// path/to/your/thunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import {
  setErrorState,
  setLoadingState,
  updateDocuments,
} from "../../../../catalogSliceV4";

// Для хранения функций отписки, связанных с конкретными коллекциями
const unsubscribeMapPreview = new Map();

// Для хранения информации о последних обновлениях и таймерах по коллекциям
const updateMapPreview = new Map();

/**
 * Подписка на изменения в коллекции Firestore с контролем частоты обновлений.
 * @param {Object} params - Параметры подписки.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} params.previewCurrentDocumenState - Состояние текущей коллекции в Redux.
 * @param {Array<string>} params.excludedIds - Массив ID документов, которые нужно исключить из подписки.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 * @param {number} [params.delayTime=60000] - Задержка в миллисекундах между обновлениями (по умолчанию 60 секунд).
 */
export const interrogationPreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/interrogationPreviewDocumentsThunkV4",
  async (
    {
      collectionPath,
      previewCurrentDocumenState,
      excludedIds = [],
      loadingStateName,
      errorStateName,
      delayTime = 60000, // По умолчанию 60 секунд
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log("Инициализация подписки на коллекцию:", collectionPath);

      const collectionRef = collection(db, ...collectionPath.split("."));
      console.log("Ссылка на коллекцию Firestore создана:", collectionRef);

      // Если существует подписка на ту же коллекцию, отписываемся от неё
      if (unsubscribeMapPreview.has(collectionPath)) {
        const previousUnsubscribe = unsubscribeMapPreview.get(collectionPath);
        if (typeof previousUnsubscribe === "function") {
          previousUnsubscribe();
          console.log(
            `Существующая подписка на коллекцию ${collectionPath} отменена`
          );
        }
      }

      // Подписка на коллекцию и получение функции отписки
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          const currentTimestamp = Date.now();
          const updateEntry = updateMapPreview.get(collectionPath) || {};

          const filteredDocuments = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((doc) => !excludedIds.includes(doc.id));

          const combinedData = filteredDocuments.reduce((acc, doc) => {
            if (Array.isArray(doc.data)) {
              return acc.concat(doc.data);
            }
            return acc;
          }, []);

          if (!updateEntry.lastUpdateTimestamp) {
            // Первое обновление: выполняем сразу
            dispatch(
              updateDocuments({
                documents: combinedData,
                previewCurrentDocumenState,
              })
            );
            dispatch(
              setLoadingState({
                loadingStateName,
                isLoading: false,
              })
            );

            // Устанавливаем последний момент обновления
            updateEntry.lastUpdateTimestamp = currentTimestamp;

            // Увеличиваем счётчик обновлений
            updateEntry.updateCount = (updateEntry.updateCount || 0) + 1;

            console.log(
              `Данные обновлены для коллекции ${collectionPath} (сразу). Обновление №${updateEntry.updateCount}`
            );
          } else {
            const timeSinceLastUpdate =
              currentTimestamp - updateEntry.lastUpdateTimestamp;

            if (timeSinceLastUpdate >= delayTime) {
              // Достаточно времени прошло: обновляем
              dispatch(
                updateDocuments({
                  documents: combinedData,
                  previewCurrentDocumenState,
                })
              );
              dispatch(
                setLoadingState({
                  loadingStateName,
                  isLoading: false,
                })
              );

              // Обновляем последний момент обновления
              updateEntry.lastUpdateTimestamp = currentTimestamp;

              // Увеличиваем счётчик обновлений
              updateEntry.updateCount = (updateEntry.updateCount || 0) + 1;

              console.log(
                `Данные обновлены для коллекции ${collectionPath} (по таймауту). Обновление №${updateEntry.updateCount}`
              );
            } else {
              // Время не прошло: сохраняем последние данные и устанавливаем таймер, если его нет
              updateEntry.latestData = combinedData;

              if (!updateEntry.timerId) {
                const remainingTime = delayTime - timeSinceLastUpdate;

                updateEntry.timerId = setTimeout(() => {
                  const dataToUpdate = updateEntry.latestData;

                  dispatch(
                    updateDocuments({
                      documents: dataToUpdate,
                      previewCurrentDocumenState,
                    })
                  );
                  dispatch(
                    setLoadingState({
                      loadingStateName,
                      isLoading: false,
                    })
                  );

                  // Обновляем последний момент обновления
                  updateEntry.lastUpdateTimestamp = Date.now();
                  updateEntry.timerId = null;
                  updateEntry.latestData = null;

                  // Увеличиваем счётчик обновлений
                  updateEntry.updateCount = (updateEntry.updateCount || 0) + 1;

                  console.log(
                    `Данные обновлены для коллекции ${collectionPath} (через задержку). Обновление №${updateEntry.updateCount}`
                  );
                }, remainingTime);

                console.log(
                  `Таймер установлен для коллекции ${collectionPath}, обновление через ${remainingTime} мс.`
                );
              }

              // Сохраняем обновленный updateEntry
              updateMapPreview.set(collectionPath, updateEntry);
            }
          }

          // Обновляем запись в updateMapPreview
          updateMapPreview.set(collectionPath, updateEntry);
        },
        (error) => {
          const errorMessage = interpretErrorV4(error.code);
          console.error("Ошибка при подписке на Firestore:", errorMessage);
          dispatch(
            setErrorState({
              errorStateName,
              errorMessage,
            })
          );
          return rejectWithValue({ errorMessage });
        }
      );

      // Сохраняем функцию отписки в Map
      unsubscribeMapPreview.set(collectionPath, unsubscribe);

      console.log("Подписка успешно установлена на коллекцию:", collectionPath);

      // Возвращаем объект без функции отписки для сериализуемости
      return { subscriptionEstablished: true };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при подписке на Firestore:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);

/**
 * Thunk для отписки от коллекции Firestore.
 * @param {string} collectionPath - Путь к коллекции Firestore.
 */
export const unsubscribeInterrogationPreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/unsubscribeInterrogationPreviewDocumentsThunkV4",
  async (collectionPath, { dispatch }) => {
    try {
      if (unsubscribeMapPreview.has(collectionPath)) {
        const unsubscribe = unsubscribeMapPreview.get(collectionPath);
        if (typeof unsubscribe === "function") {
          unsubscribe();
          console.log(`Отписка от коллекции ${collectionPath} выполнена`);
        }
        unsubscribeMapPreview.delete(collectionPath);
      } else {
        console.log(`Нет подписки для коллекции ${collectionPath}`);
      }

      // Также нужно очистить таймер, если он установлен
      if (updateMapPreview.has(collectionPath)) {
        const currentUpdate = updateMapPreview.get(collectionPath);
        if (currentUpdate.timerId) {
          clearTimeout(currentUpdate.timerId);
          console.log(`Таймер для коллекции ${collectionPath} очищен`);
        }
        updateMapPreview.delete(collectionPath);
      }

      return { unsubscribed: true };
    } catch (error) {
      console.error("Ошибка при отписке от Firestore:", error);
      throw error;
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, onSnapshot } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";

// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateDocuments,
// } from "../../../../catalogSliceV4";

// // Слушаем изменения в коллекции Firestore
// export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribePreviewDocumentsThunkV4",
//   async (
//     {
//       collectionPath,
//       previewCurrentDocumenState,
//       excludedIds = [],
//       loadingStateName,
//       errorStateName,
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       console.log("Инициализация подписки на коллекцию:", collectionPath);

//       const collectionRef = collection(db, ...collectionPath.split("."));
//       console.log("Ссылка на коллекцию Firestore создана:", collectionRef);

//       // Устанавливаем подписку и получаем функцию отписки
//       const unsubscribe = onSnapshot(
//         collectionRef,
//         (snapshot) => {
//           const filteredDocuments = snapshot.docs
//             .map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//             }))
//             .filter((doc) => !excludedIds.includes(doc.id));

//           const combinedData = filteredDocuments.reduce((acc, doc) => {
//             if (Array.isArray(doc.data)) {
//               return acc.concat(doc.data);
//             }
//             return acc;
//           }, []);

//           dispatch(
//             updateDocuments({
//               documents: combinedData,
//               previewCurrentDocumenState,
//             })
//           );

//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );
//         },
//         (error) => {
//           const errorMessage = interpretErrorV4(error.code);
//           console.error("Ошибка при подписке на Firestore:", errorMessage);
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );
//           return rejectWithValue({ errorMessage });
//         }
//       );

//       console.log("Подписка успешно установлена");
//       return { unsubscribe }; // Возвращаем функцию отписки
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error("Ошибка при подписке на Firestore:", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, onSnapshot } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";

// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateDocuments,
// } from "../../../../catalogSliceV4";

// // Слушаем изменения в коллекции Firestore
// export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribePreviewDocumentsThunkV4",
//   async (
//     {
//       collectionPath, // Путь к коллекции
//       previewCurrentDocumenState, // Путь в стейте для хранения данных
//       excludedIds = [], // Исключаемые ID документов из ответа функции (по умолчанию пустой массив)
//       loadingStateName, // Название состояния загрузки
//       errorStateName, // Название состояния ошибки
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       console.log("Инициализация подписки на коллекцию:", collectionPath);

//       // Получаем ссылку на коллекцию
//       const collectionRef = collection(db, ...collectionPath.split("."));
//       console.log("Ссылка на коллекцию Firestore создана:", collectionRef);

//       // Подписка на изменения в коллекции
//       onSnapshot(
//         collectionRef,
//         (snapshot) => {
//           console.log("Получен снимок данных из Firestore:");
//           snapshot.docs.forEach((doc) => {
//             console.log(`Документ ID: ${doc.id}`, doc.data());
//           });

//           // Фильтруем документы, исключая те, у которых ID содержатся в excludedIds
//           const filteredDocuments = snapshot.docs
//             .map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//             }))
//             .filter((doc) => !excludedIds.includes(doc.id));

//           // Объединяем все массивы `data` из каждого документа в один массив
//           const combinedData = filteredDocuments.reduce((acc, doc) => {
//             if (Array.isArray(doc.data)) {
//               return acc.concat(doc.data);
//             }
//             return acc;
//           }, []);

//           console.log("Объединенные данные:", combinedData);

//           // Используем сгенерированный экшен updateDocuments для обновления данных в стейте
//           dispatch(
//             updateDocuments({
//               documents: combinedData, // Объединенный массив данных
//               previewCurrentDocumenState, // Путь для хранения данных
//             })
//           );

//           console.log(
//             "Завершена обработка документов. Сброс статуса загрузки."
//           );

//           // Используем сгенерированный экшен setLoadingState для изменения статуса загрузки
//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );
//         },
//         (error) => {
//           console.error("Ошибка подписки на документы Firestore:", error);
//           const errorMessage = interpretErrorV4(error.code);
//           console.log("Ошибка обработки:", errorMessage);

//           // Используем сгенерированный экшен setErrorState для изменения статуса ошибки
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );

//           rejectWithValue({ errorMessage });
//         }
//       );

//       console.log(
//         "Подписка на изменения в коллекции Firestore успешно инициирована."
//       );
//       return { success: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при подписке на документы Firestore:",
//         errorMessage
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

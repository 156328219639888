import React from "react";
import Preloader from "../../UIElements/Preloader";

const WidgetTimetable = ({ timetableData, isLoading }) => {
  return (
    <div
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "4px",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Preloader />
        </div>
      ) : (
        <>
          {timetableData?.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="p-light">{item.weekday.weekdayOriginal}:</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2px",
                }}
              >
                {item.isHoliday ? (
                  <p
                    className="p-light"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "var(--color-alarm)",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                    }}
                  >
                    holiday
                  </p>
                ) : (
                  <>
                    <p
                      style={{
                        fontWeight: "600",
                        padding: "4px 0px",
                      }}
                    >
                      {item.openHours}:{item.openMinutes}&nbsp;—&nbsp;
                      {item.closeHours}:{item.closeMinutes}
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default WidgetTimetable;

import { useState, useEffect } from "react";

/**
 * Хук для определения мобильного устройства.
 * @returns {boolean} - Возвращает true, если устройство мобильное, иначе false.
 */
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 400); // по умолчанию считаем, что mobile если ширина экрана меньше или равна 768px

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400);
    };

    // Добавляем слушатель события на изменение размера окна
    window.addEventListener("resize", handleResize);

    // Убираем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;

import React from "react";
import Preloader from "../../../../../UIElements/Preloader";

const ContentBox = React.memo(
  ({ children, position, isLoading, label, header, onClick }) => {
    return (
      <div
        className={`block-section ${
          position === "start"
            ? "block-section__start-box"
            : position === "end"
            ? "block-section__end-box"
            : position === "one"
            ? "block-section__one-box"
            : "block-section__medium-box"
        }`}
        style={{
          overflow: "hidden",
          boxSizing: "border-box",
          ...(header && { paddingTop: "0" }),
        }}
      >
        {header && (
          <div
            style={{
              width: "100%",
              // height: "80px",
              // backgroundColor: "violet",
              position: "relative",
              overflow: "hidden",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                height: "24px",
                borderRadius: "24px 24px 0 0",
                backgroundColor: "var(--color-white)",
                zIndex: 1,
                boxSizing: "border-box",
              }}
            />

            {header}
          </div>
        )}
        <div
          onClick={onClick}
          className="profile-block-section__content-box"
          style={{
            width: "100%",
            ...(onClick && {
              cursor: "pointer",
            }),
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Preloader />
              {label && (
                <p
                  className="p-light"
                  style={{ color: "var(--color-gray-500)" }}
                >
                  {label}
                </p>
              )}
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    );
  }
);

export default ContentBox;

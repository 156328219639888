import React from "react";
import { truncateText } from "../../../../../functions/truncateText";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { v4 as uuidv4 } from "uuid";
import Counter from "../WidgetsProfile/Counter/Counter";
import Button from "../WidgetsProfile/Buttons/Button";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import { he } from "date-fns/locale";
import { dateFormat } from "../../../../../functions/convertDate";

const WidgetCurrentCartV4 = ({
  currentCart, // Массив объектов с заказами
  onClick,
  onRemoveItem,
  onUpdateQuantity,
}) => {
  // Группируем заказы в массиве по точкам проката
  const groupedOrderData =
    currentCart?.reduce((acc, itemCart) => {
      const key = itemCart?.rentalPointRootData?.idRootDocument || null;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(itemCart);

      return acc;
    }, {}) || {};

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "32px",
        boxSizing: "border-box",
      }}
    >
      {Object.entries(groupedOrderData)?.map(([id, itemGroupedOrder]) => (
        <div
          key={id}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <RentalPointData item={itemGroupedOrder[0].rentalPointRootData} />

            {itemGroupedOrder.length > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                {itemGroupedOrder?.map((item, index) => (
                  <div
                    key={uuidv4()}
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <BikeItemData
                      item={item}
                      index={index}
                      groupItems={itemGroupedOrder}
                      onUpdateQuantity={onUpdateQuantity}
                      onRemoveItem={onRemoveItem}
                    />
                  </div>
                ))}
              </div>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <p className="p-light">
                TOTAL:{" "}
                {formatCurrencyUSD(
                  itemGroupedOrder?.reduce(
                    (acc, item) =>
                      acc +
                      (item?.totals?.totalCostBike +
                        (item?.totals?.totalCostAditionalServices || 0)),
                    0
                  )
                )}
              </p>

              <Button
                type="small"
                active={true}
                label="Place your order"
                onClick={() => onClick({ itemGroupedOrder: itemGroupedOrder })}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WidgetCurrentCartV4;

const RentalPointData = ({ item }) => {
  console.log("RentalPointData item", item);
  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <PriorityFile
        files={item?.files?.logoFiles}
        style={{
          borderRadius: "50%",
          height: "80px",
        }}
      />

      <div
        style={{
          gridColumn: "span 5",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <span>
          <h5>{item?.basicInformation?.title?.titleOriginal}</h5>
          <p className="p-light">{item?.location?.country?.title}</p>
          <p className="p-light">{item?.location?.city?.title}</p>
        </span>
      </div>
    </div>
  );
};

const BikeItemData = ({
  item,
  index,
  groupItems,
  onUpdateQuantity,
  onRemoveItem,
}) => {
  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 1) {
      onUpdateQuantity(item, newQuantity);
    }
  };

  const handleRemoveItem = () => {
    onRemoveItem(item);
  };

  const singleBikeCost =
    item?.totals?.totalCostBike / (item?.totals?.quantity || 1);
  const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
  const updatedTotalCost =
    updatedTotalCostBike + (item?.totals?.totalCostAditionalServices || 0);

  return (
    <div
      style={{
        backgroundColor: "var(--color-gray-100)",
        borderRadius:
          groupItems.length === 1
            ? "16px"
            : `${index === 0 ? "16px 16px 0 0" : ""} ${
                index === groupItems.length - 1 ? "0 0 16px 16px" : ""
              }`,
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "24px",
      }}
    >
      <div
        style={{
          height: "100%",
          gridColumn: "span 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "4px",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-gray-400)",
              }}
            >
              # {index + 1}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.brand?.title}
              </p>

              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.category?.title}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
                whiteSpace: "nowrap",
              }}
            >
              <p className="p-light">
                {dateFormat(item?.totals?.dateInterval[0] || 0)} —
                {dateFormat(item?.totals?.dateInterval[1] || 0)}
              </p>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />

              <p className="p-light">
                {item?.totals?.daysInInterval || 0} day
                {item?.totals?.daysInInterval > 1 && "s"}
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <h5
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncateText(
                    item?.bikeData?.basicInformation?.title?.titleOriginal,
                    30
                  )}
                </h5>

                <div
                  className="material-symbols-outlined p-light"
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                >
                  close_small
                </div>

                <p
                  className="p-light"
                  style={{
                    color: "var(--color-gray-400)",
                  }}
                >
                  {item?.totals?.quantity || 1}
                </p>
              </div>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />

              <h6>{formatCurrencyUSD(updatedTotalCostBike)}</h6>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "80px",
            padding: "4px",
          }}
        >
          <button
            onClick={handleRemoveItem}
            style={{
              height: "32px",
              aspectRatio: "1/1",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "0",
            }}
            className="material-symbols-outlined"
          >
            close
          </button>

          <Counter
            type="small"
            maxValue={item?.bikeData?.metricsBike?.quantityBikes || 1}
            minValue={1}
            value={item.totals.quantity}
            onChange={handleQuantityChange}
          />
        </div>
      </div>

      {item?.orderedAditionalServices?.length > 0 && (
        <div
          style={{
            gridColumn: "span 3",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {item?.orderedAditionalServices?.map((service, idx) => (
                <div
                  key={service?.id || idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateText(service?.title?.titleOriginal, 55)}
                    </p>
                    <span
                      style={{
                        color: "var(--color-gray-400)",
                      }}
                      className="material-symbols-outlined p-light"
                    >
                      close_small
                    </span>
                    <p
                      className="p-light"
                      style={{
                        color: "var(--color-gray-400)",
                      }}
                    >
                      {service?.countOrderedServices}
                    </p>
                  </span>

                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--color-gray-200)",
                      height: "1px",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      color: "var(--color-black)",
                      fontWeight: "600",
                    }}
                  >
                    {formatCurrencyUSD(
                      service?.price * service?.countOrderedServices || 0
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="p-light">TOTAL:</p>
              <h6>{formatCurrencyUSD(updatedTotalCost)}</h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

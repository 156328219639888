import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
// import WidgetOrdersList from "./WidgetOrdersList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import Preloader from "../../../../UIElements/Preloader";
import AddData from "../WidgetsProfile/AddData/AddData";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowCatalogV4 from "./WindowCatalogV4";
import { useDispatch } from "react-redux";
import WidgetOrdersListV4 from "./WidgetOrdersListV4";

// const calculateTotalItems = (currentCart) => {
//   if (!currentCart || typeof currentCart !== "object") {
//     console.error(" не является объектом или не существует.");
//     return 0;
//   }

//   // Суммируем длины всех массивов в объекте
//   return Object.values(currentCart).reduce((total, group) => {
//     if (Array.isArray(group)) {
//       return total + group.length;
//     }
//     return total;
//   }, 0);
// };

const WidgetOrders = ({ isLoading, currentOrders }) => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState("moderation");
  const [orderState, setOrderState] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);

  // WINDOW
  const [windowCreateNewOrderV4, setWindowCreateNewOrderV4] = useState(false);

  /**
   *
   * Логика компонента
   *
   */

  // Функция фильтрации по статусу
  useEffect(() => {
    const filterOrders = () => {
      if (!Array.isArray(currentOrders)) {
        console.error("currentOrders is not an array:", currentOrders);
        return []; // Если currentOrders не массив, возвращаем пустой массив
      }

      if (orderState === "new") {
        return currentOrders?.filter((order) => order.orderStatus === "new");
      } else if (orderState === "in progress") {
        return currentOrders?.filter(
          (order) =>
            order.orderStatus === "in progress" ||
            order.orderStatus === "replacement"
        );
      } else if (orderState === "completed") {
        return currentOrders?.filter(
          (order) =>
            order.orderStatus === "completed" ||
            order.orderStatus === "confirm" ||
            order.orderStatus === "canceled"
        );
      } else if (orderState === "payment") {
        return currentOrders?.filter(
          (order) => order.orderStatus === "payment"
        );
      }
      return currentOrders; // По умолчанию возвращаем все заказы
    };

    const result = filterOrders();
    setFilteredOrders(result);
  }, [orderState, currentOrders]);

  const createNewOrder = (mode) => {
    setMode(mode);
    setWindowCreateNewOrderV4(true);
  };

  const handleClose = () => {
    setWindowCreateNewOrderV4(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowCreateNewOrderV4}
        onClose={handleClose}
        title={`WindowCatalogV4`}
      >
        <WindowCatalogV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      {!isLoading ? (
        <>
          <AddData
            onClick={() => createNewOrder("order")}
            icon={<span className="material-symbols-outlined">grid_view</span>}
            title={"Create new order"}
            description={
              "lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <SegmentedControls
              options={["new", "in progress", "completed"]}
              onSelect="new"
              // label="Orders"
              selected={setOrderState}
            />
            <WidgetOrdersListV4 ordersList={filteredOrders} />
          </div>
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default WidgetOrders;

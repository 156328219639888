import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ContentBox from "../ContentBox/ContentBox";
import Button from "../Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ButtonsBar from "../ButtonsBar/ButtonsBar";
import Rate from "../Rate/RateV4";
import { setTempData } from "../../../../../../reducersToolkit/catalogSliceV4";

const WindowCalendar = ({ mode, onClose }) => {
  const dispatch = useDispatch();

  const { tempData } = useSelector((state) => state.catalogSliceV4);

  const [dates, setDates] = useState([]);
  const [rate, setRate] = useState([]);
  // const [orderedBike, setOrderedBike] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [allowedMonths, setAllowedMonths] = useState([]);

  const [filterIntervalDays, setFilterIntervalDays] = useState(null);
  const [orderedBikeIntervalDays, setOrderedBikeIntervalDays] = useState(null);

  const [dateIntervalFilter, setDateIntervalFilter] = useState([]);
  const [dateIntervalOrderedBike, setDateIntervalOrderedBike] = useState([]);

  const [activeButtonApply, setActiveButtonApply] = useState(true);

  // Инициализация данных из tempData.manage_fdiltersBikes.dateInterval
  useEffect(() => {
    // дата указанная в фильтрах каталога
    const dateIntervalFilter = tempData?.manage_fdiltersBikes?.dateInterval;

    if (Array.isArray(dateIntervalFilter) && dateIntervalFilter.length === 2) {
      const start = new Date(dateIntervalFilter[0]);
      const end = new Date(dateIntervalFilter[1]);

      // Устанавливаем начало и конец дня
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateIntervalFilter([start, end]);
    }

    // дата указанная при добавлении байка в корзину
    const dateIntervalOrderedBike =
      tempData?.manage_fdiltersBikes?.dateIntervalOrderedBike;

    if (
      Array.isArray(dateIntervalOrderedBike) &&
      dateIntervalOrderedBike.length === 2
    ) {
      const start = new Date(dateIntervalOrderedBike[0]);
      const end = new Date(dateIntervalOrderedBike[1]);

      // Устанавливаем начало и конец дня
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateIntervalOrderedBike([start, end]);
    }
  }, [tempData]);

  // Инициализация заказанного байка
  useEffect(() => {
    // setOrderedBike(tempData?.orderedBike || null);
    setRate(tempData?.orderedBike?.rate || []);
  }, [tempData?.orderedBike]);

  // Динамическое формирование allowedMonths
  useEffect(() => {
    const monthsWithPrice = rate
      .filter((item) => item.price !== 0)
      .map((item) => item.month);
    setAllowedMonths(monthsWithPrice);
  }, [rate]);

  // Счетчик дней ДНЕЙ ЗАДАННЫХ В ФИЛЬТРЕ (dateIntervalFilter)
  useEffect(() => {
    if (
      dateIntervalFilter.length === 2 &&
      dateIntervalFilter[0] instanceof Date &&
      dateIntervalFilter[1] instanceof Date
    ) {
      const days = Math.ceil(
        (dateIntervalFilter[1].getTime() - dateIntervalFilter[0].getTime()) /
          (1000 * 60 * 60 * 24)
      );
      setFilterIntervalDays(days);
    }
  }, [dateIntervalFilter]);

  // Счетчик дней ДНЕЙ ЗАДАННЫХ В КАЛЕНДАРЕ (dateIntervalOrderedBike)
  useEffect(() => {
    if (
      dateIntervalOrderedBike.length === 2 &&
      dateIntervalOrderedBike[0] instanceof Date &&
      dateIntervalOrderedBike[1] instanceof Date
    ) {
      const days = Math.ceil(
        (dateIntervalOrderedBike[1].getTime() -
          dateIntervalOrderedBike[0].getTime()) /
          (1000 * 60 * 60 * 24)
      );
      setOrderedBikeIntervalDays(days);
    }
  }, [dateIntervalOrderedBike]);

  // Устанавливаем даты при изменении selectedMonth
  useEffect(() => {
    if (selectedMonth !== null) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const targetYear =
        selectedMonth < currentMonth ? currentYear + 1 : currentYear;

      // Если выбран текущий месяц, устанавливаем startDate с текущего дня
      const isCurrentMonth =
        selectedMonth === currentMonth && targetYear === currentYear;
      const startDate = isCurrentMonth
        ? currentDate // Начинаем с текущего дня
        : new Date(targetYear, selectedMonth, 1); // Начинаем с первого числа месяца
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(startDate);

      if (orderedBikeIntervalDays) {
        endDate.setDate(startDate.getDate() + orderedBikeIntervalDays);
      } else if (filterIntervalDays) {
        endDate.setDate(startDate.getDate() + filterIntervalDays - 1);
      }

      endDate.setHours(23, 59, 59, 999);
      setDates([startDate, endDate]);

      // console.log("setOrderedBikeIntervalDays", orderedBikeIntervalDays);

      // Сбрасываем другие интервалы
      setDateIntervalFilter([]);
      setDateIntervalOrderedBike([]);
    }
  }, [selectedMonth, orderedBikeIntervalDays, filterIntervalDays]);

  // Функция фильтрации дат
  const isMonthAllowed = (date) => {
    const month = date.getMonth();
    return allowedMonths.includes(month);
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const targetYear =
    selectedMonth !== null && selectedMonth < currentMonth
      ? currentYear + 1
      : currentYear;

  // const handleApplyButton = () => {
  //   let resolvedDates = [];

  //   // Проверяем и выбираем правильный источник дат
  //   if (dateIntervalOrderedBike.length === 2) {
  //     resolvedDates = [
  //       dateIntervalOrderedBike[0]?.getTime(),
  //       dateIntervalOrderedBike[1]?.getTime(),
  //     ];

  //     setDates(resolvedDates);
  //   } else if (dateIntervalFilter.length === 2) {
  //     resolvedDates = [
  //       dateIntervalFilter[0]?.getTime(),
  //       dateIntervalFilter[1]?.getTime(),
  //     ];

  //     setDates(resolvedDates);
  //   } else if (dates.length === 2) {
  //     resolvedDates = [dates[0]?.getTime(), dates[1]?.getTime()];
  //     setDates(resolvedDates);
  //   }

  //   if (resolvedDates.length === 2 && resolvedDates[0] && resolvedDates[1]) {
  //     dispatch(
  //       setTempData({
  //         tempCurrentDocumentState:
  //           "manage_fdiltersBikes.dateIntervalOrderedBike",
  //         data: resolvedDates,
  //       })
  //     );
  //     onClose();
  //   } else {
  //     console.error("Invalid date range provided");
  //   }
  // };
  const handleApplyButton = () => {
    let resolvedDates = [];

    // Проверяем и выбираем правильный источник дат
    if (dateIntervalOrderedBike.length === 2) {
      resolvedDates = [
        dateIntervalOrderedBike[0]?.getTime(), // Преобразуем дату в миллисекунды
        dateIntervalOrderedBike[1]?.getTime(),
      ];

      setDates(resolvedDates);
    } else if (dateIntervalFilter.length === 2) {
      resolvedDates = [
        dateIntervalFilter[0]?.getTime(), // Преобразуем дату в миллисекунды
        dateIntervalFilter[1]?.getTime(),
      ];

      setDates(resolvedDates);
    } else if (dates.length === 2) {
      resolvedDates = [dates[0]?.getTime(), dates[1]?.getTime()];
      setDates(resolvedDates);
    }

    if (resolvedDates.length === 2 && resolvedDates[0] && resolvedDates[1]) {
      console.log("resolvedDates", resolvedDates);
      dispatch(
        setTempData({
          tempCurrentDocumentState:
            "manage_fdiltersBikes.dateIntervalOrderedBike",
          data: resolvedDates, // Передаём данные как временные метки (миллисекунды)
        })
      );
      onClose();
    } else {
      console.error("Invalid date range provided");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <Rate
          setSelectedMonth={setSelectedMonth}
          rate={rate}
          startBookingDates={
            dateIntervalOrderedBike.length && dateIntervalOrderedBike[0] // Если есть элементы в dateIntervalOrderedBike и есть второй элемент
              ? dateIntervalOrderedBike[0]
              : dateIntervalFilter.length && dateIntervalFilter[0] // Если есть элементы в dateIntervalFilter и есть второй элемент
              ? dateIntervalFilter[0]
              : dates[0] // Если оба условия выше не выполнены
          }
          endBookingDates={
            dateIntervalOrderedBike.length && dateIntervalOrderedBike[1] // Если есть элементы в dateIntervalOrderedBike и есть второй элемент
              ? dateIntervalOrderedBike[1]
              : dateIntervalFilter.length && dateIntervalFilter[1] // Если есть элементы в dateIntervalFilter и есть второй элемент
              ? dateIntervalFilter[1]
              : dates[1] // Если оба условия выше не выполнены
          }
          // title="Rentals are available in the following months:"
          // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          activator={setActiveButtonApply}
        />
      </ContentBox>
      <ContentBox>
        <DatePicker
          selected={
            dateIntervalOrderedBike.length && dateIntervalOrderedBike[0] // Если есть элементы в dateIntervalOrderedBike и есть второй элемент
              ? dateIntervalOrderedBike[0]
              : dateIntervalFilter.length && dateIntervalFilter[0] // Если есть элементы в dateIntervalFilter и есть второй элемент
              ? dateIntervalFilter[0]
              : dates[0] // Если оба условия выше не выполнены
          }
          onChange={(dateRange) => {
            setDates(dateRange);
            setDateIntervalFilter(dateRange);
            setDateIntervalOrderedBike(dateRange);
            // Сбрасываем selectedMonth, чтобы избежать конфликтов
            setSelectedMonth(null);
          }}
          startDate={
            dateIntervalOrderedBike.length && dateIntervalOrderedBike[0] // Если есть элементы в dateIntervalOrderedBike и есть второй элемент
              ? dateIntervalOrderedBike[0]
              : dateIntervalFilter.length && dateIntervalFilter[0] // Если есть элементы в dateIntervalFilter и есть второй элемент
              ? dateIntervalFilter[0]
              : dates[0] // Если оба условия выше не выполнены
          }
          endDate={
            dateIntervalOrderedBike.length && dateIntervalOrderedBike[1] // Если есть элементы в dateIntervalOrderedBike и есть второй элемент
              ? dateIntervalOrderedBike[1]
              : dateIntervalFilter.length && dateIntervalFilter[1] // Если есть элементы в dateIntervalFilter и есть второй элемент
              ? dateIntervalFilter[1]
              : dates[1] // Если оба условия выше не выполнены
          }
          selectsRange
          inline
          monthsShown={1}
          minDate={new Date()} // Минимальная дата - текущий день
          maxDate={new Date(new Date().getFullYear() + 1, 11, 31)} // Максимальная дата - декабрь текущий год + 2
          filterDate={isMonthAllowed}
          openToDate={
            selectedMonth !== null
              ? new Date(targetYear, selectedMonth, 1)
              : dates[0] || new Date()
          }
        />
      </ContentBox>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel selections"
            onClick={onClose}
            active={true}
          />
          <Button
            type="small"
            color="--color-primary"
            label="Apply"
            active={activeButtonApply}
            onClick={handleApplyButton}
            allert={
              <div
                style={{
                  padding: "32px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to apply these dates?</h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowCalendar;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import WidgetListFileV4 from "../WidgetsProfile/UploadFiles/WidgetListFileV4";
import WidgetViewBikeDetail from "./WidgetViewBikeDetail";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import Button from "../WidgetsProfile/Buttons/Button";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import CollapsableText from "../WidgetsProfile/Collapsable/CollapsableText";
import { v4 as uuidv4 } from "uuid";
import priorityFile from "../../../../../functions/priorityFile";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
const truncateToThreeChars = (value) => {
  // Проверяем, является ли value строкой
  if (typeof value === "string") {
    return value.slice(0, 3); // Обрезаем строку до 3 символов
  }
  return value; // Возвращаем значение без изменений, если это не строка
};

// function updateUserChoiceFlag({
//   order_selectedCompletedRootOrder,
//   indexBikeCurrentCart,
//   keySlotReplacementBike,
//   indexReplacementBikes,
//   flag,
//   oneOnly
// }) {
//   // Проверяем наличие текущей корзины и других вложенных объектов
//   if (
//     !order_selectedCompletedRootOrder?.currentCart ||
//     !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart] ||
//     !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
//       .replacementBikes ||
//     !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
//       .replacementBikes[keySlotReplacementBike]
//   ) {
//     console.error("Некорректные данные или структура объекта");
//     return null;
//   }

//   // Создаем копию order_selectedCompletedRootOrder для предотвращения мутации исходного объекта
//   const updatedOrder = JSON.parse(
//     JSON.stringify(order_selectedCompletedRootOrder)
//   );

//   // Получаем ссылку на массив по ключу keySlotReplacementBike
//   const replacementBikes =
//     updatedOrder.currentCart[indexBikeCurrentCart].replacementBikes[
//       keySlotReplacementBike
//     ];

//   // Проверяем существование элемента в массиве по индексу indexReplacementBikes
//   if (!replacementBikes[indexReplacementBikes]) {
//     console.error("Элемент с указанным индексом не найден в replacementBikes");
//     return null;
//   }

//   // Устанавливаем флаг userChoice в flag
//   replacementBikes[indexReplacementBikes].userChoice = flag;

//   // Возвращаем обновленный объект
//   return updatedOrder;
// }

function updateUserChoiceFlag({
  order_selectedCompletedRootOrder,
  indexBikeCurrentCart,
  keySlotReplacementBike,
  indexReplacementBikes,
  flag,
  oneOnly,
}) {
  // Проверяем наличие текущей корзины и других вложенных объектов
  if (
    !order_selectedCompletedRootOrder?.currentCart ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart] ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
      .replacementBikes ||
    !order_selectedCompletedRootOrder.currentCart[indexBikeCurrentCart]
      .replacementBikes[keySlotReplacementBike]
  ) {
    console.error("Некорректные данные или структура объекта");
    return null;
  }

  // Создаем копию order_selectedCompletedRootOrder для предотвращения мутации исходного объекта
  const updatedOrder = JSON.parse(
    JSON.stringify(order_selectedCompletedRootOrder)
  );

  // Получаем ссылку на массив по ключу keySlotReplacementBike
  const replacementBikes =
    updatedOrder.currentCart[indexBikeCurrentCart].replacementBikes[
      keySlotReplacementBike
    ];

  // Проверяем существование элемента в массиве по индексу indexReplacementBikes
  if (!replacementBikes[indexReplacementBikes]) {
    console.error("Элемент с указанным индексом не найден в replacementBikes");
    return null;
  }

  // Если oneOnly === true, сбрасываем все флаги userChoice в false
  if (oneOnly) {
    replacementBikes.forEach((bike) => {
      bike.userChoice = false; // сбрасываем все флаги
    });
  }

  // Устанавливаем флаг userChoice в flag для указанного элемента
  replacementBikes[indexReplacementBikes].userChoice = flag;

  // Возвращаем обновленный объект
  return updatedOrder;
}

const WindowViewBikeDetail = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const {
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    loading,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  /**
   *
   *  Выбор замены байка
   *
   */

  const [photoFiles, setPhotoFiles] = useState(null);
  const [basicInformation, setBasicInformation] = useState(null);
  const [directory, setDirectory] = useState(null);
  const [rate, setRate] = useState(null);
  const [orderDeteInterval, setOrderDeteInterval] = useState([0, 0]);
  const [rentalPointRootData, setRentalPointRootData] = useState(null);
  // const [rootReplacemtntOrderData, setRootReplacemtntOrderData] =
  useState(null);
  const [previewReplacemtntOrderData, setPreviewReplacemtntOrderData] =
    useState(null);
  const [orderIdRootDocument, setOrderIdRootDocument] = useState(null);
  const [replacementBike, setReplacementBike] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);
  const [indexBikeCurrentCart, setIndexBikeCurrentCart] = useState(0);
  const [indexReplacementBikes, setIndexReplacementBikes] = useState(0);
  const [keySlotReplacementBike, setKeySlotReplacementBike] = useState(null);

  const [selectedBikes, setSelectedBikes] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userChoice, setUserChoice] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);
  const [startBookingDates, setStartBookingDates] = useState(0);
  const [endBookingDates, setEndBookingDates] = useState(0);
  console.log("WindowViewBikeDetail orderDeteInterval", rentalPointRootData);
  // ------- tempData
  useEffect(() => {
    setIndexBikeCurrentCart(
      tempData?.replacementSelectedBike?.indexBikeCurrentCart || 0
    );
    setIndexReplacementBikes(
      tempData?.replacementSelectedBike.indexReplacementBikes || 0
    );
    setKeySlotReplacementBike(
      tempData?.replacementSelectedBike.keySlotReplacementBike || null
    );

    setPhotoFiles(
      tempData?.replacementSelectedBike?.replacementBike?.files?.photoFiles ||
        null
    );

    setBasicInformation(
      tempData?.replacementSelectedBike?.replacementBike?.basicInformation ||
        null
    );

    setDirectory(
      tempData?.replacementSelectedBike?.replacementBike?.directory || null
    );

    setRate(tempData?.replacementSelectedBike?.replacementBike?.rate || null);

    setOrderDeteInterval(
      tempData?.replacementSelectedBike?.orderData?.totals?.dateInterval || null
    );

    setReplacementBike(
      tempData?.replacementSelectedBike.replacementBike || null
    );

    setOrderIdRootDocument(
      tempData?.selectedCompletedOrderPreview?.idRootDocument || null
    );
  }, [
    tempData?.replacementSelectedBike,
    tempData?.orderData,
    tempData?.selectedCompletedOrderPreview,
  ]);

  // ------- tempData
  // ------- tempRootData
  useEffect(() => {
    if (tempData?.currentUpdatedOrder) {
      setCurrentCart(tempData?.currentUpdatedOrder?.currentCart || null);

      setOrder_selectedCompletedRootOrder(
        tempData?.currentUpdatedOrder || null
      );

      setUserIdRootDocument(
        tempData?.currentUpdatedOrder?.client?.idRootDocument || null
      );

      setSelectedBikes(
        tempData?.currentUpdatedOrder?.currentCart[indexBikeCurrentCart]
          ?.selectedBikes || {}
      );

      setUserChoice(
        tempData?.currentUpdatedOrder?.currentCart?.[indexBikeCurrentCart]
          ?.replacementBikes?.[keySlotReplacementBike]?.[indexReplacementBikes]
          ?.userChoice || false
      );

      const startBookingDates =
        tempData?.currentUpdatedOrder?.currentCart[indexBikeCurrentCart]?.totals
          ?.dateInterval[0];

      setStartBookingDates(startBookingDates);

      const endBookingDates =
        tempData?.currentUpdatedOrder?.currentCart[indexBikeCurrentCart]?.totals
          ?.dateInterval[1];

      setEndBookingDates(endBookingDates);

      setRentalPointRootData(
        tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData ||
          null
      );
    } else {
      setCurrentCart(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      );

      setOrder_selectedCompletedRootOrder(
        tempRootData?.order_selectedCompletedRootOrder || null
      );

      setUserIdRootDocument(
        tempRootData?.order_selectedCompletedRootOrder?.client
          ?.idRootDocument || null
      );

      setSelectedBikes(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart[
          indexBikeCurrentCart
        ]?.selectedBikes || {}
      );

      setUserChoice(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          indexBikeCurrentCart
        ]?.replacementBikes?.[keySlotReplacementBike]?.[indexReplacementBikes]
          ?.userChoice || false
      );

      const startBookingDates =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          indexBikeCurrentCart
        ]?.totals?.dateInterval[0];

      setStartBookingDates(startBookingDates);

      const endBookingDates =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          indexBikeCurrentCart
        ]?.totals?.dateInterval[1];

      setEndBookingDates(endBookingDates);

      setRentalPointRootData(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
          ?.rentalPointRootData || null
      );
    }
  }, [
    tempRootData?.order_selectedCompletedRootOrder,
    indexBikeCurrentCart,
    indexReplacementBikes,
    keySlotReplacementBike,
    tempData?.currentUpdatedOrder,
  ]);

  /**
   *
   * Формирование объектов root и preview
   *
   */

  useEffect(() => {
    const newPreviewData = {
      ...tempData.selectedCompletedOrderPreview,
      orderStatus: "confirm",
    };
    setPreviewReplacemtntOrderData(newPreviewData);
  }, [tempData.selectedCompletedOrderPreview]);

  /**
   *
   * CRUD функции
   *
   */
  const applyData = ({ rootReplacemtntOrderData }) => {};

  /**
   *
   * Внутренняя логика компонента
   *
   */
  useEffect(() => {
    if (selectedBikes) {
      const existingBikes = selectedBikes[indexReplacementBikes] || [];

      const isBikeExist = existingBikes?.some(
        (bike) => bike?.id === replacementBike?.id
      );

      setIsDeleting(isBikeExist);
    }
  }, [
    indexReplacementBikes,
    replacementBike?.id,
    selectedBikes,
    indexBikeCurrentCart,
    tempRootData?.order_selectedCompletedRootOrder?.currentCart,
  ]);

  const handleReplacementBike = (flag) => {
    const updatedOrder = updateUserChoiceFlag({
      order_selectedCompletedRootOrder,
      indexBikeCurrentCart,
      keySlotReplacementBike,
      indexReplacementBikes,
      flag: flag,
      oneOnly: true,
    });

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentUpdatedOrder",
        data: updatedOrder,
      })
    );

    // if (updatedOrder) {
    //   dispatch(
    //     updateDataThunkV4({
    //       // Указываем id preview документа который будем менять
    //       // idPreviewDocument: idPreviewDocumentRenyalPoint,
    //       // Указываем id root Элемента
    //       idRootDocument: orderIdRootDocument,
    //       // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
    //       // updatedData: rootCountryData,

    //       rootData: updatedOrder,
    //       previewData: previewReplacemtntOrderData,

    //       // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
    //       // newFiles: {
    //       //   // или другие массивы файлов, если есть
    //       //   logoFiles: ,
    //       //   photoFiles: ,
    //       // },
    //       // Имя того массива файлов который будет использоваться для назначения иконки
    //       // iconFields: "",
    //       // Путь до коллекции указывается из НЕ четного колличества вложенностей
    //       // Путь до коллекции указывается из НЕ четного колличества вложенностей
    //       rootCollectionPath: "orders",

    //       previewCollectionPath: `manager_ordersPreview.userIds.${userIdRootDocument}`,

    //       previewGeneralCollectionPath: `general_ordersPreview`,

    //       // // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
    //       metadataDocumentPath: `manager_ordersPreview.userIds.${userIdRootDocument}.metadata`,

    //       metadataGeneralDocumentPath: `general_ordersPreview.metadata`,
    //       /*************/
    //       // Сохраняет в state ROOT ОБЪЕКТ как результат выполнения thunk
    //       rootCurrentDocumentState: "order_selectedCompletedRootOrder",
    //       // Сохраняет в state PREVIEW МАССИВ объектов как результат выполнения thunk
    //       // previewCurrentDocumenState: "",
    //       // previewGeneralDocumenState: "",
    //       // Эти состояния будут отображать состояния загрузки и ошибки
    //       /*************/
    //       loadingStateName: "loading_selectedCompletedRootOrder",
    //       errorStateName: "currentError",
    //     })
    //   );
    // }

    onClose();
    closeParent();
  };

  // const handleDeleteBike = () => {
  //   // Получаем массив bikes по индексу replacementBikes
  //   const existingBikes = selectedBikes[indexReplacementBikes] || [];

  //   // Фильтруем массив, исключая объект replacementBike
  //   const updatedSelectedBikes = {
  //     ...selectedBikes,
  //     [indexReplacementBikes]: existingBikes.filter(
  //       (bike) => bike.id !== replacementBike.id
  //     ),
  //   };

  //   // Обновляем состояние selectedBikes
  //   setSelectedBikes(updatedSelectedBikes);

  //   // Создаем новый объект для currentCart с обновленным selectedBikes
  //   // const updatedCart = {
  //   //   ...order_selectedCompletedRootOrder?.currentCart[indexBikeCurrentCart],
  //   //   selectedBikes: updatedSelectedBikes,
  //   // };

  //   // Обновляем объект rootOrder с новым updatedCart
  //   const updatedOrder = {
  //     ...order_selectedCompletedRootOrder,
  //     currentCart: order_selectedCompletedRootOrder?.currentCart?.map(
  //       (cartItem, index) =>
  //         index === indexBikeCurrentCart
  //           ? { ...cartItem, selectedBikes: updatedSelectedBikes }
  //           : cartItem
  //     ),
  //   };

  //   // Обновляем данные состояния
  //   // setRootReplacemtntOrderData(updatedOrder);
  //   applyData({ rootReplacemtntOrderData: updatedOrder });
  // };

  if (mode === "selected") {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ContentBox position="start">
            <WidgetViewBikeDetail
              photoFiles={photoFiles}
              basicInformation={basicInformation}
              directory={directory}
              orderDeteInterval={orderDeteInterval}
              rate={rate}
              startBookingDates={startBookingDates}
              endBookingDates={endBookingDates}
            />
          </ContentBox>
          {/* Rental point data */}
          <ContentBox
            // position="start"
            header={
              <HeaderRentalPointPreview
                rentalPointRootData={rentalPointRootData}
                backgroundRentalPoint={
                  priorityFile(rentalPointRootData?.files?.photoFiles)?.url
                }
              />
            }
          >
            <RentalPointData rentalPointRootData={rentalPointRootData} />
          </ContentBox>
        </div>
        <ButtonsBar>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "8px",
            }}
          >
            <Button
              type="small"
              active={true}
              label="Cancel"
              onClick={onClose}
              color="--color-black"
            />

            {userChoice ? (
              <Button
                type="small"
                active={true}
                label="Delete"
                color="--color-alarm"
                onClick={() => handleReplacementBike(false)}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Пожалуйста пожтвердите что вы хотите выбрать "Название
                    байка" на замену "Название байка"
                  </h1>
                }
              />
            ) : (
              <Button
                type="small"
                active={true}
                label="Select"
                onClick={() => handleReplacementBike(true)}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Пожалуйста пожтвердите что вы хотите выбрать "Название
                    байка" на замену "Название байка"
                  </h1>
                }
              />
            )}
          </div>
          {/* {isDeleting ? "true" : "false"}
          {isDeleting ? (
            <button onClick={handleDeleteBike}>Удалить Bike</button>
          ) : (
            <button onClick={handleAddBike}>Добавить Bike</button>
          )} */}
        </ButtonsBar>
      </div>
    );
  }

  /**
   *
   *  Выбор замены байка END
   *
   */
};

export default WindowViewBikeDetail;

const RentalPointData = ({ rentalPointRootData }) => {
  const isMobile = window.innerWidth <= 400;

  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div>
          {/* rental point name */}
          <h5>{rentalPointRootData?.basicInformation?.title?.titleOriginal}</h5>

          {/* location */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.pointAddress}
            </p>
          </div>
        </div>
      </div>
      {/* <WidgetListFileV4
          viewFiles={item?.rentalPointRootData?.files?.photoFiles || []}
          // loadingStateName=""
        /> */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          boxSizing: "border-box",
          gap: "2px",
        }}
      >
        {/* timetable */}
        <div
          style={{
            width: "100%",
            gridColumn: "1",
            gridRow: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            // gap: "16px",
            backgroundColor: "var(--color-gray-100)",
            boxSizing: "border-box",
            borderRadius: "16px",
            // aspectRatio: "1/1",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              padding: "16px",
            }}
          >
            <h6
              style={{
                width: "100%",
                // color: "var(--color-gray-200)",
              }}
            >
              Timetable
            </h6>
            <span
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
              className="material-symbols-outlined"
            >
              alarm_on
            </span>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: "2px",
              boxSizing: "border-box",
            }}
          >
            {rentalPointRootData?.timetable?.map((timetableItem, index) =>
              !timetableItem.isHoliday ? (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem.weekday.weekdayOriginal
                    )}
                  </p>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />

                  <div
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-success)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    <p>
                      {timetableItem?.openHours}:{timetableItem?.openMinutes}
                    </p>
                    —
                    <p>
                      {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem?.weekday?.weekdayOriginal
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-black)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    holiday
                  </p>
                </div>
              )
            )}
          </div>
        </div>

        {/* contacts */}
        {/* emails */}
        <div
          style={{
            gridColumn: "2",
            gridRow: "1",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.emailAddresses}
            fildname="email"
            title="Emails: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                mail
              </span>
            }
          />
        </div>
        {/* phones */}

        <div
          style={{
            gridColumn: "2",
            gridRow: "2",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.phoneNumbers}
            fildname="phone"
            title="Phones: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                call
              </span>
            }
          />
        </div>
      </div>
      {/* rental poiunt description */}
      {rentalPointRootData?.basicInformation?.description
        ?.descriptionOriginal && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>Description rental point</h6>
          <CollapsableText
            text={
              rentalPointRootData?.basicInformation?.description
                ?.descriptionOriginal
            }
          />
        </div>
      )}
    </div>
  );
};

const HeaderRentalPointPreview = ({
  rentalPointRootData,
  backgroundRentalPoint,
  title,
  address,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(80px + 42px + 16px)",
        backgroundColor: "var(--color-gray-200)",
        position: "relative",
        backgroundImage: `url('${backgroundRentalPoint}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        padding: "16px 24px 42px 24px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <PriorityFile
          style={{
            borderRadius: "80px",
            height: "80px",
            width: "80px",
          }}
          files={rentalPointRootData?.files?.logoFiles}
        />
        {/* icon location */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px",
                height: "24px",
                padding: "0 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="p-light">
                {rentalPointRootData?.location?.country?.title}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "4px 0",
                height: "24px",
                padding: "0 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="p-light">
                {rentalPointRootData?.location?.city?.title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactsList = ({ contacts, fildname, title, isMobile, icon }) => {
  const [showAll, setShowAll] = useState(false);

  const visibleContacts = contacts?.slice(0, 1);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: contacts?.length > 1 ? "space-between" : "flex-end",
        boxSizing: "border-box",
      }}
    >
      {Array.isArray(contacts) && contacts?.length > 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            // backgroundColor: "var(--color-gray-800)",
            padding: "16px",
          }}
        >
          <p
            className="p-light"
            // style={{
            //   color: "var(--color-gray-500)",
            // }}
          >
            {title && (
              <h6
              // style={{
              //   color: "var(--color-gray-200)",
              // }}
              >
                {title}
                {contacts.length}
              </h6>
            )}
          </p>
          {icon ? (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
            >
              {icon}
            </span>
          ) : (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
              className="material-symbols-outlined"
            >
              keyboard_arrow_right
            </span>
          )}
        </div>
      )}

      <div
        style={{
          width: "100%",
          padding: "0 16px 8px 16px",
          boxSizing: "border-box",
        }}
      >
        {visibleContacts?.map((contactItem, index) => (
          <div
            key={contactItem.key || index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
              boxSizing: "border-box",
            }}
          >
            <p
              className="p-light"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "20ch" : "40ch",
                // color: "var(--color-gray-500)",
                boxSizing: "border-box",
              }}
            >
              {fildname && contactItem[fildname]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { truncateText } from "../../../../../functions/truncateText";
import { dateFormat } from "../../../../../functions/convertDate";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
const WidgetBikeItemDataV4 = React.memo(
  ({
    item,
    index,
    groupItems,
    onUpdateQuantity,
    onRemoveItem,
    handleOpenWindowReplacementBikeV4,
    isMobile,
  }) => {
    // const handleQuantityChange = (newQuantity) => {
    //   if (newQuantity >= 1) {
    //     onUpdateQuantity(item, newQuantity);
    //   }
    // };

    // const handleRemoveItem = () => {
    //   onRemoveItem(item);
    // };

    const singleBikeCost = item?.totalCostBike / (item?.totals?.quantity || 1);
    const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
    const updatedTotalCost =
      updatedTotalCostBike + (item.totalCostAditioalServices || 0);

    return (
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          borderRadius:
            groupItems.length === 1
              ? "16px"
              : `${
                  index === 0
                    ? "16px 16px 0 0"
                    : index === groupItems.length - 1
                    ? "0 0 16px 16px"
                    : "0"
                }`,
          padding: "16px",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "24px",
        }}
      >
        <div
          style={{
            height: "100%",
            gridColumn: "span 3",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <PriorityFile
              files={item.bikeData.files.photoFiles || []}
              style={{
                width: "100%",
                aspectRatio: isMobile ? "1/1" : "16/9",
              }}
            />
            {/* brand and category */}

            <div
              style={{
                position: "absolute",
                right: "8px",
                top: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <p
                className="p-light"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(10px)",
                  color: "var(--color-white)",
                }}
              >
                {item?.bikeData?.directory?.brand?.title}
              </p>

              <p
                className="p-light"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(10px)",
                  color: "var(--color-white)",
                }}
              >
                {item?.bikeData?.directory?.category?.title}
              </p>
            </div>
            {/* number order */}
            <div
              style={{
                position: "absolute",
                left: "8px",
                bottom: "8px",
              }}
            >
              <p
                className="p-light"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(10px)",
                  color: "var(--color-white)",
                }}
              >
                # {index + 1}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* Name bike and quantity */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <h5
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {truncateText(
                  item?.bikeData?.basicInformation?.title?.titleOriginal,
                  30
                )}
              </h5>
              <span
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <h6
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {item?.totals?.quantity || 1} pc
              </h6>
            </div>

            {/* date interval */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <p className="p-light" style={{ whiteSpace: "nowrap" }}>
                {dateFormat(item?.totals?.dateInterval[0] || 0, isMobile)}
              </p>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <p className="p-light" style={{ whiteSpace: "nowrap" }}>
                {dateFormat(item?.totals?.dateInterval[1] || 0, isMobile)}
              </p>
            </div>

            {/* Total days in order item */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <p className="p-light">
                Day
                {item?.totals?.daysInInterval > 1 && "s"}
              </p>
              <span
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <h6>{item?.totals?.daysInInterval || 0}</h6>
            </div>

            {/* Total cost bike item */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <p
                className="p-light"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Total cost
              </p>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <h6>{formatCurrencyUSD(updatedTotalCostBike)}</h6>
            </div>
          </div>
        </div>
        {item?.orderedAditionalServices?.length > 0 && (
          <div
            style={{
              gridColumn: "span 3",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {item?.orderedAditionalServices?.map((service, idx) => (
                  <div
                    key={service?.id || idx}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <p
                        className="p-light"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {truncateText(service?.title?.titleOriginal, 55)}
                      </p>
                      <span
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                        className="material-symbols-outlined p-light"
                      >
                        close_small
                      </span>
                      <p
                        className="p-light"
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                      >
                        {service?.countOrderedServices}
                      </p>
                    </span>

                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-gray-200)",
                        height: "1px",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        color: "var(--color-black)",
                        fontWeight: "600",
                      }}
                    >
                      {formatCurrencyUSD(
                        service?.price * service?.countOrderedServices || 0
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="p-light">TOTAL:</p>
                <h6>{formatCurrencyUSD(updatedTotalCost)}</h6>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default WidgetBikeItemDataV4;

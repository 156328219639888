import { createSlice } from "@reduxjs/toolkit";
import { createDataThunkV4 } from "./thunk/catalog/V4/CRUD/createDataThunkV4";
import { updateFilePriorityThunkV4 } from "./thunk/catalog/V4/updateFilePriorityThunkV4";
import { deleteFilesThunkV4 } from "./thunk/catalog/V4/deleteFilesThunkV4";
import { fetchPreviewDataV4 } from "./thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import { fetchRootDataThunkV4 } from "./thunk/catalog/V4/CRUD/fetchRootDataThunkV4";
import { updateDataThunkV4 } from "./thunk/catalog/V4/CRUD/updateDataThunkV4";
import { deleteDataThunkV4 } from "./thunk/catalog/V4/CRUD/deleteDataThunkV4";
import { emailSignInThunkV4 } from "./thunk/user/emailSignInThunkV4";
import { emailSignUpThunkV4 } from "./thunk/user/emailSignUpThunkV4";
import { logoutThunkV4 } from "./thunk/user/logoutThunkV4";
import { googleSignUpThunkV4 } from "./thunk/user/googleSignUpThunkV4";

// Утилита для получения состояния по пути
// const getStateByPath = (state, path) => {
//   return path.split(".").reduce((acc, key) => acc && acc[key], state);
// };

const getStateByPath = (state, path) => {
  if (!path || typeof path !== "string") {
    return undefined; // Если путь пустой или не строка
  }

  return path.split(".").reduce((acc, key) => {
    if (acc && typeof acc === "object" && acc.hasOwnProperty(key)) {
      return acc[key]; // Если ключ существует, продолжаем углубляться
    }
    return undefined; // Если ключ отсутствует, возвращаем undefined
  }, state);
};

// Утилита для установки значения в состоянии по пути
const setStateByPath = (state, path, value) => {
  const keys = path?.split(".");
  let current = state;

  for (let i = 0; i < keys?.length - 1; i++) {
    const key = keys[i];

    // Проверяем, является ли ключ индексом массива
    const isArrayIndex = !isNaN(parseInt(key));

    if (isArrayIndex) {
      const index = parseInt(key);
      if (!Array.isArray(current)) {
        // Если current не массив, создаем массив на текущем уровне
        current[keys[i - 1]] = [];
      }
      if (!current[index]) {
        current[index] = {}; // Создаем элемент массива, если он отсутствует
      }
      current = current[index];
    } else {
      // Работаем с объектами
      if (!current[key]) {
        current[key] = {}; // Создаем вложенные объекты, если они отсутствуют
      }
      current = current[key];
    }
  }

  const lastKey = keys[keys?.length - 1];
  const isArrayIndex = !isNaN(parseInt(lastKey));

  if (isArrayIndex) {
    const index = parseInt(lastKey);
    if (!Array.isArray(current)) {
      current[keys[keys.length - 2]] = []; // Присваиваем массив предыдущему объекту
    }
    current[index] = value; // Обновляем элемент массива по индексу
  } else {
    current[lastKey] = value; // Обновляем значение объекта
  }
};

const initializeStateByPath = (state, path, initialValue = {}) => {
  const keys = path.split(".");
  let current = state;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (!current[key]) {
      // Если это последний ключ в пути, присваиваем initialValue
      current[key] = i === keys.length - 1 ? initialValue : {};
    }

    current = current[key];
  }
  return current;
};

/**
 *
 * User
 *
 * */
const rootUserData = {
  stateUser: {
    status: "moderation",
  },

  role: "client",

  createdat: Date.now(),

  company: {
    companyName: "",
    mailingAddress: "",
    timeZone: "",
    description: {
      descriptionOriginal: "",
    },
  },

  metaData: {
    offerConfirm: "",
    language: "en",
  },

  files: {
    businessLicenseFiles: [],
    localPermitsFiles: [],
    articlesOfAssociationFiles: [],
    avatarUrl: [],
  },

  user: {
    contactingTheUser: "",
    gender: "",
    yourGender: "",
    ownerName: "",
    ownerSurname: "",
  },

  userContacts: {
    email: "",
    phoneNumber: "",
  },

  rentalPointsIds: [],
  id: "",
};
const previewUserData = {
  createdat: Date.now(),

  avatarUrl: "",
  ownerName: "",
  email: "",
  phoneNumber: "",

  totals: {
    rentalPointsModeration: 0,
    rentalPointsApproved: 0,
    rentalPointsBanned: 0,
    bikesModeration: 0,
    bikesApproved: 0,
    bikesBanned: 0,
  },

  status: "moderation",
  role: "client",
};
/**
 *
 * RentalPoint
 *
 * */
const rootRentalPointData = {
  bikesIds: [],
  ordersIds: [],
  created: 0,

  dirrectory: {
    cityId: "",
    countryId: "",
  },

  rentalPointStatus: {
    currentStatus: "moderation",
    // comments: [
    //   {
    //     connent: "",
    //     status: "",
    //     moderatorId: "",
    //     timestamp: "",
    //     id: "",
    //   },
    // ],
  },

  basicInformation: {
    title: {
      titleOriginal: "",
    },

    description: {
      descriptionOriginal: "",
    },
  },

  location: {
    pointAddress: "",
    location: {
      lat: null,
      lng: null,
    },
    city: {},
    country: {},
  },

  files: {
    licenseFile: [],
    photoFiles: [],
    logoFiles: [],
  },

  contacts: {
    emailAddresses: [],
    phoneNumbers: [],
  },

  additionalServices: [],

  timetable: [
    {
      weekday: {
        weekdayOriginal: "Monday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Tuesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Wednesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Thursday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Friday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Saturday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
    {
      weekday: {
        weekdayOriginal: "Sunday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
  ],
};
const previewRentalPointData = {
  created: 0,

  rentalPointStatus: "moderation",
  title: "",
  description: "",

  files: {
    licenseFile: "",
    photoFiles: "",
    logoFile: "",
  },

  totals: {
    bikesModeration: 0,
    bikesApproved: 0,
    bikesBanned: 0,
  },
};
/**
 *
 * Bikes
 *
 */
const rootBikeData = {
  bikeStatus: { currentStatus: "moderation" },
  created: 0,

  basicInformation: {
    title: {
      titleOriginal: "",
    },
    description: {
      descriptionOriginal: "",
    },
  },

  directory: {
    category: {},
    brand: {},
    // city:{},
    // country:{}
  },

  // characteristics: [],

  metricsBike: {
    quantityBikes: 0,
  },

  // files: {},

  rate: [
    {
      month: 1,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 2,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 3,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 4,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 5,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 6,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 7,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 8,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 9,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 10,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 11,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 12,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
  ],
};
const previewBikeData = {
  bikeStatus: "moderation",
  created: 0,
  title: "",
  description: "",
};
/**
 *
 * Orders
 *
 * */
const rootOrderData = {
  orderStatus: "new",
  created: 0,
  orderedBikes: [],
  client: {},
  total: {},
};

const previewOrderData = {
  orderStatus: "new",
  created: 0,
  total: {
    quantityBikesOrder: 0,
    totalCost: 0,
    totalBikes: 0,
    totalCostAdditionalServices: 0,
  },
};

const catalogSliceV4 = createSlice({
  name: "catalogSliceV4",
  initialState: {
    previewNewData: {
      previewUserData: previewUserData,
      previewRentalPointData: previewRentalPointData,
      previewBikeData: previewBikeData,
      previewOrderData: previewOrderData,
    },

    rootNewData: {
      rootUserData: rootUserData,
      rootRentalPointData: rootRentalPointData,
      rootBikeData: rootBikeData,
      rootOrderData: rootOrderData,
    },

    tempPreviewData: {},
    tempRootData: {},
    tempData: {},

    loading: {
      loading_signIn: false,
      loading_signUp: false,
      loading_logOut: false,
    },

    error: {
      error_signIn: null,
      error_signUp: null,
      error_logOut: null,
    },

    // Системый state который используется для хранения структуры firestore
    users: {},
    countries: {},
    brands: {},
    bikes: {},
    manager_bikesPreview: {},
    categories: {},
    rentalPoints: {},
    manager_rentalPointsPrevirew: {},
    manager_ordersPreview: {},
  },

  reducers: {
    setCurrentPreviewData: (state, action) => {
      const { currentPreviewDocumenState, data } = action.payload;
      setStateByPath(state.tempPreviewData, currentPreviewDocumenState, data);
    },

    setCurrentRootData: (state, action) => {
      const { rootCurrentDocumentState, data } = action.payload;
      setStateByPath(state.tempRootData, rootCurrentDocumentState, data);
    },

    setTempData: (state, action) => {
      const { tempCurrentDocumentState, data } = action.payload;
      setStateByPath(state.tempData, tempCurrentDocumentState, data);
    },

    /** subscribePreviewDocumentsThunkV4 */
    updateDocuments: (state, action) => {
      const { documents, previewCurrentDocumenState } = action.payload;
      state.tempPreviewData[previewCurrentDocumenState] = documents;
    },
    setLoadingState: (state, action) => {
      const { loadingStateName, isLoading } = action.payload;
      state.loading[loadingStateName] = isLoading;
    },
    setErrorState: (state, action) => {
      const { errorStateName, errorMessage } = action.payload;
      state.error[errorStateName] = errorMessage;
    },
    /** subscribeRootDocumentsThunkV4 */
    updateRootDocuments: (state, action) => {
      const { documents, rootCurrentDocumenState } = action.payload;
      state.tempRootData[rootCurrentDocumenState] = documents;
    },
    setResetErrorLogin: (state, action) => {
      state.error = {
        error_signIn: null,
        error_signUp: null,
        error_logOut: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder

      /* *** Добавляем обработку fetchPreviewDataV4 *** */

      .addCase(fetchPreviewDataV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        if (loadingStateName) {
          state.loading[loadingStateName] = true;
        }
        state.error[errorStateName] = null;
      })

      .addCase(fetchPreviewDataV4.fulfilled, (state, action) => {
        const {
          previewCollectionPath,
          previewDocumentsData,
          lastUpdated,
          previewCurrentDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Инициализируем previewState на основе пути
        let previewState = initializeStateByPath(state, previewCollectionPath, {
          data: [],
          lastUpdated: null,
        });

        // Обновляем данные в previewState
        previewState.data = previewDocumentsData;
        previewState.lastUpdated = lastUpdated;

        // Обновляем текущие элементы
        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] =
            previewDocumentsData || [];
        }

        // Устанавливаем состояние загрузки и ошибки
        if (loadingStateName) {
          state.loading[loadingStateName] = false;
        }
        state.error[errorStateName] = null;
      })

      .addCase(fetchPreviewDataV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        if (loadingStateName) {
          state.loading[loadingStateName] = false;
        }
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку fetchRootDataThunkV4 *** */

      .addCase(fetchRootDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;

        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = true;

        // Устанавливаем ошибку
        state.error[errorStateName] = null;
      })
      .addCase(fetchRootDataThunkV4.fulfilled, (state, action) => {
        const {
          rootCollectionPath,
          rootDocumentData,
          lastUpdated,
          rootCurrentDocumentState,
        } = action.payload;
        const { loadingStateName, errorStateName } = action.meta.arg;

        console.log("Получен rootCollectionPath:", rootCollectionPath);
        console.log("Получен rootDocumentData:", rootDocumentData);

        // Разбиваем rootCollectionPath на ключи
        const rootKeys = rootCollectionPath.split(".");

        // Навигируем или создаем вложенную структуру в состоянии для rootCollectionPath
        let rootState = state;
        for (let i = 0; i < rootKeys.length; i++) {
          const key = rootKeys[i];
          if (i === rootKeys.length - 1) {
            if (!rootState[key] || Array.isArray(rootState[key])) {
              rootState[key] = {}; // Инициализация объекта
            }
          } else {
            if (!rootState[key] || Array.isArray(rootState[key])) {
              rootState[key] = {}; // Инициализация объекта
            }
          }
          rootState = rootState[key];
        }

        console.log("rootState перед проверкой наличия документа:", rootState);

        // Проверяем наличие объекта по idRootDocument и его актуальность
        const existingDocument = rootState[rootDocumentData.idRootDocument];
        if (existingDocument && existingDocument.lastUpdated >= lastUpdated) {
          console.log(
            `Документ с idRootDocument ${rootDocumentData.idRootDocument} уже существует и актуален.`
          );
        } else {
          rootState[rootDocumentData.idRootDocument] = {
            ...rootDocumentData,
            lastUpdated,
          };
        }

        // Обновляем глобальный стейт только если есть rootCurrentDocumentState
        if (rootCurrentDocumentState) {
          if (Array.isArray(rootCurrentDocumentState)) {
            rootCurrentDocumentState.forEach((statePath) => {
              state.tempRootData[statePath] = {
                ...rootDocumentData,
                lastUpdated,
              };
            });
          } else {
            state.tempRootData[rootCurrentDocumentState] = {
              ...rootDocumentData,
              lastUpdated,
            };
          }
        }

        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(fetchRootDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        // Сбрасываем состояние загрузки
        state.loading[loadingStateName] = false;

        // Устанавливаем ошибку
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку createDataThunkV4 *** */

      .addCase(createDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;

        state.error[errorStateName] = null;
      })
      .addCase(createDataThunkV4.fulfilled, (state, action) => {
        const {
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootDocumentData,
          previewDocumentData,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Инициализация rootState
        let rootState = initializeStateByPath(state, rootCollectionPath);
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Инициализация previewState
        let previewState = initializeStateByPath(state, previewCollectionPath, {
          data: [],
          lastUpdated: null,
        });

        previewState.data.push(previewDocumentData);
        previewState.lastUpdated = Date.now();

        // Инициализация generalPreviewState, если previewGeneralCollectionPath существует
        if (previewGeneralCollectionPath) {
          let generalPreviewState = initializeStateByPath(
            state,
            previewGeneralCollectionPath,
            {
              data: [],
              lastUpdated: null,
            }
          );

          generalPreviewState.data.push(previewDocumentData);
          generalPreviewState.lastUpdated = Date.now();
        }

        // Обновляем tempRootData для rootCurrentDocumentState
        if (rootCurrentDocumentState) {
          if (Array.isArray(rootCurrentDocumentState)) {
            rootCurrentDocumentState.forEach((statePath) => {
              state.tempRootData[statePath] = rootDocumentData;
            });
          } else {
            state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
          }
        }

        // Обновляем tempPreviewData для previewCurrentDocumenState
        if (previewCurrentDocumenState) {
          if (Array.isArray(previewCurrentDocumenState)) {
            previewCurrentDocumenState.forEach((statePath) => {
              state.tempPreviewData[statePath] = previewState.data;
            });
          } else {
            state.tempPreviewData[previewCurrentDocumenState] =
              previewState.data;
          }
        }

        // Обновляем tempPreviewData для previewGeneralDocumenState
        if (previewGeneralDocumenState) {
          if (Array.isArray(previewGeneralDocumenState)) {
            previewGeneralDocumenState.forEach((statePath) => {
              state.tempPreviewData[statePath] =
                state.tempPreviewData[statePath] || [];
              state.tempPreviewData[statePath].push(previewDocumentData);
            });
          } else {
            state.tempPreviewData[previewGeneralDocumenState] =
              state.tempPreviewData[previewGeneralDocumenState] || [];
            state.tempPreviewData[previewGeneralDocumenState].push(
              previewDocumentData
            );
          }
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(createDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, loadinocalStateName, errorStateName } =
          action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку updateDataThunkV4 *** */

      .addCase(updateDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(updateDataThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          idRootDocument,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // === Блок обновления rootState ===

        // Инициализируем rootState на основе пути rootCollectionPath
        let rootState = initializeStateByPath(state, rootCollectionPath, {});

        // Обновляем или добавляем документ в rootState по ID основного документа
        rootState[idRootDocument] = rootDocumentData;

        // === Блок обновления previewState ===

        // Инициализируем previewState на основе пути previewCollectionPath
        let previewState = initializeStateByPath(state, previewCollectionPath, {
          data: [],
          lastUpdated: null,
        });

        // Обновляем или добавляем previewDocumentData в previewState
        const updatePreviewState = (stateObj) => {
          const index = stateObj?.data?.findIndex(
            (item) => item?.idRootDocument === idRootDocument
          );

          if (index !== -1) {
            stateObj.data[index] = previewDocumentData;
          } else {
            stateObj?.data.push(previewDocumentData);
          }

          stateObj.lastUpdated = Date.now();
        };

        updatePreviewState(previewState);

        // === Блок обновления generalPreviewState, если предоставлен previewGeneralCollectionPath ===

        if (previewGeneralCollectionPath) {
          let generalPreviewState = initializeStateByPath(
            state,
            previewGeneralCollectionPath,
            {
              data: [],
              lastUpdated: null,
            }
          );

          updatePreviewState(generalPreviewState);
        }

        // === Блок обновления временных данных ===

        // Если rootCurrentDocumentState передан, обновляем текущее состояние основного документа
        if (rootCurrentDocumentState && rootDocumentData) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        // Если previewCurrentDocumenState передан, обновляем состояние документа предварительного просмотра
        if (previewCurrentDocumenState && previewDocumentData) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        // Если previewGeneralDocumenState передан, обновляем состояние общего документа предварительного просмотра
        if (previewGeneralDocumenState && previewDocumentData) {
          let generalData =
            state.tempPreviewData[previewGeneralDocumenState] || [];
          const generalIndex = generalData.findIndex(
            (item) => item.idRootDocument === idRootDocument
          );

          if (generalIndex !== -1) {
            generalData[generalIndex] = previewDocumentData;
          } else {
            generalData.push(previewDocumentData);
          }

          state.tempPreviewData[previewGeneralDocumenState] = generalData;
        }

        // === Блок сброса состояния загрузки и ошибок ===

        // Устанавливаем состояние загрузки как завершённое
        state.loading[loadingStateName] = false;

        // Сбрасываем ошибки, если они были
        state.error[errorStateName] = null;
      })
      .addCase(updateDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;

        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку deleteDataThunkV4 *** */

      .addCase(deleteDataThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(deleteDataThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          previewGeneralDocumentData, // Added
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath, // Added
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState, // Added
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // === Update rootState ===
        let rootState = initializeStateByPath(state, rootCollectionPath, {});

        if (rootState[rootDocumentData.idRootDocument]) {
          rootState[rootDocumentData.idRootDocument].remove =
            rootDocumentData.remove;
        } else {
          rootState[rootDocumentData.idRootDocument] = rootDocumentData;
        }

        // === Update previewState ===
        let previewState = initializeStateByPath(state, previewCollectionPath, {
          data: [],
          lastUpdated: null,
        });

        const previewIndex = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );

        if (previewIndex !== -1) {
          previewState.data[previewIndex].remove = previewDocumentData.remove;
        } else {
          previewState.data.push(previewDocumentData);
        }

        previewState.lastUpdated = Date.now();

        // === Update generalPreviewState if provided ===
        if (previewGeneralCollectionPath && previewGeneralDocumentData) {
          let generalPreviewState = initializeStateByPath(
            state,
            previewGeneralCollectionPath,
            {
              data: [],
              lastUpdated: null,
            }
          );

          const generalPreviewIndex = generalPreviewState.data.findIndex(
            (item) =>
              item.idRootDocument === previewGeneralDocumentData.idRootDocument
          );

          if (generalPreviewIndex !== -1) {
            generalPreviewState.data[generalPreviewIndex].remove =
              previewGeneralDocumentData.remove;
          } else {
            generalPreviewState.data.push(previewGeneralDocumentData);
          }

          generalPreviewState.lastUpdated = Date.now();
        }

        // === Update temporary data ===
        if (rootCurrentDocumentState) {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        if (previewCurrentDocumenState) {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        if (previewGeneralDocumenState && previewGeneralDocumentData) {
          state.tempPreviewData[previewGeneralDocumenState] =
            state.tempPreviewData[previewGeneralDocumenState] || [];
          const generalIndex = state.tempPreviewData[
            previewGeneralDocumenState
          ].findIndex(
            (item) =>
              item.idRootDocument === previewGeneralDocumentData.idRootDocument
          );

          if (generalIndex !== -1) {
            state.tempPreviewData[previewGeneralDocumenState][generalIndex] =
              previewGeneralDocumentData;
          } else {
            state.tempPreviewData[previewGeneralDocumenState].push(
              previewGeneralDocumentData
            );
          }
        }

        // === Reset loading and error states ===
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(deleteDataThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку deleteFilesThunkV4 *** */

      .addCase(deleteFilesThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(deleteFilesThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }
        const index = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );
        if (index !== -1) previewState.data[index] = previewDocumentData;
        else previewState.data.push(previewDocumentData);
        previewState.lastUpdated = Date.now();

        // Обновляем previewGeneralCollectionPath, если оно существует
        if (previewGeneralCollectionPath) {
          let generalPreviewState = getStateByPath(
            state,
            previewGeneralCollectionPath
          );
          if (!generalPreviewState) {
            setStateByPath(state, previewGeneralCollectionPath, {
              data: [],
              lastUpdated: null,
            });
            generalPreviewState = getStateByPath(
              state,
              previewGeneralCollectionPath
            );
          }
          const genIndex = generalPreviewState.data.findIndex(
            (item) => item.idRootDocument === previewDocumentData.idRootDocument
          );
          if (genIndex !== -1)
            generalPreviewState.data[genIndex] = previewDocumentData;
          else generalPreviewState.data.push(previewDocumentData);
          generalPreviewState.lastUpdated = Date.now();
        }

        // Обновляем текущие элементы для rootCurrentDocumentState
        if (rootCurrentDocumentState) {
          if (Array.isArray(rootCurrentDocumentState)) {
            rootCurrentDocumentState.forEach((statePath) => {
              setStateByPath(state.tempRootData, statePath, rootDocumentData);
            });
          } else {
            state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
          }
        }

        // Обновляем текущие элементы для previewCurrentDocumenState
        if (previewCurrentDocumenState) {
          if (Array.isArray(previewCurrentDocumenState)) {
            previewCurrentDocumenState.forEach((statePath) => {
              setStateByPath(
                state.tempPreviewData,
                statePath,
                previewState.data
              );
            });
          } else {
            state.tempPreviewData[previewCurrentDocumenState] =
              previewState.data;
          }
        }

        // Обновляем текущие элементы для previewGeneralDocumenState
        if (previewGeneralDocumenState) {
          if (Array.isArray(previewGeneralDocumenState)) {
            previewGeneralDocumenState.forEach((statePath) => {
              state.tempPreviewData[statePath] =
                state.tempPreviewData[statePath] || [];
              state.tempPreviewData[statePath].push(previewDocumentData);
            });
          } else {
            state.tempPreviewData[previewGeneralDocumenState] =
              state.tempPreviewData[previewGeneralDocumenState] || [];
            state.tempPreviewData[previewGeneralDocumenState].push(
              previewDocumentData
            );
          }
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })
      .addCase(deleteFilesThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку updateFilePriorityThunkV4 *** */

      .addCase(updateFilePriorityThunkV4.pending, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = true;
        state.error[errorStateName] = null;
      })
      .addCase(updateFilePriorityThunkV4.fulfilled, (state, action) => {
        const {
          rootDocumentData,
          previewDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
        } = action.payload;

        const { loadingStateName, errorStateName } = action.meta.arg;

        // Обновляем rootState
        let rootState = getStateByPath(state, rootCollectionPath);
        if (!rootState) {
          setStateByPath(state, rootCollectionPath, {});
          rootState = getStateByPath(state, rootCollectionPath);
        }
        rootState[rootDocumentData.idRootDocument] = rootDocumentData;

        // Обновляем previewState
        let previewState = getStateByPath(state, previewCollectionPath);
        if (!previewState) {
          setStateByPath(state, previewCollectionPath, {
            data: [],
            lastUpdated: null,
          });
          previewState = getStateByPath(state, previewCollectionPath);
        }
        const index = previewState.data.findIndex(
          (item) => item.idRootDocument === previewDocumentData.idRootDocument
        );
        if (index !== -1) previewState.data[index] = previewDocumentData;
        else previewState.data.push(previewDocumentData);
        previewState.lastUpdated = Date.now();

        // Обновляем previewGeneralState, если previewGeneralCollectionPath предоставлен
        if (previewGeneralCollectionPath) {
          let generalPreviewState = getStateByPath(
            state,
            previewGeneralCollectionPath
          );
          if (!generalPreviewState) {
            setStateByPath(state, previewGeneralCollectionPath, {
              data: [],
              lastUpdated: null,
            });
            generalPreviewState = getStateByPath(
              state,
              previewGeneralCollectionPath
            );
          }
          const genIndex = generalPreviewState.data.findIndex(
            (item) => item.idRootDocument === previewDocumentData.idRootDocument
          );
          if (genIndex !== -1)
            generalPreviewState.data[genIndex] = previewDocumentData;
          else generalPreviewState.data.push(previewDocumentData);
          generalPreviewState.lastUpdated = Date.now();
        }

        // Обновляем текущие элементы для rootCurrentDocumentState
        if (Array.isArray(rootCurrentDocumentState)) {
          rootCurrentDocumentState.forEach((statePath) => {
            setStateByPath(state.tempRootData, statePath, rootDocumentData);
          });
        } else {
          state.tempRootData[rootCurrentDocumentState] = rootDocumentData;
        }

        // Обновляем текущие элементы для previewCurrentDocumenState
        if (Array.isArray(previewCurrentDocumenState)) {
          previewCurrentDocumenState.forEach((statePath) => {
            setStateByPath(state.tempPreviewData, statePath, previewState.data);
          });
        } else {
          state.tempPreviewData[previewCurrentDocumenState] = previewState.data;
        }

        // Обновляем текущие элементы для previewGeneralDocumenState
        if (Array.isArray(previewGeneralDocumenState)) {
          previewGeneralDocumenState.forEach((statePath) => {
            state.tempPreviewData[statePath] =
              state.tempPreviewData[statePath] || [];
            state.tempPreviewData[statePath].push(previewDocumentData);
          });
        } else {
          state.tempPreviewData[previewGeneralDocumenState] =
            state.tempPreviewData[previewGeneralDocumenState] || [];
          state.tempPreviewData[previewGeneralDocumenState].push(
            previewDocumentData
          );
        }

        state.loading[loadingStateName] = false;
        state.error[errorStateName] = null;
      })

      .addCase(updateFilePriorityThunkV4.rejected, (state, action) => {
        const { loadingStateName, errorStateName } = action.meta.arg;
        state.loading[loadingStateName] = false;
        state.error[errorStateName] = action.payload || action.error.message;
      })

      /* *** Добавляем обработку emailSignInThunkV4 *** */

      .addCase(emailSignInThunkV4.pending, (state) => {
        state.loading.loading_signIn = true;
        state.error.error_signIn = null;
      })
      .addCase(emailSignInThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signIn = false;
        state.error.error_signIn = null;
      })
      .addCase(emailSignInThunkV4.rejected, (state, action) => {
        state.loading.loading_signIn = false;
        state.error.error_signIn = action.payload.errorMessage;
      })

      /* *** Добавляем обработку emailSignUpThunkV4 *** */

      .addCase(emailSignUpThunkV4.pending, (state) => {
        state.loading.loading_signUp = true;
        state.error.error_signUp = null;
      })
      .addCase(emailSignUpThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = null;
      })
      .addCase(emailSignUpThunkV4.rejected, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = action.payload.errorMessage;
      })

      /* *** Добавляем обработку googleSignUpThunkV4 *** */

      .addCase(googleSignUpThunkV4.pending, (state) => {
        state.loading.loading_signUp = true;
        state.error.error_signUp = null;
      })
      .addCase(googleSignUpThunkV4.fulfilled, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = null;
      })
      .addCase(googleSignUpThunkV4.rejected, (state, action) => {
        state.loading.loading_signUp = false;
        state.error.error_signUp = action.payload.errorMessage;
      })

      /* *** Добавляем обработку logoutThunkV4 *** */

      .addCase(logoutThunkV4.pending, (state) => {
        state.loading.loading_logOut = true;
        state.error.error_logOut = null;
      })
      .addCase(logoutThunkV4.fulfilled, (state, action) => {
        state.loading.loading_logOut = false;
        state.error.error_logOut = null;
      })
      .addCase(logoutThunkV4.rejected, (state, action) => {
        state.loading.loading_logOut = false;
        state.error.error_logOut = action.payload.errorMessage;
      });
  },
});

export const {
  setCurrentPreviewData,
  setCurrentRootData,

  setTempData,
  setPreviewCurrentCountry,
  setRootCurrentCountry,
  setRootCurrentCity,
  setPreviewCurrentCity,
  updateDocuments,
  updateRootDocuments,
  setLoadingState,
  setErrorState,
  setResetErrorLogin,
} = catalogSliceV4.actions;
export const { reducer: catalogReducer } = catalogSliceV4;
export default catalogSliceV4.reducer;

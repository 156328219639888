import { createSlice } from "@reduxjs/toolkit";
import { updateBikeModerationThunkV2 } from "./thunk/moderation/updateBikeModerationThunkV2";
import { useSelector } from "react-redux";
import { updateRentalPointsModerationThunkV2 } from "./thunk/moderation/updateRentalPointsModerationThunkV2";
import { saveUserModerationDataThankV2 } from "./thunk/moderation/saveUserModerationDataThankV2";
import { addRentalPointsModerationThunkV2 } from "./thunk/moderation/addRentalPointsModerationThunkV2";
import { addBikeModerationThunkV2 } from "./thunk/moderation/addBikeModerationThunkV2";

const user = {
  moderation: {
    status: "moderation",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  createdat: 0,
  company: {
    companyName: "",
    mailingAddress: "",
    timeZone: "",
    description: {
      descriptionOriginal: "",
    },
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },
  offerConfirm: "",
  rentalPointsIds: [],
  role: "rental",
  stateProfile: "new",
  user: {
    files: {
      avatarFiles: [],
    },
    contactingTheUser: "",
    gender: "",
    yourGender: "",
    ownerName: "",
    ownerSurname: "",
  },
  userContacts: {
    email: "",
    phoneNumber: "",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },
};

const bike = {
  created: 0,

  moderation: {
    status: "new",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  basicInformation: {
    title: {
      titleOriginal: "",
    },
    description: {
      descriptionOriginal: "",
    },
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  directory: {
    categoryId: "",
    brandId: "",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  characteristics: [
    {
      title: {
        titleOriginal: "",
      },
      description: {
        descriptionOriginal: "",
      },
      characteristics: "",
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
  ],

  metricsBike: {
    quantityBikes: 0,
  },

  files: {
    bikePhotoFiles: [],
  },

  rate: [
    {
      month: 1,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 2,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 3,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 4,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 5,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 6,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 7,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 8,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 9,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 10,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 11,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
    {
      month: 12,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
    },
  ],
};

const rentalPoint = {
  bikesIds: [],
  ordersIds: [],

  rentalPointStatus: {
    currentStatus: "moderation",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  directory: {
    cityId: "",
    countryId: "",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  created: 0,

  basicInformation: {
    title: {
      titleOriginal: "",
    },

    description: {
      descriptionOriginal: "",
    },
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  location: {
    pointAddress: "",
    location: {
      lat: null,
      lng: null,
    },
    cityId: "",
    countryId: "",
    city: "",
    country: "",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  files: {
    mapFiles: [],
    photoFiles: [],
    logoFile: [],
  },

  contacts: {
    emailAddresses: [],
    phoneNumbers: [],
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  additionalServices: {
    services: [],
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  timetable: [
    {
      weekday: {
        weekdayOriginal: "Monday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Tuesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Wednesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Thursday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Friday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Saturday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
    {
      weekday: {
        weekdayOriginal: "Sunday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
      historyChanges: [
        {
          userId: "",
          timestamp: 0,
          adjustments: {},
        },
      ],
    },
  ],
};

const addHistoryChange = (state, userId, adjustments) => {
  const timestamp = Date.now();

  // Проверка, что массив historyChanges существует и является массивом
  if (!state.historyChanges || !Array.isArray(state.historyChanges)) {
    state.historyChanges = [];
  }

  // Проверка, что массив historyChanges расширяем
  if (!Object.isExtensible(state.historyChanges)) {
    state.historyChanges = [...state.historyChanges];
  }

  // Добавляем новую запись в historyChanges
  state.historyChanges.push({
    userId,
    timestamp,
    adjustments,
  });

  // Ограничиваем длину массива historyChanges
  if (state.historyChanges.length > HISTORY_CHANGES_LIMIT) {
    state.historyChanges.shift();
  }
};

const HISTORY_CHANGES_LIMIT = process.env.REACT_APP_HISTORY_CHANGES_LIMIT || 10;

const moderationSliceV2 = createSlice({
  name: "reducersToolkit/moderationSliceV2",
  initialState: {
    isLoading: false,
    isLoadingUsers: false,
    isLoadingBikes: false,
    isLoadingRentalPoints: false,
    error: null,

    rentalPoints: [],
    bikes: [],
    users: [],

    usersModeration: [],
    usersApproved: [],
    usersBanned: [],

    selectedUser: {},
    selectedUserRentalPoints: [],
    newUser: { ...user },

    selectedRentalPoint: {},
    selectedRentalPointBikes: [],
    newRentalPoint: { ...rentalPoint },
    selectedAdditionalServiceRentalPoint: {},

    selectedBike: {},
    newBike: { ...bike },
  },
  reducers: {
    clearUserForm(state, action) {
      state.selectedUser = user;
    },
    clearRentalPointForm(state, action) {
      state.selectedRentalPoint = rentalPoint;
    },
    clearBikePointForm(state, action) {
      state.selectedBike = bike;
    },

    setMetricsBikeModeration(state, action) {
      const { mode, counterValues } = action.payload;
      if (mode === "moderation") {
        state.selectedBike.metricsBike.quantityBikes = counterValues;
      } else if (mode === "moderatorAdded") {
        state.newBike.metricsBike.quantityBikes = counterValues;
      }
    },

    setSelectedAdditionalServiceRentalPoint(state, action) {
      state.selectedAdditionalServiceRentalPoint = action.payload;
    },

    setRateSelectedBikeModeration(state, action) {
      const { index, newRate, mode } = action.payload;
      if (mode === "edit" || mode === "moderation") {
        if (
          state.selectedBike.rate &&
          index >= 0 &&
          index < state.selectedBike.rate.length
        ) {
          state.selectedBike.rate[index] = newRate;
        }
      } else if (mode === "moderatorAdded") {
        if (
          state.newBike.rate &&
          index >= 0 &&
          index < state.newBike.rate.length
        ) {
          state.newBike.rate[index] = newRate;
        }
      }
    },

    setSelectedBikeCategoryIdModeration(state, action) {
      state.selectedBike.directory.categoryesId = action.payload;
    },

    setSelectedBikeBrandIdModeration(state, action) {
      if (!state.selectedBike.directory) {
        state.selectedBike.directory = {};
      }
      state.selectedBike.directory.brandId = action.payload;
    },

    setSelectedBikeBasicInformation(state, action) {
      const { title, description, mode } = action.payload;
      if (mode === "moderation") {
        state.selectedBike.basicInformation.title.titleOriginal = title;
        state.selectedBike.basicInformation.description.descriptionOriginal =
          description;
      } else if (mode === "moderatorAdded") {
        state.newBike.basicInformation.title.titleOriginal = title;
        state.newBike.basicInformation.description.descriptionOriginal =
          description;
      }
    },

    setSelectedBikeModeration(state, action) {
      state.selectedBike = action.payload;
    },

    setContactsEmailAddressesModeration(state, action) {
      const { mode, emailAddresses } = action.payload;

      if (mode === "moderation") {
        state.selectedRentalPoint.contacts.emailAddresses.push(emailAddresses);
      } else if (mode === "moderatorAdded") {
        state.newRentalPoint.contacts.emailAddresses.push(emailAddresses);
      }
    },

    removeContactsEmailAddressModeration(state, action) {
      const { mode, key } = action.payload;
      if (mode === "moderation") {
        state.selectedRentalPoint.contacts.emailAddresses =
          state.selectedRentalPoint.contacts.emailAddresses.filter(
            (emailAddress) => emailAddress.key !== key
          );
      } else if (mode === "moderatorAdded") {
        state.newRentalPoint.contacts.emailAddresses =
          state.newRentalPoint.contacts.emailAddresses.filter(
            (emailAddress) => emailAddress.key !== key
          );
      }
    },

    setContactsPhoneNumbersModeration(state, action) {
      const { mode, phoneNumber } = action.payload;
      if (mode === "moderation") {
        state.selectedRentalPoint.contacts.phoneNumbers.push(phoneNumber);
      } else if (mode === "moderatorAdded") {
        state.newRentalPoint.contacts.phoneNumbers.push(phoneNumber);
      }
    },

    removeContactsPhoneNumberModeration(state, action) {
      const { mode, key } = action.payload;

      if (mode === "moderation") {
        state.selectedRentalPoint.contacts.phoneNumbers =
          state.selectedRentalPoint.contacts.phoneNumbers.filter(
            (phoneNumbers) => phoneNumbers.key !== key
          );
      } else if (mode === "moderatorAdded") {
        state.newRentalPoint.contacts.phoneNumbers =
          state.newRentalPoint.contacts.phoneNumbers.filter(
            (phoneNumbers) => phoneNumbers.key !== key
          );
      }
    },

    updateAdditionalServiceModeration(state, action) {
      const { id, additionalService, userId, mode } = action.payload;

      if (mode === "moderation") {
        const index =
          state.selectedRentalPoint.additionalServices.services.findIndex(
            (service) => service.id === id
          );

        if (index !== -1) {
          state.selectedRentalPoint.additionalServices.services[index] = {
            ...additionalService,
            // ...state.selectedRentalPoint.additionalServices[index],
            // title: {
            //   ...state.selectedRentalPoint.additionalServices[index].title,
            //   titleOriginal: additionalService.title.titleOriginal,
            // },
            // description: {
            //   ...state.selectedRentalPoint.additionalServices[index]
            //     .description,
            //   descriptionOriginal:
            //     additionalService.description.descriptionOriginal,
            // },
            // price: additionalService.price,
          };

          // addHistoryChange(
          //   state.selectedRentalPoint.additionalServices[index],
          //   userId,
          //   {
          //     ...additionalService,
          //   }
          // );
        }
      } else if (mode === "moderatorAdded") {
        const index =
          state.newRentalPoint.additionalServices.services.findIndex(
            (service) => service.id === id
          );

        if (index !== -1) {
          state.newRentalPoint.additionalServices.services[index] = {
            ...additionalService,
            // ...state.newRentalPoint.additionalServices[index],
            // title: {
            //   ...state.newRentalPoint.additionalServices[index].title,
            //   titleOriginal: additionalService.title.titleOriginal,
            // },
            // description: {
            //   ...state.newRentalPoint.additionalServices[index].description,
            //   descriptionOriginal:
            //     additionalService.description.descriptionOriginal,
            // },
            // price: additionalService.price,
          };

          // addHistoryChange(
          //   state.newRentalPoint.additionalServices[index],
          //   userId,
          //   {
          //     ...additionalService,
          //   }
          // );
        }
      }
    },

    addAdditionalServiceModeration(state, action) {
      const { mode, additionalService, userId } = action.payload;
      console.log("mode, additionalService", mode, additionalService);
      if (mode === "moderatorAdded") {
        state.newRentalPoint.additionalServices.services.push(
          additionalService
        );
      } else if (mode === "moderation") {
        state.selectedRentalPoint.additionalServices.services.push(
          additionalService
        );
      }

      // const index =
      //   state.selectedRentalPoint.additionalServices.services.length - 1;

      // if (index !== -1) {
      //   addHistoryChange(
      //     state.selectedRentalPoint.additionalServices.services[index],
      //     userId,
      //     {
      //       additionalService,
      //     }
      //   );
      // }
    },

    deleteAdditionalServiceModeration(state, action) {
      const { mode, additionalServicesId } = action.payload;
      if (mode === "moderatorAdded") {
        state.newRentalPoint.additionalServices.services =
          state.newRentalPoint.additionalServices.services.filter(
            (service) => service.id !== additionalServicesId
          );
      } else if (mode === "moderation") {
        state.selectedRentalPoint.additionalServices.services =
          state.selectedRentalPoint.additionalServices.services.filter(
            (service) => service.id !== additionalServicesId
          );
      }
    },

    setTimetableInModeration: (state, action) => {
      const { userId, index, newTimetable, mode } = action.payload;
      if (mode === "moderatorAdded") {
        // Проверяем, что массив timetable расширяем
        if (!Object.isExtensible(state.newRentalPoint.timetable)) {
          state.newRentalPoint.timetable = [...state.newRentalPoint.timetable];
        }

        // Обновите существующий объект timetable, сохраняя его свойства
        state.newRentalPoint.timetable[index] = {
          ...state.newRentalPoint.timetable[index],
          ...newTimetable,
        };

        // Исключаем объект historyChanges из newTimetable
        const { historyChanges, ...newTimetableWithoutHistory } = newTimetable;

        // Добавьте изменения в historyChanges
        addHistoryChange(state.newRentalPoint.timetable[index], userId, {
          ...newTimetableWithoutHistory,
        });
      } else {
        // Проверяем, что массив timetable расширяем
        if (!Object.isExtensible(state.selectedRentalPoint.timetable)) {
          state.selectedRentalPoint.timetable = [
            ...state.selectedRentalPoint.timetable,
          ];
        }

        // Обновите существующий объект timetable, сохраняя его свойства
        state.selectedRentalPoint.timetable[index] = {
          ...state.selectedRentalPoint.timetable[index],
          ...newTimetable,
        };

        // Исключаем объект historyChanges из newTimetable
        const { historyChanges, ...newTimetableWithoutHistory } = newTimetable;

        // Добавьте изменения в historyChanges
        addHistoryChange(state.selectedRentalPoint.timetable[index], userId, {
          ...newTimetableWithoutHistory,
        });
      }
    },

    setBasicInformationTitleModeration(state, action) {
      state.selectedRentalPoint.basicInformation.title.titleOriginal =
        action.payload;
    },

    setBasicInformationModeration(state, action) {
      const { title, description, userId, mode } = action.payload;

      if (mode === "moderation") {
        // Обновляем основную информацию
        state.selectedRentalPoint.basicInformation.title.titleOriginal = title;
        state.selectedRentalPoint.basicInformation.description.descriptionOriginal =
          description;

        addHistoryChange(state.selectedRentalPoint.basicInformation, userId, {
          title: title,
          description: description,
        });
      } else if (mode === "moderatorAdded") {
        // Обновляем основную информацию
        state.newRentalPoint.basicInformation.title.titleOriginal = title;
        state.newRentalPoint.basicInformation.description.descriptionOriginal =
          description;

        addHistoryChange(state.newRentalPoint.basicInformation, userId, {
          title: title,
          description: description,
        });
      }
    },

    setBasicInformationDescriptionModeration(state, action) {
      state.selectedRentalPoint.basicInformation.description.descriptionOriginal =
        action.payload;
    },

    setLocationModeration(state, action) {
      const { cityId, countryId, address, userId, mode } = action.payload;

      if (mode === "moderatorAdded") {
        // Инициализация в случае если state отсутствует
        if (!state.newRentalPoint.location) {
          state.newRentalPoint.location = {};
        }
        state.newRentalPoint.location.cityid = cityId;

        state.newRentalPoint.location.countryId = countryId;

        state.newRentalPoint.location.pointAddress = address;
      } else {
        // Инициализация в случае если state отсутствует
        if (!state.selectedRentalPoint.location) {
          state.selectedRentalPoint.location = {};
        }
        state.selectedRentalPoint.location.cityid = cityId;

        state.selectedRentalPoint.location.countryId = countryId;

        state.selectedRentalPoint.location.pointAddress = address;

        addHistoryChange(state.selectedRentalPoint.location, userId, {
          pointAddress: address,
          location: {
            lat: null,
            lng: null,
          },
          cityId: cityId,
          countryId: countryId,
          city: "",
          country: "",
        });
      }
    },

    // setCityIdModeration(state, action) {
    //   state.selectedRentalPoint.location.cityid = action.payload;
    // },

    // setCountryIdModeration(state, action) {
    //   state.selectedRentalPoint.location.countryId = action.payload;
    // },
    setUsersList(state, action) {
      state.users = action.payload;

      state.usersModeration = state.users.filter(
        (user) => user?.moderation?.status === "moderation"
      );
      state.usersApproved = state.users.filter(
        (user) => user?.moderation?.status === "approved"
      );
      state.usersBanned = state.users.filter(
        (user) => user?.moderation?.status === "banned"
      );
    },

    setSelectedUserModeration(state, action) {
      const userItem = action.payload;
      state.selectedUser = userItem;
      state.selectedUserRentalPoints = state.rentalPoints.filter(
        (rentalPoint) =>
          userItem.rentalPointsIds &&
          userItem.rentalPointsIds.includes(rentalPoint.id)
      );
    },

    setSelectedRentalPointModeration(state, action) {
      const rentalPointItem = action.payload;
      state.selectedRentalPoint = rentalPointItem;
      state.selectedRentalPointBikes = state.bikes.filter(
        (bike) =>
          rentalPointItem.bikesIds &&
          Array.isArray(rentalPointItem.bikesIds) &&
          rentalPointItem.bikesIds.includes(bike.id)
      );
    },

    setRentalPointsList(state, action) {
      state.rentalPoints = action.payload;
    },

    setBikesList(state, action) {
      state.bikes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBikeModerationThunkV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBikeModerationThunkV2.fulfilled, (state, action) => {
        state.isLoading = false;
        const { idBike, updatedData } = action.payload;
        const index = state.bikes.findIndex((bike) => bike.id === idBike);
        if (index !== -1) {
          state.bikes[index] = {
            ...state.bikes[index],
            ...updatedData,
          };
        }

        // Обновляем selectedRentalPointBikes, если bike.id совпадает с idBike
        state.selectedRentalPointBikes = state.selectedRentalPointBikes.map(
          (bike) => (bike.id === idBike ? { ...bike, ...updatedData } : bike)
        );
      })
      .addCase(updateBikeModerationThunkV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateRentalPointsModerationThunkV2.pending, (state) => {
        state.isLoadingRentalPoints = true;
        state.error = null;
      })
      .addCase(
        updateRentalPointsModerationThunkV2.fulfilled,
        (state, action) => {
          state.isLoadingRentalPoints = false;
          const updatedRentalPoint = action.payload;
          // Обновляем rentalPoints
          state.rentalPoints = state.rentalPoints.map((point) =>
            point.id === updatedRentalPoint.id ? updatedRentalPoint : point
          );
          // Обновляем selectedUserRentalPoints
          state.selectedUserRentalPoints = state.selectedUserRentalPoints.map(
            (point) =>
              point.id === updatedRentalPoint.id ? updatedRentalPoint : point
          );
        }
      )
      .addCase(
        updateRentalPointsModerationThunkV2.rejected,
        (state, action) => {
          state.isLoadingRentalPoints = false;
          state.error = action.payload.errorMessage;
        }
      )
      .addCase(saveUserModerationDataThankV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveUserModerationDataThankV2.fulfilled, (state, action) => {
        state.isLoading = false;
        const { docId, fieldsToUpdate } = action.payload;
        state.selectedUser = {
          ...state.selectedUser,
          ...fieldsToUpdate,
        };
        state.users = state.users.map((user) =>
          user.id === docId ? { ...user, ...fieldsToUpdate } : user
        );
      })
      .addCase(saveUserModerationDataThankV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(addRentalPointsModerationThunkV2.pending, (state) => {
        state.isLoadingRentalPoints = true;
        state.error = null;
      })
      .addCase(addRentalPointsModerationThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoints = false;
        const newRentalPoint = action.payload;
        state.rentalPoints.push(newRentalPoint);
        state.selectedUserRentalPoints.push(newRentalPoint);
        if (
          state.selectedUser &&
          state.selectedUser.rentalPointsIds &&
          !state.selectedUser.rentalPointsIds.includes(newRentalPoint.id)
        ) {
          state.selectedUser.rentalPointsIds.push(newRentalPoint.id);
        }
      })
      .addCase(addRentalPointsModerationThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoints = false;
        state.error = action.payload;
      })
      .addCase(addBikeModerationThunkV2.pending, (state) => {
        state.isLoadingBikes = true;
        state.error = null;
      })
      .addCase(addBikeModerationThunkV2.fulfilled, (state, action) => {
        state.isLoadingBikes = false;
        const newBike = action.payload;
        state.bikes.push(newBike);
        state.selectedRentalPointBikes.push(newBike);
      })
      .addCase(addBikeModerationThunkV2.rejected, (state, action) => {
        state.isLoadingBikes = false;
        state.error = action.payload;
      });
  },
});

export const {
  removeContactsPhoneNumberModeration,
  setContactsPhoneNumbersModeration,
  removeContactsEmailAddressModeration,
  setContactsEmailAddressesModeration,
  updateAdditionalServiceModeration,
  setSelectedAdditionalServiceRentalPoint,
  addAdditionalServiceModeration,
  deleteAdditionalServiceModeration,
  setTimetableInModeration,
  setBasicInformationTitleModeration,
  setBasicInformationModeration,
  setBasicInformationDescriptionModeration,
  setLocationModeration,
  setUsersList,
  // setCityIdModeration,
  // setCountryIdModeration,
  setSelectedUserModeration,
  setSelectedRentalPointModeration,
  setRentalPointsList,
  setBikesList,
  clearUserForm,
  clearRentalPointForm,
  clearBikePointForm,
  setMetricsBikeModeration,
  setRateSelectedBikeModeration,
  setSelectedBikeCategoryIdModeration,
  setSelectedBikeBrandIdModeration,
  setSelectedBikeBasicInformation,
  setSelectedBikeModeration,
} = moderationSliceV2.actions;
export default moderationSliceV2.reducer;

import React, { useEffect, useState } from "react";
import Button from "../WidgetsProfile/Buttons/Button";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import { useDispatch, useSelector } from "react-redux";
import { getWeekDayName } from "../RentalPointsV3/functions/getWeekDayName";
import Textarea from "../WidgetsProfile/Textarea/Textarea";
import DoubleDropdownList from "../WidgetsProfile/DropdownList/DoubleDropdownList";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import { setTimetableInModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";

const WindowEditTimetableModerationV4 = ({ mode, indexDay, onClose }) => {
  const dispatch = useDispatch();

  const [timetable, setTimetable] = useState([]);
  const [openingHoursTime, setOpeningHoursTime] = useState("");
  const [openingMinutesTime, setOpeningMinutesTime] = useState("");

  const [closingHoursTime, setClosingHoursTime] = useState("");
  const [closingMinutesTime, setClosingMinutesTime] = useState("");

  const [selectedDay, setSelectedDay] = useState();
  const [timeTableDescription, setTimeTableDescription] = useState();
  const [historyChanges, setHistoryChanges] = useState({});

  const currentUser = useSelector((state) => state.userSliceV2.currentUser);

  const { newRentalPoint, selectedRentalPoint } = useSelector(
    (state) => state.moderationSliceV2
  );

  // const { selectedRentalPoint, newRentalPoint } = useSelector(
  //   (state) => state.rentalPointsSliiceV2
  // );

  useEffect(() => {
    if (mode === "moderatorAdded") {
      setTimetable(newRentalPoint?.timetable || []);
    } else {
      setTimetable(selectedRentalPoint?.timetable || []);
    }
  }, [selectedRentalPoint, newRentalPoint, mode]);

  useEffect(() => {
    const currentTimetable = timetable[indexDay] || {};
    setOpeningHoursTime(currentTimetable.openHours || "");
    setOpeningMinutesTime(currentTimetable.openMinutes || "");

    setClosingHoursTime(currentTimetable.closeHours || "");
    setClosingMinutesTime(currentTimetable.closeMinutes || "");

    setSelectedDay(currentTimetable.isHoliday ? "Day off" : "Working day");
    setTimeTableDescription(
      currentTimetable.description?.descriptionOriginal || ""
    );
    setHistoryChanges(currentTimetable.historyChanges || {});
  }, [timetable, indexDay]);

  const newTimetable = {
    weekday: { weekdayOriginal: getWeekDayName(indexDay) },
    openHours: openingHoursTime.toString(),
    openMinutes: openingMinutesTime.toString(),
    closeHours: closingHoursTime.toString(),
    closeMinutes: closingMinutesTime.toString(),
    description: {
      descriptionOriginal: timeTableDescription,
    },
    isHoliday: selectedDay === "Day off" ? true : false,
    historyChanges: historyChanges,
  };

  const handleApply = () => {
    dispatch(
      setTimetableInModeration({
        userId: currentUser.id,
        index: indexDay,
        newTimetable,
        mode,
      })
    );

    onClose();
  };

  const hours = Array.from({ length: 25 }, (_, index) => index);
  const minutes = Array.from({ length: 61 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const hoursOptions = {
    label: "Hours",
    value: hours,
  };

  const minutesOptions = {
    label: "Minutes",
    value: minutes,
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <ContentBox position="start">
        <div className="profile-widget__content-box">
          <SegmentedControls
            options={["Working day", "Day off"]}
            onSelect={selectedDay}
            selected={setSelectedDay}
            label="SegmentedControls"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
          />

          {selectedDay === "Working day" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                <DoubleDropdownList
                  optionsFirst={hoursOptions}
                  optionsSecond={minutesOptions}
                  onSelectFirst={setOpeningHoursTime}
                  onSelectSecond={setOpeningMinutesTime}
                  label={
                    openingHoursTime && openingMinutesTime
                      ? `${openingHoursTime}:${openingMinutesTime}`
                      : "Open time"
                  }
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  selectedOptionFirst={openingHoursTime}
                  selectedOptionSecond={openingMinutesTime}
                />

                <DoubleDropdownList
                  optionsFirst={hoursOptions}
                  optionsSecond={minutesOptions}
                  onSelectFirst={setClosingHoursTime}
                  onSelectSecond={setClosingMinutesTime}
                  label={
                    closingHoursTime && closingMinutesTime
                      ? `${closingHoursTime}:${closingMinutesTime}`
                      : "Close time"
                  }
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  selectedOptionFirst={closingHoursTime}
                  selectedOptionSecond={closingMinutesTime}
                />
              </div>
              <Textarea
                label="Description"
                maxLength={500}
                onChange={(e) => setTimeTableDescription(e.target.value)}
                value={timeTableDescription}
                description={`Please describe the details of your rental point's operation on this day, if any.`}
              />
            </>
          )}
        </div>
      </ContentBox>

      <ButtonsBar>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
          />
          <Button label="Apply" active={true} onClick={handleApply} />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowEditTimetableModerationV4;

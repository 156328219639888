import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import {
  setErrorState,
  setLoadingState,
  updateDocuments,
} from "../../../../catalogSliceV4";

// Для хранения подписок
const unsubscribeMap = new Map();

/**
 * Polling для получения изменений в коллекции Firestore.
 * @param {Object} params - Параметры Polling.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} params.previewCurrentDocumenState - Состояние текущей коллекции в Redux.
 * @param {Array<string>} params.excludedIds - Массив ID документов, которые нужно исключить из Polling.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 */
export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribePreviewDocumentsThunkV4",
  async (
    {
      collectionPath,
      previewCurrentDocumenState,
      excludedIds = [],
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log("Инициализация подписки для коллекции:", collectionPath);

      // Создайте запрос к Firestore
      const collectionRef = collection(db, ...collectionPath.split("."));

      // Устанавливаем подписку на изменения в коллекции
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          try {
            const filteredDocuments = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .filter((doc) => !excludedIds.includes(doc.id));

            const combinedData = filteredDocuments.reduce((acc, doc) => {
              if (Array.isArray(doc.data)) {
                return acc.concat(doc.data);
              }
              return acc;
            }, []);

            // Обновляем данные в Redux
            dispatch(
              updateDocuments({
                documents: combinedData,
                previewCurrentDocumenState,
              })
            );
            console.log(`Данные обновлены для коллекции ${collectionPath}.`);

            // Обновляем состояние загрузки
            dispatch(
              setLoadingState({
                loadingStateName,
                isLoading: false,
              })
            );
          } catch (error) {
            const errorMessage = interpretErrorV4(error.code);
            console.error(
              "Ошибка при обновлении данных Firestore:",
              errorMessage
            );
            dispatch(
              setErrorState({
                errorStateName,
                errorMessage,
              })
            );
          }
        },
        (error) => {
          const errorMessage = interpretErrorV4(error.code);
          console.error(
            "Ошибка при подписке на изменения Firestore:",
            errorMessage
          );
          dispatch(
            setErrorState({
              errorStateName,
              errorMessage,
            })
          );
        }
      );

      // Сохраняем функцию отписки в карту для дальнейшего использования
      unsubscribeMap.set(collectionPath, unsubscribe);

      // Возвращаем объект для информирования о успешной подписке
      return { subscriptionEstablished: true };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error(
        "Ошибка при инициализации подписки на изменения в Firestore:",
        errorMessage
      );
      return rejectWithValue({ errorMessage });
    }
  }
);

/**
 * Thunk для остановки подписки от коллекции Firestore.
 * @param {string} collectionPath - Путь к коллекции Firestore.
 */
export const unsubscribePreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/unsubscribePreviewDocumentsThunkV4",
  async (collectionPath, { dispatch }) => {
    try {
      // Если подписка существует, отписываемся
      if (unsubscribeMap.has(collectionPath)) {
        const unsubscribe = unsubscribeMap.get(collectionPath);
        unsubscribe(); // Отписываемся
        unsubscribeMap.delete(collectionPath);
        console.log(`Подписка на коллекцию ${collectionPath} отменена`);
      } else {
        console.log(`Нет активной подписки для коллекции ${collectionPath}`);
      }

      return { unsubscribed: true };
    } catch (error) {
      console.error("Ошибка при остановке подписки на Firestore:", error);
      throw error;
    }
  }
);

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateDocuments,
// } from "../../../../catalogSliceV4";

// // Для хранения идентификаторов таймеров Polling и старых данных
// const pollingMap = new Map();
// const oldDataMap = new Map(); // Новая структура для хранения старых данных

// /**
//  * Polling для получения изменений в коллекции Firestore с контролем частоты обновлений.
//  * @param {Object} params - Параметры Polling.
//  * @param {string} params.collectionPath - Путь к коллекции Firestore.
//  * @param {string} params.previewCurrentDocumenState - Состояние текущей коллекции в Redux.
//  * @param {Array<string>} params.excludedIds - Массив ID документов, которые нужно исключить из Polling.
//  * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
//  * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
//  * @param {number} [params.delayTime=60000] - Задержка в миллисекундах между Polling-запросами (по умолчанию 60 секунд).
//  */
// export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribePreviewDocumentsThunkV4",
//   async (
//     {
//       collectionPath,
//       previewCurrentDocumenState,
//       excludedIds = [],
//       loadingStateName,
//       errorStateName,
//       delayTime = 0, // По умолчанию 0 секунд
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     try {
//       console.log("Инициализация Polling для коллекции:", collectionPath);

//       // Если уже существует Polling для этой коллекции, остановите его
//       if (pollingMap.has(collectionPath)) {
//         const existingTimerId = pollingMap.get(collectionPath);
//         clearInterval(existingTimerId);
//         console.log(
//           `Существующий Polling для коллекции ${collectionPath} остановлен`
//         );
//       }

//       const fetchData = async () => {
//         try {
//           // Создайте запрос к Firestore
//           const collectionRef = collection(db, ...collectionPath.split("."));

//           // Выполните запрос
//           const snapshot = await getDocs(collectionRef);

//           const filteredDocuments = snapshot.docs
//             .map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//             }))
//             .filter((doc) => !excludedIds.includes(doc.id));

//           const combinedData = filteredDocuments.reduce((acc, doc) => {
//             if (Array.isArray(doc.data)) {
//               return acc.concat(doc.data);
//             }
//             return acc;
//           }, []);

//           // Получаем старые данные
//           const oldData = oldDataMap.get(collectionPath) || [];

//           // Сравниваем старые и новые данные
//           const dataChanged = !arraysAreEqual(combinedData, oldData);

//           if (dataChanged) {
//             // Если данные изменились, обновляем Redux
//             dispatch(
//               updateDocuments({
//                 documents: combinedData,
//                 previewCurrentDocumenState,
//               })
//             );
//             console.log(`Данные обновлены для коллекции ${collectionPath}.`);

//             // Обновляем старые данные в хранилище
//             oldDataMap.set(collectionPath, combinedData);
//           } else {
//             console.log(
//               `Данные для коллекции ${collectionPath} не изменились.`
//             );
//           }

//           // Обновляем состояние загрузки
//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );
//         } catch (error) {
//           const errorMessage = interpretErrorV4(error.code);
//           console.error("Ошибка при Polling Firestore:", errorMessage);
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );
//           // Можно прервать Polling при критических ошибках, если нужно
//         }
//       };

//       // Выполните первый запрос сразу
//       await fetchData();

//       // Установите интервал для последующих запросов
//       const timerId = setInterval(fetchData, delayTime);
//       pollingMap.set(collectionPath, timerId);

//       console.log(
//         `Polling установлен для коллекции ${collectionPath} с интервалом ${delayTime} мс.`
//       );

//       // Возвращаем объект для информирования о успешной подписке
//       return { subscriptionEstablished: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при инициализации Polling Firestore:",
//         errorMessage
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// /**
//  * Функция для сравнения двух массивов (независимо от порядка элементов).
//  * @param {Array} arr1 - Первый массив.
//  * @param {Array} arr2 - Второй массив.
//  * @returns {boolean} - Возвращает true, если массивы одинаковы.
//  */
// function arraysAreEqual(arr1, arr2) {
//   if (arr1.length !== arr2.length) {
//     return false;
//   }

//   // Создаём копию массивов, чтобы не менять их
//   const sortedArr1 = [...arr1].sort();
//   const sortedArr2 = [...arr2].sort();

//   for (let i = 0; i < sortedArr1.length; i++) {
//     if (JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])) {
//       return false;
//     }
//   }

//   return true;
// }

// /**
//  * Thunk для остановки Polling от коллекции Firestore.
//  * @param {string} collectionPath - Путь к коллекции Firestore.
//  */
// export const unsubscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/unsubscribePreviewDocumentsThunkV4",
//   async (collectionPath, { dispatch }) => {
//     try {
//       if (pollingMap.has(collectionPath)) {
//         const timerId = pollingMap.get(collectionPath);
//         clearInterval(timerId);
//         pollingMap.delete(collectionPath);
//         console.log(`Polling для коллекции ${collectionPath} остановлен`);
//       } else {
//         console.log(`Нет активного Polling для коллекции ${collectionPath}`);
//       }

//       return { unsubscribed: true };
//     } catch (error) {
//       console.error("Ошибка при остановке Polling Firestore:", error);
//       throw error;
//     }
//   }
// );

// // path/to/your/thunk.js
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { collection, getDocs, query, where } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateDocuments,
// } from "../../../../catalogSliceV4";

// // Для хранения идентификаторов таймеров Polling
// const pollingMap = new Map();

// /**
//  * Polling для получения изменений в коллекции Firestore с контролем частоты обновлений.
//  * @param {Object} params - Параметры Polling.
//  * @param {string} params.collectionPath - Путь к коллекции Firestore.
//  * @param {string} params.previewCurrentDocumenState - Состояние текущей коллекции в Redux.
//  * @param {Array<string>} params.excludedIds - Массив ID документов, которые нужно исключить из Polling.
//  * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
//  * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
//  * @param {number} [params.delayTime=60000] - Задержка в миллисекундах между Polling-запросами (по умолчанию 60 секунд).
//  */
// export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribePreviewDocumentsThunkV4",
//   async (
//     {
//       collectionPath,
//       previewCurrentDocumenState,
//       excludedIds = [],
//       loadingStateName,
//       errorStateName,
//       delayTime = 60000, // По умолчанию 60 секунд
//     },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     try {
//       console.log("Инициализация Polling для коллекции:", collectionPath);

//       // Если уже существует Polling для этой коллекции, остановите его
//       if (pollingMap.has(collectionPath)) {
//         const existingTimerId = pollingMap.get(collectionPath);
//         clearInterval(existingTimerId);
//         console.log(
//           `Существующий Polling для коллекции ${collectionPath} остановлен`
//         );
//       }

//       const fetchData = async () => {
//         try {
//           // Создайте запрос к Firestore
//           const collectionRef = collection(db, ...collectionPath.split("."));

//           // Если нужно, добавьте условия в запрос
//           // Например, чтобы исключить определённые документы:
//           // const q = query(collectionRef, where("id", "!=", "metadata"));

//           // Выполните запрос
//           const snapshot = await getDocs(collectionRef); // или используйте `q` вместо `collectionRef`, если добавили условия

//           const filteredDocuments = snapshot.docs
//             .map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//             }))
//             .filter((doc) => !excludedIds.includes(doc.id));

//           const combinedData = filteredDocuments.reduce((acc, doc) => {
//             if (Array.isArray(doc.data)) {
//               return acc.concat(doc.data);
//             }
//             return acc;
//           }, []);

//           // Диспачим обновление документов
//           dispatch(
//             updateDocuments({
//               documents: combinedData,
//               previewCurrentDocumenState,
//             })
//           );

//           // Обновляем состояние загрузки
//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );

//           // Увеличиваем счётчик обновлений (если необходимо)
//           // Для этого можно хранить счётчики в Redux или локально

//           console.log(`Данные обновлены для коллекции ${collectionPath}.`);
//         } catch (error) {
//           const errorMessage = interpretErrorV4(error.code);
//           console.error("Ошибка при Polling Firestore:", errorMessage);
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );
//           // Можно прервать Polling при критических ошибках, если нужно
//         }
//       };

//       // Выполните первый запрос сразу
//       await fetchData();

//       // Установите интервал для последующих запросов
//       const timerId = setInterval(fetchData, delayTime);
//       pollingMap.set(collectionPath, timerId);

//       console.log(
//         `Polling установлен для коллекции ${collectionPath} с интервалом ${delayTime} мс.`
//       );

//       // Возвращаем объект для информирования о успешной подписке
//       return { subscriptionEstablished: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error(
//         "Ошибка при инициализации Polling Firestore:",
//         errorMessage
//       );
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// /**
//  * Thunk для остановки Polling от коллекции Firestore.
//  * @param {string} collectionPath - Путь к коллекции Firestore.
//  */
// export const unsubscribePreviewDocumentsThunkV4 = createAsyncThunk(
//   "catalog/unsubscribePreviewDocumentsThunkV4",
//   async (collectionPath, { dispatch }) => {
//     try {
//       if (pollingMap.has(collectionPath)) {
//         const timerId = pollingMap.get(collectionPath);
//         clearInterval(timerId);
//         pollingMap.delete(collectionPath);
//         console.log(`Polling для коллекции ${collectionPath} остановлен`);
//       } else {
//         console.log(`Нет активного Polling для коллекции ${collectionPath}`);
//       }

//       return { unsubscribed: true };
//     } catch (error) {
//       console.error("Ошибка при остановке Polling Firestore:", error);
//       throw error;
//     }
//   }
// );

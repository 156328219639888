import React, { useState } from "react";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import WindowNewOrderDetailV4 from "./WindowNewOrderDetailV4";
import { v4 as uuidv4 } from "uuid";
import { setTempData } from "../../../../../reducersToolkit/catalogSliceV4";
import { useDispatch } from "react-redux";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { dateFormat } from "../../../../../functions/convertDate";
import WindowNewOrderDetailV5 from "./WindowNewOrderDetailV5";
import { Link } from "react-router-dom";

function getPaymentMethodStatus(method) {
  console.log("getPaymentMethodStatus method", method);
  switch (method) {
    case "cryptocurrency":
      return "Crypto Payment";
    case "electronicWallets":
      return "E-Wallet Payment";
    case "bankCard":
      return "Card Payment";
    default:
      return "Unknown Payment Method";
  }
}

function WidgetOrdersListV4({ ordersList }) {
  const dispatch = useDispatch();

  const [windowOrderDetailV5, setWindowOrderDetailV5] = useState(false);

  const handleOpenWindowOrderDetailV4 = (order) => {
    // Обнуляем при открытии заказа данные заказа измененные пользователем до момента сохранения
    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentUpdatedOrder",
        data: null,
      })
    );

    // Сохраняеи данные выбанного preview заказа
    dispatch(
      setTempData({
        tempCurrentDocumentState: "selectedCompletedOrderPreview",
        data: order,
      })
    );

    setWindowOrderDetailV5(true);
  };

  const handleClose = () => {
    setWindowOrderDetailV5(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowOrderDetailV5}
        onClose={handleClose}
        title={`WindowNewOrderDetailV5`}
      >
        <WindowNewOrderDetailV5 onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      {ordersList && ordersList.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          {ordersList?.map((order, index) => (
            // <Link
            //   to="/orderDetail"
            //   style={{
            //     width: "100%",
            //   }}
            // >
            <div
              onClick={() => handleOpenWindowOrderDetailV4(order)}
              key={uuidv4()}
              style={{
                width: "100%",
                backgroundColor: "var(--color-gray-100)",
                padding: "16px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                  height: "100%",
                }}
              >
                <PriorityFile
                  style={{
                    borderRadius: "50%",
                    width: "80px",
                    height: "80px",
                    aspectRatio: "1/1",
                    backgroundColor: "var(--color-gray-200)",
                  }}
                  files={order?.fileUrl}
                />

                <div
                  style={{
                    right: "16px",
                    top: "-8px",
                    backgroundColor: "var(--color-alarm)",
                    height: "16px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 8px",
                    borderRadius: "80px",
                  }}
                >
                  <p
                    className="p-light"
                    style={{
                      color: "var(--color-white)",
                      fontSize: "0.6rem",
                    }}
                  >
                    {order?.orderStatus}
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <h6>{order?.client?.ownerName || "No name"}</h6>
                  <div>
                    <p className="p-light">
                      {order.client?.email || "No email"}
                    </p>
                    <p className="p-light">
                      {order?.client?.phoneNumber || "No phone number"}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total quantity
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                      }}
                    >
                      {order?.totals?.quantity || 0}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Cost
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                      }}
                    >
                      {formatCurrencyUSD(order?.totals?.total || 0)}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Bike Cost
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                      }}
                    >
                      {formatCurrencyUSD(order?.totals?.totalCostBike || 0)}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Additional Services
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                      }}
                    >
                      {formatCurrencyUSD(
                        order?.totals?.totalCostAditionalServices || 0
                      )}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Payment method
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {getPaymentMethodStatus(order?.paymentMethod)}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Created
                    </p>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "var(--color-gray-200)",
                        width: "100%",
                      }}
                    />
                    <p
                      className="p-light"
                      style={{
                        fontWeight: "600",
                        color: "var(--color-black)",
                      }}
                    >
                      {dateFormat(order?.created || 0)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            // </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default WidgetOrdersListV4;

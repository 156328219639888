import { createSlice } from "@reduxjs/toolkit";
import { fetchRentalPointsThunkV2 } from "./thunk/rentalPoints/fetchRentalPointsThunkV2";
import { addRentalPointsThunkV2 } from "./thunk/rentalPoints/addRentalPointsThunkV2";
import { updateRentalPointsThunkV2 } from "./thunk/rentalPoints/updateRentalPointsThunkV2";
import { addBikesThunkV2 } from "./thunk/bikes/addBikesThunkV2";
import { deactivateRentalPointThunkV2 } from "./thunk/rentalPoints/deactivateRentalPointThunkV2";
import { activatedRentalPointThunkV2 } from "./thunk/rentalPoints/activatedRentalPointThunkV2";

const rentalPoint = {
  bikesIds: [],
  ordersIds: [],

  dirrectory: {
    cityId: "",
    countryId: "",
  },

  rentalPointStatus: {
    currentStatus: "moderation",
    historyChanges: [
      {
        userId: "",
        timestamp: 0,
        adjustments: {},
      },
    ],
  },

  created: 0,

  basicInformation: {
    title: {
      titleOriginal: "",
    },

    description: {
      descriptionOriginal: "",
    },
  },

  location: {
    pointAddress: "",
    location: {
      lat: null,
      lng: null,
    },
    city: {},
    country: {},
  },

  files: {
    licenseFile: [],
    photoFiles: [],
    logoFile: [],
  },

  contacts: {
    emailAddresses: [],
    phoneNumbers: [],
  },

  additionalServices: {
    services: [],
  },

  timetable: [
    {
      weekday: {
        weekdayOriginal: "Monday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Tuesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Wednesday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Thursday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Friday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: false,
    },
    {
      weekday: {
        weekdayOriginal: "Saturday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
    {
      weekday: {
        weekdayOriginal: "Sunday",
      },
      openHours: "9",
      openMinutes: "00",
      closeHours: "18",
      closeMinutes: "00",
      description: {
        descriptionOriginal: "",
      },
      isHoliday: true,
    },
  ],
};

const getOrCreateNestedObject = (obj, path) => {
  return path.split(".").reduce((acc, part) => {
    if (!acc[part]) {
      acc[part] = {};
    }
    return acc[part];
  }, obj);
};

const setNestedObject = (obj, path, value) => {
  const parts = path.split(".");
  const last = parts.pop();
  const target = getOrCreateNestedObject(obj, parts.join("."));
  if (!Array.isArray(target[last])) {
    target[last] = [];
  }
  target[last].push(value);
};

const rentalPointsSliiceV4 = createSlice({
  name: "reducersToolkit/rentalPointsSliiceV4",
  initialState: {
    error: null,
    isLoadingRentalPoint: false,

    rentalPointsList: [],
    newRentalPoint: { ...rentalPoint },
    selectedRentalPoint: {},
  },
  reducers: {
    clearFormV4(state, action) {
      state.newRentalPoint = rentalPoint;
    },

    setFiles(state, action) {
      const {
        mode,
        photoFiles = [],
        licenseFile = [],
        logoFile = [],
      } = action.payload;

      if (mode === "new") {
        state.newRentalPoint.files.photoFiles = photoFiles;
        state.newRentalPoint.files.licenseFile = licenseFile;
        state.newRentalPoint.files.logoFile = logoFile;
      } else if (mode === "edit") {
      }
    },

    setModerationRentalPointV4(state, action) {
      const { fildPatch, fildName, data } = action.payload;
      setNestedObject(state, `${fildPatch}.${fildName}`, data);
    },

    setSelectedRentalPointV4(state, action) {
      state.selectedRentalPoint = action.payload;
    },

    setNewBasicInformationRentalPointV4(state, action) {
      const { title, description } = action.payload;

      state.newRentalPoint.basicInformation.title.titleOriginal = title;

      state.newRentalPoint.basicInformation.description.descriptionOriginal =
        description;
    },

    setEditBasicInformationRentalPointV4(state, action) {
      const { title, description } = action.payload;

      state.selectedRentalPoint.basicInformation.title.titleOriginal = title;

      state.selectedRentalPoint.basicInformation.description.descriptionOriginal =
        description;
    },

    setLocationAddressV4(state, action) {
      const { mode, pointAddress, country, city } = action.payload;
      if (mode === "new") {
        state.newRentalPoint.location.pointAddress = pointAddress;
        state.newRentalPoint.location.country = country;
        state.newRentalPoint.location.city = city;
      } else if (mode === "edit") {
        state.selectedRentalPoint.location.pointAddress = pointAddress;
      }
    },

    setContactsEmailAddressesV4(state, action) {
      const { mode, emailAddresse } = action.payload;
      if (mode === "new") {
        state.newRentalPoint.contacts.emailAddresses.push(emailAddresse);
      } else if (mode === "edit") {
        state.selectedRentalPoint.contacts.emailAddresses.push(emailAddresse);
      }
    },

    removeContactsEmailAddressV4(state, action) {
      // const { key } = action.payload;
      state.selectedRentalPoint.contacts.emailAddresses =
        state.selectedRentalPoint.contacts.emailAddresses.filter(
          (emailAddress) => emailAddress.key !== action.payload
        );
    },

    setContactsPhoneNumbersV4(state, action) {
      const { mode, phoneNumber } = action.payload;
      if (mode === "new") {
        state.newRentalPoint.contacts.phoneNumbers.push(phoneNumber);
      } else if (mode === "edit") {
        state.selectedRentalPoint.contacts.phoneNumbers.push(phoneNumber);
      }
    },

    removeContactsPhoneNumberV4(state, action) {
      const { mode, index } = action.payload;
      if (mode === "new") {
        state.newRentalPoint.contacts.phoneNumbers.splice(index, 1);
      } else if (mode === "edit") {
        state.selectedRentalPoint.contacts.phoneNumbers.splice(index, 1);
      }
    },

    setTimetableInNewRentalPointV4(state, action) {
      const { index, newTimetable } = action.payload;
      state.newRentalPoint.timetable[index] = newTimetable;
    },

    setTimetableInCurrentRentalPointV4(state, action) {
      const { index, newTimetable } = action.payload;
      state.selectedRentalPoint.timetable[index] = newTimetable;
    },

    addAdditionalServiceV4(state, action) {
      const { mode, aditionalServise } = action.payload;

      if (mode === "new") {
        if (!state.newRentalPoint.additionalServices.services) {
          state.newRentalPoint.additionalServices.services = [];
        }
        state.newRentalPoint.additionalServices.services.push(aditionalServise);
      } else if (mode === "edit") {
        if (!state.selectedRentalPoint.additionalServices.services) {
          state.selectedRentalPoint.additionalServices.services = [];
        }
        state.selectedRentalPoint.additionalServices.services.push(
          aditionalServise
        );
      }
    },

    deleteAdditionalServiceV4(state, action) {
      const { mode, id } = action.payload;
      if (mode === "new") {
        state.newRentalPoint.additionalServices.services =
          state.newRentalPoint.additionalServices.services.filter(
            (service) => service.id !== id
          );
      } else if (mode === "edit") {
        state.selectedRentalPoint.additionalServices.services =
          state.selectedRentalPoint.additionalServices.services.filter(
            (service) => service.id !== id
          );
      }
    },

    updateAdditionalServiceV4(state, action) {
      const { id, additionalService } = action.payload;
      const index =
        state.selectedRentalPoint.additionalServices.services.findIndex(
          (service) => service.id === id
        );
      if (index !== -1) {
        state.selectedRentalPoint.additionalServices.services[index] =
          additionalService;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRentalPointsThunkV2.pending, (state) => {
        state.isLoadingRentalPoint = true;
        state.error = null;
      })
      .addCase(fetchRentalPointsThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.rentalPointsList = action.payload.rentalPoints;
      })
      .addCase(fetchRentalPointsThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(addRentalPointsThunkV2.pending, (state) => {
        state.isLoadingRentalPoint = true;
        state.error = null;
      })
      .addCase(addRentalPointsThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.rentalPointsList.push(action.payload);
      })
      .addCase(addRentalPointsThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(updateRentalPointsThunkV2.pending, (state) => {
        state.isLoadingRentalPoint = true;
        state.error = null;
      })
      .addCase(updateRentalPointsThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoint = false;
        const updatedRentalPoint = action.payload;
        const index = state.rentalPointsList.findIndex(
          (rentalPoint) => rentalPoint.id === updatedRentalPoint.id
        );
        if (index !== -1) {
          state.rentalPointsList[index] = updatedRentalPoint;
        }
      })
      .addCase(updateRentalPointsThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.error = action.payload.errorMessage;
      })
      .addCase(addBikesThunkV2.fulfilled, (state, action) => {
        const { documentId, newBike } = action.payload;
        if (state.selectedRentalPoint.id === documentId) {
          state.selectedRentalPoint.bikes.push(newBike);
        }
      })

      .addCase(deactivateRentalPointThunkV2.pending, (state) => {
        state.isLoadingRentalPoint = true;
        state.error = null;
      })
      .addCase(deactivateRentalPointThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoint = false;
        const { rentalPointId, rentalPointStatus } = action.payload;
        const index = state.rentalPointsList.findIndex(
          (rentalPoint) => rentalPoint.id === rentalPointId
        );
        if (index !== -1) {
          state.rentalPointsList[index].rentalPointStatus = rentalPointStatus;
        }
      })
      .addCase(deactivateRentalPointThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.error = action.payload.errorMessage;
      })

      .addCase(activatedRentalPointThunkV2.pending, (state) => {
        state.isLoadingRentalPoint = true;
        state.error = null;
      })
      .addCase(activatedRentalPointThunkV2.fulfilled, (state, action) => {
        state.isLoadingRentalPoint = false;
        const { rentalPointId, rentalPointStatus } = action.payload;
        const index = state.rentalPointsList.findIndex(
          (rentalPoint) => rentalPoint.id === rentalPointId
        );
        if (index !== -1) {
          state.rentalPointsList[index].rentalPointStatus = rentalPointStatus;
        }
      })
      .addCase(activatedRentalPointThunkV2.rejected, (state, action) => {
        state.isLoadingRentalPoint = false;
        state.error = action.payload.errorMessage;
      });
  },
});

export const {
  clearFormV4,
  setFiles,
  setModerationRentalPointV4,
  setSelectedRentalPointV4,
  // --- BasicInformation ---

  setNewBasicInformationRentalPointV4,
  setEditBasicInformationRentalPointV4,
  // setBasicInformationTitle,
  // setBasicInformationDescription,

  // --- BasicInformation end ---

  setLocationAddressV4,
  setContactsEmailAddressesV4,
  removeContactsEmailAddressV4,
  removeContactsPhoneNumberV4,
  setContactsPhoneNumbersV4,
  setTimetableInNewRentalPointV4,
  setTimetableInCurrentRentalPointV4,

  addAdditionalServiceV4,
  deleteAdditionalServiceV4,
  updateAdditionalServiceV4,
} = rentalPointsSliiceV4.actions;
export const { actions, reducer } = rentalPointsSliiceV4;
export default rentalPointsSliiceV4.reducer;

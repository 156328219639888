import React, { useEffect, useState } from "react";
import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
import WidgetUsersListModerationV4 from "./WidgetUsersListModerationV4";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import QuantityParams from "../WidgetsProfile/QuantityParams/QuantityParams";

const WidgetModeration = ({ usersModeration, isLoading }) => {
  const [usersModerationList, setUsersModerationList] = useState([]);
  const [userState, setUserState] = useState("moderation");
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    if (usersModeration && Array.isArray(usersModeration)) {
      // Подсчет общего количества rentalPointsModeration и bikesModeration для каждого статуса
      // const totalsByStatus = usersModeration.reduce(
      //   (acc, user) => {
      //     const { status, totals } = user;
      //     if (status && totals) {
      //       // Суммируем rentalPointsModeration
      //       if (totals.rentalPointsModeration != null) {
      //         acc[status] += totals.rentalPointsModeration;
      //       }
      //       // Суммируем bikesModeration
      //       if (totals.bikesModeration != null) {
      //         acc[status] += totals.bikesModeration;
      //       }
      //     }
      //     return acc;
      //   },
      //   { moderation: 0, approved: 0, banned: 0 }
      // );

      // Обновление options с компонентами
      // setOptions([
      //   {
      //     title: "moderation",
      //     additions: totalsByStatus.moderation > 0 && (
      //       <QuantityParams param={totalsByStatus.moderation} />
      //     ),
      //   },
      //   {
      //     title: "approved",
      //     additions: totalsByStatus.approved > 0 && (
      //       <QuantityParams param={totalsByStatus.approved} />
      //     ),
      //   },
      //   {
      //     title: "banned",
      //     additions: totalsByStatus.banned > 0 && (
      //       <QuantityParams param={totalsByStatus.banned} />
      //     ),
      //   },
      // ]);

      // Фильтрация списка пользователей по статусу
      const filteredUsers = usersModeration
        .filter((user) => user.status === userState)
        .sort((b, a) => new Date(a.lastUpdated) - new Date(b.lastUpdated));

      setUsersModerationList(filteredUsers);
    }
  }, [userState, usersModeration]);

  return (
    <>
      {usersModeration?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <SegmentedControls
            options={["moderation", "approved", "banned"]}
            onSelect="moderation"
            selected={setUserState}
          />
          <WidgetUsersListModerationV4
            usersModerationList={usersModerationList}
          />
        </div>
      )}
    </>
  );
};

export default WidgetModeration;

// import React, { useEffect, useState } from "react";
// import SegmentedControls from "../WidgetsProfile/SegmentedControls/SegmentedControls";
// import WidgetUsersListModerationV4 from "./WidgetUsersListModerationV4";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";

// const WidgetModeration = ({ usersModeration, isLoading }) => {

//   console.log("usersModerationList", usersModeration);

//   const [usersModerationList, setUsersModerationList] = useState(null);
//   const [userState, setUserState] = useState("moderation");

//   useEffect(() => {
//     if (usersModeration && Array.isArray(usersModeration)) {
//       // Фильтрация списка пользователей по статусу
//       const filteredUsers = usersModeration
//         .filter((user) => user.status === userState)
//         .sort((b, a) => new Date(a.lastUpdated) - new Date(b.lastUpdated)); // Сортировка от новых к старым

//       setUsersModerationList(filteredUsers);
//     }
//   }, [userState, usersModeration]);

//   return (
//     <ContentBox isLoading={isLoading}>
//       {usersModeration?.length > 0 && (
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             gap: "8px",
//           }}
//         >
//           <SegmentedControls
//             options={["moderation", "approved", "banned"]}
//             onSelect="moderation"
//             label="Partners in project"
//             selected={setUserState}
//           />
//           <WidgetUsersListModerationV4
//             usersModerationList={usersModerationList}
//           />
//         </div>
//       )}
//     </ContentBox>
//   );
// };

// export default WidgetModeration;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTempData } from "../../../../reducersToolkit/catalogSliceV4";
import { fetchPreviewDataV4 } from "../../../../reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import PopupDirrectory from "./WidgetsProfile/Popups/PopupDirrectory";
import WindowRentalPointOrderV4 from "./OrdersV3/WindowRentalPointOrderV4";
import ContentBox from "./WidgetsProfile/ContentBox/ContentBox";
import WidgetCurrentCartV4 from "./OrdersV3/WidgetCurrentCartV4";
import { v4 as uuidv4 } from "uuid";
// const filterBikes = (bikes, filters) => {
//   return bikes.filter((bike) => {
//     const { brand, category, city, country, dateInterval, quantityBikes } =
//       filters;

//     const matchesBrand = !brand || bike.brand?.id === brand.idRootDocument;
//     const matchesCategory =
//       !category || bike.category?.id === category.idRootDocument;
//     const matchesCity = !city || bike.city?.id === city.idRootDocument;
//     const matchesCountry =
//       !country || bike.country?.id === country.idRootDocument;

//     // Проверка на совпадение интервала дат и цены
//     const matchesDateInterval =
//       !dateInterval ||
//       (bike.rate &&
//         bike.rate.some((rate) => {
//           // Начало месяца
//           const rateMonthStart = new Date(dateInterval[0]).setMonth(
//             rate.month,
//             1
//           );

//           // Конец месяца
//           const rateMonthEnd = new Date(rateMonthStart).setMonth(
//             rate.month + 1,
//             0
//           );

//           // Проверяем, пересекаются ли интервал даты и месяц
//           return (
//             rate.price > 0 && // Цена должна быть больше 0
//             ((dateInterval[0] >= rateMonthStart &&
//               dateInterval[0] <= rateMonthEnd) || // Начало диапазона внутри месяца
//               (dateInterval[1] >= rateMonthStart &&
//                 dateInterval[1] <= rateMonthEnd) || // Конец диапазона внутри месяца
//               (rateMonthStart >= dateInterval[0] &&
//                 rateMonthEnd <= dateInterval[1])) // Месяц полностью внутри диапазона
//           );
//         }));

//     const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;

//     return (
//       matchesBrand &&
//       matchesCategory &&
//       matchesCity &&
//       matchesCountry &&
//       matchesDateInterval &&
//       matchesQuantity
//     );
//   });
// };

const UIBasketV3 = () => {
  const dispatch = useDispatch();

  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  const [windowOrderForUserV4, setWindowOrderForUserV4] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */

  useEffect(() => {
    setCurrentCart(tempData?.currentCart || null);
  }, [tempData?.currentCart]);

  /**
   *
   * Логика компонента
   *
   */

  // Удаление элемента из корзины
  const onRemoveItem = (item) => {
    const updatedOrders = currentCart.filter((order) => order.id !== item.id);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedOrders,
      })
    );
  };

  // Изменение колличества в заказе
  const onUpdateQuantity = (item, newQuantity) => {
    const updatedOrders = currentCart.map((order) => {
      if (order.id === item.id) {
        const replacementBikes = Array.from(
          { length: newQuantity },
          (_, index) => ({
            [uuidv4()]: [],
          })
        );

        // Убедимся, что quantity всегда больше 0 и является числом
        const currentQuantity = order.totals.quantity || 1; // Подставляем 1, если undefined или 0
        const totalCostBike = order.totals.totalCostBike || 0; // Защита от undefined
        const totalCostAditioalServices = order.totalCostAditioalServices || 0; // Защита от undefined

        // Обновление totalCostBike для новой quantity
        const updatedTotalCostBike =
          (totalCostBike / currentQuantity) * newQuantity;

        // Обновление total
        const updatedTotal = updatedTotalCostBike + totalCostAditioalServices;

        return {
          ...order,
          totals: {
            ...order.totals,
            quantity: newQuantity,
            total: updatedTotal,
          },
          totalCostBike: updatedTotalCostBike,
          replacementBikes: replacementBikes, // Обновляем replacementBikes
        };
      }

      return order;
    });

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentCart",
        data: updatedOrders,
      })
    );
  };

  const handleClose = () => {
    setWindowOrderForUserV4(false);
  };

  const handleOpenWindowOrderForUserV4 = ({ itemGroupedOrder }) => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentRentalPointOrders",
        data: itemGroupedOrder,
      })
    );
    setWindowOrderForUserV4(true);
  };

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title={`WindowRentalPointOrderV4`}
      >
        <WindowRentalPointOrderV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            paddingTop: "80px",
            maxWidth: "var(--width-mobile-content)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
              width: "100%",
              marginBottom: "72px",
            }}
          >
            <ContentBox>
              <WidgetCurrentCartV4
                onRemoveItem={onRemoveItem}
                onUpdateQuantity={onUpdateQuantity}
                currentCart={currentCart}
                onClick={handleOpenWindowOrderForUserV4}
              />
            </ContentBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default UIBasketV3;

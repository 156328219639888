import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./services/route/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import WidgetPublicMenu from "./components/Public/Menu/WidgetPublicMenu";
import { LoadScript } from "@react-google-maps/api";
import { setLoadingStateGoogleMapApi } from "./slice/configSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Добавьте onAuthStateChanged
import { fetchRootDataThunkV4 } from "./reducersToolkit/thunk/catalog/V4/CRUD/fetchRootDataThunkV4";

import { fetchPreviewDataV4 } from "./reducersToolkit/thunk/catalog/V4/CRUD/fetchPreviewDataV4";
import {
  subscribePreviewDocumentsThunkV4,
  unsubscribePreviewDocumentsThunkV4,
} from "./reducersToolkit/thunk/catalog/V4/CRUD/subscribePreviewDocumentsThunkV4";
import {
  interrogationPreviewDocumentsThunkV4,
  unsubscribeInterrogationPreviewDocumentsThunkV4,
} from "./reducersToolkit/thunk/catalog/V4/CRUD/interrogationPreviewDocumentsThunkV4";

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["places"];

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();

  const { loading, error, tempRootData, tempPreviewData, tempData } =
    useSelector((state) => state.catalogSliceV4);

  const { mainMenu } = useSelector((state) => state.navigateSliceV2);

  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);
  const [userRole, setUserRole] = useState("user");
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [basketNotifications, setBasketNotifications] = useState(0);
  const [orderNotifications, setOrderNotifications] = useState(0);

  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
    setUserIdRootDocument(
      tempRootData?.user_auth_rootCurrentData?.idRootDocument || null
    );
  }, [tempRootData?.user_auth_rootCurrentData]);

  useEffect(() => {
    setOrderNotifications(
      tempPreviewData?.manager_ordersPreviewNotifications?.length || 0
    );
  }, [tempPreviewData?.manager_ordersPreviewNotifications]);

  /** Fetch current user RootData */
  useEffect(() => {
    // Слушатель изменения состояния аутентификации
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        console.log("Слушатель изменения состояния аутентификации: ", user);
        // Пользователь аутентифицирован, обновляем данные пользователя
        dispatch(
          fetchRootDataThunkV4({
            idRootDocument: user.uid,
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "users",
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "user_auth_rootCurrentData",
            // Название state который будет помогать отслежывать результаты работы thunk
            loadingStateName: "loading_fetchRootUser_App",
            errorStateName: "addErrorRootuser",
          })
        )
          .unwrap()
          .then(({ rootDocumentData }) => {
            console.log("Текущий пользователь:", rootDocumentData);
          })
          .catch((error) => {
            console.error("Ошибка:", error);
            setUser_rootCurrentData(null);
          });
      }
    });

    // Очистка слушателя при размонтировании компонента
    return () => unsubscribe();
  }, [auth, dispatch]);

  /** Counter data on moderation */
  useEffect(() => {
    const filteredRentalPoints =
      tempPreviewData?.general_rentalPointsPreviewNotifications?.filter(
        (itemRentalPoint) => itemRentalPoint.rentalPointStatus === "moderation"
      );

    const filteredBikes =
      tempPreviewData?.general_bikesPreviewNotifications?.filter(
        (bike) => bike.bikeStatus === "moderation"
      );

    const filteredUsers =
      tempPreviewData?.general_usersPreviewNotifications?.filter(
        (user) => user.status === "moderation"
      );

    const filteredUsersOrders =
      tempPreviewData.manager_ordersPreviewNotifications?.filter(
        (order) =>
          order.orderStatus === "new" ||
          order.orderStatus === "replacement" ||
          order.orderStatus === "canceled"
      );

    const totalRentalPointsNotifications = filteredRentalPoints?.length || 0;

    const totalBikesNotifications = filteredBikes?.length || 0;

    const totalUsersNotifications = filteredUsers?.length || 0;

    const totalUsersOrderNotifications = filteredUsersOrders?.length || 0;

    const totals =
      totalRentalPointsNotifications +
      totalBikesNotifications +
      totalUsersNotifications +
      totalUsersOrderNotifications;

    setTotalNotifications(totals);
  }, [
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.general_bikesPreviewNotifications,
    tempPreviewData?.general_usersPreviewNotifications,
    tempPreviewData.manager_ordersPreviewNotifications,
  ]);

  useEffect(() => {
    setBasketNotifications(tempData?.currentCart?.length || 0);
  }, [tempData?.currentCart]);

  /** USERS */
  // useEffect(() => {
  //   if (userRole === "admin") {
  //     let unsubscribe; // Объявляем переменную для хранения функции отписки

  //     // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
  //     const subscribe = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribePreviewDocumentsThunkV4({
  //             collectionPath: `usersPreview`,
  //             previewCurrentDocumenState: "general_usersPreviewNotifications",
  //             excludedIds: ["metadata"],
  //             loadingStateName: "loading_general_usersPreview",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();

  //         // Проверяем, что функция отписки определена
  //         unsubscribe = result?.unsubscribe;
  //         if (typeof unsubscribe === "function") {
  //           console.log("Функция отписки установлена успешно");
  //         } else {
  //           console.error("Не удалось получить функцию отписки");
  //         }
  //       } catch (error) {
  //         console.error("Ошибка при подписке:", error);
  //       }
  //     };

  //     subscribe(); // Вызываем функцию подписки

  //     // Отписываемся при размонтировании компонента
  //     return () => {
  //       if (typeof unsubscribe === "function") {
  //         unsubscribe();
  //         console.log("Отписка выполнена успешно");
  //       }
  //     };
  //   }
  // }, [dispatch, userRole]);
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "usersPreview";
      const previewCurrentDocumenState = "general_usersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_usersPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** ORDERS CURRENT USER*/
  // useEffect(() => {
  //   if (userIdRootDocument) {
  //     let unsubscribe; // Объявляем переменную для хранения функции отписки

  //     // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
  //     const subscribe = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribePreviewDocumentsThunkV4({
  //             collectionPath: `manager_ordersPreview.userIds.${userIdRootDocument}`,
  //             previewCurrentDocumenState: "manager_ordersPreviewNotifications",
  //             excludedIds: ["metadata"],
  //             loadingStateName: "loading_manager_ordersPreview",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();

  //         // Проверяем, что функция отписки определена
  //         unsubscribe = result?.unsubscribe;
  //         if (typeof unsubscribe === "function") {
  //           console.log("Функция отписки установлена успешно");
  //         } else {
  //           console.error("Не удалось получить функцию отписки");
  //         }
  //       } catch (error) {
  //         console.error("Ошибка при подписке:", error);
  //       }
  //     };

  //     subscribe(); // Вызываем функцию подписки

  //     // Отписываемся при размонтировании компонента
  //     return () => {
  //       if (typeof unsubscribe === "function") {
  //         unsubscribe();
  //         console.log("Отписка выполнена успешно");
  //       }
  //     };
  //   }
  // }, [dispatch, userIdRootDocument]);
  useEffect(() => {
    if (userIdRootDocument) {
      const collectionPath = `manager_ordersPreview.userIds.${userIdRootDocument}`;
      const previewCurrentDocumenState = "manager_ordersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_manager_ordersPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userIdRootDocument]);

  /** RENTAL POINTS */
  // useEffect(() => {
  //   if (userRole === "admin") {
  //     let unsubscribe; // Объявляем переменную для хранения функции отписки

  //     // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
  //     const subscribe = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribePreviewDocumentsThunkV4({
  //             collectionPath: `general_rentalPointsPreview`,
  //             previewCurrentDocumenState:
  //               "general_rentalPointsPreviewNotifications",
  //             excludedIds: ["metadata"],
  //             loadingStateName: "loading_general_rentalPointsPreview",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();

  //         // Проверяем, что функция отписки определена
  //         unsubscribe = result?.unsubscribe;
  //         if (typeof unsubscribe === "function") {
  //           console.log("Функция отписки установлена успешно");
  //         } else {
  //           console.error("Не удалось получить функцию отписки");
  //         }
  //       } catch (error) {
  //         console.error("Ошибка при подписке:", error);
  //       }
  //     };

  //     subscribe(); // Вызываем функцию подписки

  //     // Отписываемся при размонтировании компонента
  //     return () => {
  //       if (typeof unsubscribe === "function") {
  //         unsubscribe();
  //         console.log("Отписка выполнена успешно");
  //       }
  //     };
  //   }
  // }, [dispatch, userRole]);
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_rentalPointsPreview";
      const previewCurrentDocumenState =
        "general_rentalPointsPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_rentalPointsPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** BIKES */
  // useEffect(() => {
  //   if (userRole === "admin") {
  //     let unsubscribe; // Объявляем переменную для хранения функции отписки

  //     // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
  //     const subscribe = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribePreviewDocumentsThunkV4({
  //             collectionPath: `general_bikesPreview`,
  //             previewCurrentDocumenState: "general_bikesPreviewNotifications",
  //             excludedIds: ["metadata"],
  //             loadingStateName: "loading_general_bikesPreview",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();

  //         // Проверяем, что функция отписки определена
  //         unsubscribe = result?.unsubscribe;
  //         if (typeof unsubscribe === "function") {
  //           console.log("Функция отписки установлена успешно");
  //         } else {
  //           console.error("Не удалось получить функцию отписки");
  //         }
  //       } catch (error) {
  //         console.error("Ошибка при подписке:", error);
  //       }
  //     };

  //     subscribe(); // Вызываем функцию подписки

  //     // Отписываемся при размонтировании компонента
  //     return () => {
  //       if (typeof unsubscribe === "function") {
  //         unsubscribe();
  //         console.log("Отписка выполнена успешно");
  //       }
  //     };
  //   }
  // }, [dispatch, userRole]);
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_bikesPreview";
      const previewCurrentDocumenState = "general_bikesPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_bikesPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** ORDERS */
  // useEffect(() => {
  //   if (userRole === "admin") {
  //     let unsubscribe; // Объявляем переменную для хранения функции отписки

  //     // !!! ДЛЯ КОРРЕКТНОЙ РАБОТЫ ОТПИСКИ !!! Добавляем async/await для корректного ожидания результата dispatch
  //     const subscribe = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribePreviewDocumentsThunkV4({
  //             collectionPath: `general_ordersPreview`,
  //             previewCurrentDocumenState: "general_ordersPreviewNotifications",
  //             excludedIds: ["metadata"],
  //             loadingStateName: "loading_general_ordersPreview",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();

  //         // Проверяем, что функция отписки определена
  //         unsubscribe = result?.unsubscribe;
  //         if (typeof unsubscribe === "function") {
  //           console.log("Функция отписки установлена успешно");
  //         } else {
  //           console.error("Не удалось получить функцию отписки");
  //         }
  //       } catch (error) {
  //         console.error("Ошибка при подписке:", error);
  //       }
  //     };

  //     subscribe(); // Вызываем функцию подписки

  //     // Отписываемся при размонтировании компонента
  //     return () => {
  //       if (typeof unsubscribe === "function") {
  //         unsubscribe();
  //         console.log("Отписка выполнена успешно");
  //       }
  //     };
  //   }
  // }, [dispatch, userRole]);
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_ordersPreview";
      const previewCurrentDocumenState = "general_ordersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_ordersPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕчетного колличества вложенностей
        previewCollectionPath: `brandsPreview`,
        //
        loadingStateName: "loading_manager_brandsPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "brandsPreview",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: `categoriesPreview`,
        //
        loadingStateName: "loading_manager_categoriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "categoriesPreview",
      })
    );
  }, [dispatch]);

  return (
    <LoadScript
      googleMapsApiKey={googleAPIKey}
      libraries={googleMapsLibraries}
      onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
    >
      <Router>
        <WidgetPublicMenu
          mainMenu={mainMenu}
          userRole={userRole}
          currentUser={user_auth_rootCurrentData}
          totalNotifications={totalNotifications}
          basketNotifications={basketNotifications}
          ordersNotifications={orderNotifications}
          // isLoading={signInLoading || signUpLoading || logOutLoading}
          // error={error.error_logOut || error.error_signIn || error.error_signUp}
        />

        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoutes currentUser={user_auth_rootCurrentData} />
            }
          />
        </Routes>
      </Router>
    </LoadScript>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import { v4 as uuidv4 } from "uuid";

import Button from "../WidgetsProfile/Buttons/Button";
import { dateFormat } from "../../../../../functions/convertDate";
// item
//                             indexBikeCurrentCart
//                             order_selectedCompletedRootOrder
//                             onClick
//                             onClickDelete
//                             isMobile
//                             currentStateOrder
const WidgetSelectReplacementBikeV4 = React.memo(
  ({
    onClick,
    onClickDelete,
    item,
    indexBikeCurrentCart,
    order_selectedCompletedRootOrder,
    isMobile,
    currentStateOrder,
  }) => {
    const [photoRootBike, setPhotoRootBike] = useState(null);
    const [titleRootbike, setTitleRootbike] = useState(null);
    const [quantityBikes, setQuantityBikes] = useState(null);
    const [titleRentalPoint, setTitleRentalPoint] = useState(null);
    const [replacementBikes, setReplacementBikes] = useState(null);
    const [quantityBike, setQuantityBike] = useState(null);
    const [dateInterval, setDateInterval] = useState(null);
    const [daysInInterval, setDaysInInterval] = useState(null);
    const [total, setTotal] = useState(null);
    const [rentalPointTitle, setRentalPointTitle] = useState(null);
    const [nonEmptyReplacementCount, setNonEmptyReplacementCount] = useState(0);
    const [emptyReplacementCount, setEmptyReplacementCount] = useState(0);
    const [brand, setBrand] = useState(null);
    const [category, setCategory] = useState(null);

    const [counter, setCounter] = useState(0); // общий счётчик

    useEffect(() => {
      if (item && item.bikeData) {
        setPhotoRootBike(item?.bikeData?.files?.photoFiles);
        setTitleRootbike(
          item?.bikeData?.basicInformation?.title?.titleOriginal
        );
        setTitleRentalPoint(
          item?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
            null
        );
        setQuantityBikes(item?.totals.quantity || null);
        setReplacementBikes(item?.replacementBikes || null);
        const countNonEmpty = Object.values(item?.replacementBikes).filter(
          (replacementItem) =>
            Array.isArray(replacementItem) && replacementItem.length > 0
        ).length;
        setNonEmptyReplacementCount(countNonEmpty);
        setQuantityBike(item?.totals?.quantity || null);
        setDateInterval(item?.totals?.dateInterval || null);
        setDaysInInterval(item?.totals?.daysInInterval || null);
        setTotal(item?.totals?.total || null);
        setRentalPointTitle(
          item?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
            null
        );
        const countEmpty = Object.values(item?.replacementBikes).filter(
          (replacementItem) =>
            Array.isArray(replacementItem) && replacementItem.length === 0
        ).length;
        setEmptyReplacementCount(countEmpty);
        setBrand(item?.bikeData?.directory?.brand?.title || null);
        setCategory(item?.bikeData?.directory?.category?.title || null);
      }
    }, [item]);

    const checkReplacementBikes = (replacementBikes) => {
      for (let key in replacementBikes) {
        if (Array.isArray(replacementBikes[key])) {
          if (replacementBikes[key].length === 0) {
            return true;
          }
        }
      }
      return false;
    };

    const checkUserChoice = (replacementItem) => {
      return replacementItem.some((bike) => bike.userChoice === true);
    };

    const selectedReplacementBike = (replacementItem) => {
      const selectedBike = replacementItem.find(
        (bike) => bike.userChoice === true
      );
      return selectedBike || null;
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
          padding: "16px",
        }}
      >
        {/* Заказанный байк */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "8px",
            backgroundColor: "var(--color-black)",
            padding: "16px",
            borderRadius: "8px",
            ...(isMobile ? { aspectRatio: "1/1" } : { aspectRatio: "16/9" }),
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <p
              className="p-light"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                padding: "2px 8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backdropFilter: "blur(10px)",
                color: "var(--color-white)",
              }}
            >
              {brand}
            </p>
            <p
              className="p-light"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                padding: "2px 8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backdropFilter: "blur(10px)",
                color: "var(--color-white)",
              }}
            >
              {category}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <h5
              style={{
                whiteSpace: "nowrap",
                color: "var(--color-white)",
              }}
            >
              {titleRootbike}
            </h5>
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
                opacity: "0.6",
              }}
            >
              {`Вы заказали ${quantityBike} ${titleRootbike} байка. Точка проката ${rentalPointTitle} подтвердила наличие только ${emptyReplacementCount}. ${rentalPointTitle} предлагает вам замену ${nonEmptyReplacementCount} позиций ${titleRootbike} на альтернативные варианты. Пожалуйста ознакомьтесь со списком ниже и выберите подходящий для вас вариант`}
            </p>
          </div>
        </div>

        {Object.entries(replacementBikes || {})
          .sort(([keyA, replacementItemA], [keyB, replacementItemB]) => {
            // Сортировка: сначала элементы с длиной 0, потом с большей длиной
            if (replacementItemA.length === 0 && replacementItemB.length > 0) {
              return -1; // A (с длиной 0) идет раньше B
            }
            if (replacementItemA.length > 0 && replacementItemB.length === 0) {
              return 1; // B (с длиной 0) идет раньше A
            }
            // Если длины одинаковые, сортируем по возрастанию длины
            return replacementItemA.length - replacementItemB.length;
          })

          .map(([key, replacementItem], index) => {
            // Проверка на отсутствие замены
            if (replacementItem.length === 0) {
              return (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  key={uuidv4()}
                >
                  {/* Слоты */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Slot в наличии
                    </p>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-gray-200)",
                        height: "1px",
                      }}
                    />
                    <p className="p-light">{`${index + 1}`}</p>
                  </div>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "24px",
                        aspectRatio: "1/1",
                        borderRadius: "50%",
                        backgroundColor: "var(--color-success)",
                        color: "var(--color-white)",
                        right: "8px",
                        top: "8px",
                      }}
                    >
                      check_small
                    </span>
                    {/* characteristic */}

                    <div
                      style={{
                        position: "absolute",
                        left: "8px",
                        top: "8px",
                      }}
                    >
                      <p
                        style={{
                          gridColumn: "span 2",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          backdropFilter: "blur(10px)",
                          color: "var(--color-white)",
                          padding: "2px 8px",
                          borderRadius: "4px",
                        }}
                        className="p-light"
                      >
                        {titleRootbike}
                      </p>
                    </div>

                    <PriorityFile
                      style={{
                        width: "100%",
                        ...(isMobile
                          ? { aspectRatio: "1/1" }
                          : { aspectRatio: "16/9" }),
                      }}
                      files={photoRootBike}
                    />
                  </div>
                </div>
              );
            }

            // Проверка на наличие замены
            if (replacementItem.length > 0) {
              return (
                <div
                  key={uuidv4()}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  onClick={() =>
                    onClick({
                      mode: "selected",
                      indexBikeCurrentCart,
                      indexReplacementBikes: index,
                      keySlotReplacementBike: key,
                    })
                  }
                >
                  {/* Слоты */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedReplacementBike(replacementItem) ? (
                        <>Slot изменить выбор</>
                      ) : (
                        <>Slot выберите замену</>
                      )}
                    </p>

                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-gray-200)",
                        height: "1px",
                      }}
                    />
                    <p className="p-light">{`${index + 1}`}</p>
                  </div>

                  {/* Список замен */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                    }}
                  >
                    {checkUserChoice(replacementItem) ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {(() => {
                          const selectedBike =
                            selectedReplacementBike(replacementItem);

                          return (
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "absolute",
                                    width: "24px",
                                    aspectRatio: "1/1",
                                    borderRadius: "50%",
                                    backgroundColor: "var(--color-success)",
                                    color: "var(--color-white)",
                                    right: "8px",
                                    top: "8px",
                                  }}
                                >
                                  check_small
                                </span>
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "16px",
                                    left: "16px",
                                    right: "16px",
                                    display: "grid",
                                    flex: "1",
                                    boxSizing: "border-box",
                                    borderRadius: "80px",
                                  }}
                                >
                                  <Button
                                    type="small"
                                    label="Change selection"
                                    active={true}
                                    color="--color-primary"
                                  />
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    gap: "4px",
                                    left: "8px",
                                    top: "8px",
                                  }}
                                >
                                  <p
                                    style={{
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                      backdropFilter: "blur(10px)",
                                      color: "var(--color-white)",
                                      padding: "2px 8px",
                                      borderRadius: "4px",
                                      gridColumn: "span 2",
                                    }}
                                    className="p-light"
                                  >
                                    {
                                      selectedBike.basicInformation.title
                                        .titleOriginal
                                    }
                                  </p>
                                </div>

                                <PriorityFile
                                  style={{
                                    ...(isMobile
                                      ? { aspectRatio: "1/1" }
                                      : { aspectRatio: "16/9" }),
                                    width: "100%",
                                  }}
                                  files={selectedBike?.files?.photoFiles}
                                />
                              </div>
                            </div>
                          );
                        })()}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            display: "grid",
                            gridTemplateColumns: `repeat(${replacementItem.length}, 1fr)`,
                            gap: "2px",
                            ...(isMobile
                              ? { aspectRatio: "1/1" }
                              : { aspectRatio: "16/9" }),
                            width: "100%",
                          }}
                        >
                          {replacementItem.map((bike, idx) => (
                            <div
                              key={uuidv4()}
                              style={{
                                boxSizing: "border-box",
                              }}
                            >
                              <PriorityFile
                                style={{
                                  boxSizing: "border-Box",
                                  width: "100%",
                                  height: "100%",
                                  ...(idx === 0
                                    ? { borderRadius: "8px 0 0 8px" }
                                    : idx + 1 === replacementItem.length
                                    ? {
                                        borderRadius: "0 8px 8px 0",
                                      }
                                    : { borderRadius: "0" }),
                                }}
                                files={bike?.files?.photoFiles}
                              />
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "16px",
                            left: "16px",
                            right: "16px",
                            display: "grid",
                            flex: "1",
                            boxSizing: "border-box",
                            borderRadius: "80px",
                          }}
                        >
                          <Button
                            type="small"
                            label="Select bike"
                            active={true}
                            color="--color-primary"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            }

            return null;
          })}
      </div>
    );
  }
);

export default WidgetSelectReplacementBikeV4;

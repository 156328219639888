import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { truncateText } from "../../../../../functions/truncateText";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import Button from "../WidgetsProfile/Buttons/Button";
import Table from "../WidgetsProfile/Table/Table";
import PopupDirrectory from "../WidgetsProfile/Popups/PopupDirrectory";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/catalogSliceV4";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import WindowCatalogV4 from "./WindowCatalogV4";
import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
import WidgetWoucherListV4 from "./WidgetWoucherListV4";
import dateFormat from "../../../../../functions/dateFormat";
import useIsMobile from "../../../../../functions/isMobile";
import WindowSelectedReplacementBike from "./WindowSelectedReplacementBike";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/subscribeRootDocumentsThunkV4";
import { he } from "date-fns/locale";
import WidgetSelectReplacementBikeV4 from "./WidgetSelectReplacementBikeV4";
import WidgetBikesReplacmentListV4 from "./WidgetBikesReplacmentListV4";
import WidgetBikeItemDataV4 from "./WidgetBikeItemDataV4";

const WindowNewOrderDetailV5 = React.memo(() => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { tempRootData, tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const renderCountRef = useRef(0);

  // Увеличиваем счетчик при каждом рендере
  useEffect(() => {
    renderCountRef.current += 1;
  });
  // ------- Windows stats
  const [windowCatalogV4, setWindowCatalogV4] = useState(false);
  const [windowSelectedReplacementBike, setWindowSelectedReplacementBike] =
    useState(false);

  // ------- tempRootData
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);
  const [rootOrderStatus, setRootOrderStatus] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);
  const [totalOrders, setTotalOrders] = useState(null);
  const [rentalPointRootData, setRentalPointRootData] = useState(null);
  console.log(
    "tempData?.currentUpdatedOrder?.totals",
    tempData?.currentUpdatedOrder?.totals,
    tempRootData?.order_selectedCompletedRootOrder?.totals,
    totalOrders,
    rentalPointRootData
  );
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAvatarUrls, setUserAvatarUrls] = useState(null);

  // ------- tempData
  const [orderIdRootDocument, setOrderIdRootDocument] = useState(null);
  const [currentOrderState, setCurrentOrderState] = useState(null);
  const [idUserCurrentOrder, setIdUserCurrentOrder] = useState(null);

  // ------- Внутренняя логика компоненета
  const [mode, setMode] = useState(null);
  const [replacementBox, setReplacementBox] = useState(false);
  const [waitUserBox, setWaitUserBox] = useState(false);
  const [currentStateOrder, setCurrentStateOrder] = useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */

  // tempData
  // tempRootData
  useEffect(() => {
    setOrderIdRootDocument(
      tempData?.selectedCompletedOrderPreview?.idRootDocument || null
    );

    setIdUserCurrentOrder(
      tempData?.selectedCompletedOrderPreview?.client?.idRootDocument || null
    );

    setCurrentStateOrder(
      tempData?.selectedCompletedOrderPreview?.orderStatus || null
    );
  }, [tempData?.selectedCompletedOrderPreview]);

  useEffect(() => {
    setCurrentOrderState(
      tempRootData?.order_selectedCompletedRootOrder?.status?.currentStatus ||
        null
    );
    setRootOrderStatus(
      tempRootData?.order_selectedCompletedRootOrder?.status?.statusHistory ||
        null
    );
  }, [tempRootData?.order_selectedCompletedRootOrder]);

  useEffect(() => {
    // Проверяем, есть ли изменения которые были внесены в tempData.currentUpdatedOrder данные в WindowViewBikeDetail.js.
    // Если данные в currentUpdatedOrder существуют, то используем их, иначе получаем данные из базы tempRootData?.order_selectedCompletedRootOrder

    if (tempData?.currentUpdatedOrder) {
      setOrder_selectedCompletedRootOrder(
        tempData?.currentUpdatedOrder || null
      );
      setCurrentCart(tempData?.currentUpdatedOrder?.currentCart || null);
      setTotalOrders(tempData?.currentUpdatedOrder?.totals || null);
      setUserIdRootDocument(
        tempData?.currentUpdatedOrder?.client?.idRootDocument || null
      );

      if (
        tempData?.currentUpdatedOrder?.currentCart &&
        tempData?.currentUpdatedOrder?.currentCart.length > 0
      ) {
        setRentalPointRootData(
          tempData?.currentUpdatedOrder?.currentCart[0]?.rentalPointRootData ||
            null
        );
      } else {
        setRentalPointRootData(null); // Или какое-то другое значение по умолчанию
      }
    } else {
      setOrder_selectedCompletedRootOrder(
        tempRootData?.order_selectedCompletedRootOrder || null
      );
      setCurrentCart(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      );
      setTotalOrders(
        tempRootData?.order_selectedCompletedRootOrder?.totals || null
      );
      setUserIdRootDocument(
        tempRootData?.order_selectedCompletedRootOrder?.client
          ?.idRootDocument || null
      );

      if (
        tempRootData?.order_selectedCompletedRootOrder?.currentCart &&
        tempRootData?.order_selectedCompletedRootOrder?.currentCart.length > 0
      ) {
        setRentalPointRootData(
          tempRootData?.order_selectedCompletedRootOrder?.currentCart[0]
            ?.rentalPointRootData || null
        );
      } else {
        setRentalPointRootData(null); // Или какое-то другое значение по умолчанию
      }
    }
  }, [
    tempData?.currentUpdatedOrder,
    tempRootData?.order_selectedCompletedRootOrder,
  ]);

  useEffect(() => {
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
    setUserId(tempRootData?.user_auth_rootCurrentData?.idRootDocument || null);
    setUserAvatarUrls(
      tempRootData?.user_auth_rootCurrentData?.files?.avatarUrls || null
    );
  }, [tempRootData?.user_auth_rootCurrentData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */

  useEffect(() => {
    if (orderIdRootDocument) {
      const collectionPath = "orders";
      const rootCurrentDocumenState = "order_selectedCompletedRootOrder";
      const idRootDocument = orderIdRootDocument;
      const loadingStateName = "loading_selectedCompletedRootOrder";
      const errorStateName = "currentError";

      // Подписка при монтировании компонента
      dispatch(
        subscribeRootDocumentsThunkV4({
          collectionPath,
          rootCurrentDocumenState,
          idRootDocument,
          loadingStateName,
          errorStateName,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        unsubscribeRootDocument(orderIdRootDocument); // Просто вызываем функцию отписки
        console.log("Отписка выполнена");
      };
    }
  }, [dispatch, orderIdRootDocument]);

  /**
   *
   * CRUD функции
   *
   */

  const applyData = ({ stateOrder }) => {
    const previewReplacemtntOrderData = {
      ...tempData.selectedCompletedOrderPreview,
      orderStatus: stateOrder
        ? stateOrder
        : tempData.selectedCompletedOrderPreview.orderStatus,
    };

    const newStatusHistory = {
      status: stateOrder,
      timestamp: Date.now(),
      user: {
        userId: userId,
        userAvatarUrls: userAvatarUrls ? userAvatarUrls : [],
      },
    };

    const rootReplacemtntOrderData = {
      ...order_selectedCompletedRootOrder,
      status: {
        currentStatus: stateOrder,
        statusHistory: [
          ...(order_selectedCompletedRootOrder.status?.statusHistory || []),
          newStatusHistory,
        ],
      },
    };

    setCurrentStateOrder(stateOrder);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "selectedCompletedOrderPreview",
        data: previewReplacemtntOrderData,
      })
    );

    dispatch(
      updateDataThunkV4({
        // Указываем id preview документа который будем менять
        // idPreviewDocument: idPreviewDocumentRenyalPoint,
        // Указываем id root Элемента
        idRootDocument: orderIdRootDocument,
        // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
        // updatedData: rootCountryData,

        rootData: rootReplacemtntOrderData,
        previewData: previewReplacemtntOrderData,

        // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
        // newFiles: {
        //   // или другие массивы файлов, если есть
        //   logoFiles: ,
        //   photoFiles: ,
        // },
        // Имя того массива файлов который будет использоваться для назначения иконки
        // iconFields: "",
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "orders",

        previewCollectionPath: `manager_ordersPreview.userIds.${userIdRootDocument}`,

        previewGeneralCollectionPath: `general_ordersPreview`,

        // // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: `manager_ordersPreview.userIds.${userIdRootDocument}.metadata`,

        metadataGeneralDocumentPath: `general_ordersPreview.metadata`,
        /*************/
        // Сохраняет в state ROOT ОБЪЕКТ как результат выполнения thunk
        rootCurrentDocumentState: "order_selectedCompletedRootOrder",
        // Сохраняет в state PREVIEW МАССИВ объектов как результат выполнения thunk
        // previewCurrentDocumenState: "",
        // previewGeneralDocumenState: "",
        // Эти состояния будут отображать состояния загрузки и ошибки
        /*************/
        loadingStateName: "loading_selectedCompletedRootOrder",
        errorStateName: "currentError",
      })
    );
    // onClose();
  };

  /**
   *
   * Внутренняя логика компонента
   *
   */

  const handleClose = () => {
    setWindowCatalogV4(false);
    setWindowSelectedReplacementBike(false);
  };

  const handleReplacementBike = ({
    mode,
    indexReplacementBike,
    indexBikeCurrentCart,
    keySlotReplacementBike,
  }) => {
    setMode(mode);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentSlotIds",
        data: {
          indexReplacementBike: indexReplacementBike,
          indexBikeCurrentCart: indexBikeCurrentCart,
          keySlotReplacementBike: keySlotReplacementBike,
        },
      })
    );
    setWindowCatalogV4(true);
  };

  const handleSelectedReplacementBike = ({
    mode,
    indexBikeCurrentCart,
    keySlotReplacementBike,
  }) => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "itemSlotSelectedReplacementBike",
        data: {
          indexBikeCurrentCart: indexBikeCurrentCart,
          keySlotReplacementBike: keySlotReplacementBike,
        },
      })
    );

    setMode(mode);

    setWindowSelectedReplacementBike(true);
  };

  const handleDeleteReplacementBike = ({
    indexBikeCurrentCart,
    indexDeletingBike,
    keySlotReplacementBike,
    order_selectedCompletedRootOrder,
  }) => {
    const { currentCart } = order_selectedCompletedRootOrder;

    // Находим объект cartItem по индексу indexBikeCurrentCart
    const cartItem = currentCart[indexBikeCurrentCart];

    // Проверяем, существует ли replacementBikes и нужный ключ
    if (
      cartItem.replacementBikes &&
      cartItem.replacementBikes.hasOwnProperty(keySlotReplacementBike)
    ) {
      const replacementArray =
        cartItem.replacementBikes[keySlotReplacementBike];

      // Создаем новый массив, исключая элемент с индексом indexDeletingBike
      const updatedReplacementArray = [
        ...replacementArray.slice(0, indexDeletingBike), // до индекса
        ...replacementArray.slice(indexDeletingBike + 1), // после индекса
      ];
      // Обновляем объект cartItem с новым массивом replacementBikes
      const updatedCartItem = {
        ...cartItem,
        replacementBikes: {
          ...cartItem.replacementBikes,
          [keySlotReplacementBike]: updatedReplacementArray,
        },
      };

      // Создаем новый currentCart с обновленным cartItem
      const updatedCurrentCart = [
        ...currentCart.slice(0, indexBikeCurrentCart),
        updatedCartItem,
        ...currentCart.slice(indexBikeCurrentCart + 1),
      ];

      // Обновляем order_selectedCompletedRootOrder с новым currentCart
      const updatedOrder = {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      };

      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "order_selectedCompletedRootOrder",
          data: updatedOrder,
        })
      );
    } else {
      console.log("Key with index", keySlotReplacementBike, "not found.");
    }
  };

  const openReplacementBox = () => {
    setReplacementBox(true);
    // setCurrentStateOrder("replacement");
  };

  const closeReplacementBox = () => {
    setReplacementBox(false);
  };

  const openWaitUserBox = ({ stateOrder }) => {
    setWaitUserBox(true);
    applyData({ stateOrder: stateOrder });
  };

  const closeWaitUserBox = () => {
    setWaitUserBox(false);
  };

  const findStatus = (statusToFind) => {
    // Ищем элемент с нужным статусом
    const foundItem = rootOrderStatus?.find(
      (item) => item.status === statusToFind
    );

    // Если элемент найден, возвращаем true и userId
    if (foundItem) {
      return {
        status: true,
        userId: foundItem?.user?.userId,
        timestamp: foundItem?.timestamp,
      };
    }

    // Если элемент не найден, возвращаем false
    return { exists: false, userId: null, timestamp: 0 };
  };

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowCatalogV4}
        onClose={handleClose}
        title={`Replacement bike WindowCatalogV4`}
      >
        <WindowCatalogV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowSelectedReplacementBike}
        onClose={handleClose}
        title={`WindowSelectedReplacementBike`}
      >
        <WindowSelectedReplacementBike mode={mode} onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: currentOrderState !== "confirm" ? "72px" : "0",
        }}
      >
        {/* Диалог для RENTALPOINT  */}

        {userId !== idUserCurrentOrder && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <ContentBox position="start">
              <>
                <UserData
                  type="left"
                  date={findStatus("new").timestamp || 0}
                  isMobile={isMobile}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {/* message */}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-primary)",
                        borderRadius: "16px 0 16px 16px",
                        padding: "16px",
                        boxSizing: "border-box",
                      }}
                    >
                      <p
                        className="p-light"
                        style={{
                          color: "var(--color-white)",
                        }}
                      >
                        Новый заказ от клиента
                      </p>
                    </div>

                    {/* order data */}

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      {currentCart?.map((item, index) => (
                        <div
                          key={uuidv4()}
                          style={{
                            width: "100%",
                          }}
                        >
                          <WidgetBikeItemDataV4
                            item={item}
                            index={index}
                            groupItems={currentCart}
                            isMobile={isMobile}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </UserData>
                {/* Замена заказа */}
                {(replacementBox || findStatus("replacement")?.status) && (
                  <UserData
                    type="right"
                    date={findStatus("replacement")?.timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-alarm)",
                          borderRadius: "0 16px 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          Выберите варианты замены
                        </p>
                      </div>

                      {currentCart?.map((item, index) => (
                        <div
                          key={uuidv4()}
                          style={{
                            width: "100%",
                          }}
                        >
                          <WidgetBikesReplacmentListV4
                            item={item}
                            indexBikeCurrentCart={index}
                            order_selectedCompletedRootOrder={
                              order_selectedCompletedRootOrder
                            }
                            onClick={handleReplacementBike}
                            onClickDelete={handleDeleteReplacementBike}
                            isMobile={isMobile}
                            currentStateOrder={currentStateOrder}
                          />
                        </div>
                      ))}
                    </div>
                  </UserData>
                )}
                {/* Ожидание ответа от клиента */}
                {(waitUserBox || findStatus("replacement")?.status) && (
                  <UserData
                    type="left"
                    date={findStatus("replacement").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          // aspectRatio: "16/9",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flrx-start",
                          justifyContent: "center",
                          gap: "16px",
                          backgroundColor: "var(--color-gray-100)",
                          borderRadius: "16px 0 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p className="p-light">Ожидаем ответа от клиента</p>
                      </div>
                    </div>
                  </UserData>
                )}
                {/* Ваучер для ТОЧКИ ПРОКАТА */}
                {findStatus("confirm")?.status && (
                  <UserData
                    type="right"
                    date={findStatus("confirm").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {/* message */}

                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-success)",
                          borderRadius: "0 16px 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          Клиент подтвердил заказ
                        </p>
                      </div>

                      {/* wuocher */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          borderRadius: "16px",
                        }}
                      >
                        <WidgetWoucherListV4
                          isMobile={isMobile}
                          userType={userRole}
                          currentRentalPointOrders={currentCart}
                          totalOrders={totalOrders}
                        />
                      </div>
                    </div>
                  </UserData>
                )}
                {/* Заказ отменен КЛИЕНТОМ */}
                {findStatus("canceled")?.status && (
                  <UserData
                    type="left"
                    date={findStatus("canceled").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-alarm)",
                          borderRadius: "16px 0 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        {/* <span className="material-symbols-outlined">close</span> */}
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          Клиент отменил заказ
                        </p>
                      </div>
                    </div>
                  </UserData>
                )}
              </>
            </ContentBox>
          </div>
        )}

        {/* Диалог для CLIRNT */}
        {userId === idUserCurrentOrder && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
              boxSizing: "border-box",
            }}
          >
            <ContentBox position="start">
              <>
                <UserData
                  type="right"
                  date={findStatus("new")?.timestamp || 0}
                  isMobile={isMobile}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {/* message */}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-primary)",
                        borderRadius: "0 16px 16px 16px",
                        padding: "16px",
                        boxSizing: "border-box",
                      }}
                    >
                      <p
                        className="p-light"
                        style={{
                          color: "var(--color-white)",
                        }}
                      >
                        Вы заказали
                      </p>
                    </div>

                    {/* order data */}

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      {currentCart?.map((item, index) => (
                        <div
                          key={uuidv4()}
                          style={{
                            width: "100%",
                          }}
                        >
                          <WidgetBikeItemDataV4
                            item={item}
                            index={index}
                            groupItems={currentCart}
                            isMobile={isMobile}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </UserData>

                {/* Ожидапние ответа от ТОЧКИ ПРОКАТА*/}
                {findStatus("new")?.status && (
                  <UserData
                    type="left"
                    date={findStatus("new").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flrx-start",
                          justifyContent: "center",
                          gap: "16px",
                          backgroundColor: "var(--color-gray-100)",
                          borderRadius: "16px 0 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p className="p-light">
                          Ожидаем ответа от точки проката
                        </p>
                      </div>
                    </div>
                  </UserData>
                )}

                {/* Слоты для замены байка ТОЧКОЙ ПРОКАТА */}

                {(findStatus("replacement")?.status ||
                  (findStatus("replacement")?.status &&
                    findStatus("confirm")?.status)) && (
                  <UserData
                    type="left"
                    date={findStatus("replacement").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-gray-100)",
                          borderRadius: "16px 0 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p className="p-light">
                          Точка проката предлагает замену
                        </p>
                      </div>

                      {currentCart?.map((item, index) => (
                        <div
                          key={uuidv4()}
                          style={{
                            width: "100%",
                          }}
                        >
                          <WidgetSelectReplacementBikeV4
                            item={item}
                            indexBikeCurrentCart={index}
                            order_selectedCompletedRootOrder={
                              order_selectedCompletedRootOrder
                            }
                            onClick={handleSelectedReplacementBike}
                            onClickDelete={handleDeleteReplacementBike}
                            isMobile={isMobile}
                            currentStateOrder={currentStateOrder}
                          />
                        </div>
                      ))}
                    </div>
                  </UserData>
                )}

                {/* Ваучер для ТОЧКИ ПРОКАТА*/}
                {findStatus("confirm")?.status && (
                  <UserData
                    type="left"
                    date={findStatus("confirm").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {/* message */}
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-gray-100)",
                          borderRadius: "16px 0 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p className="p-light">Ваучеры для вашего заказа</p>
                      </div>

                      {/* wuocher */}
                      <div
                        style={{
                          width: "100%",
                          // aspectRatio: "16/9",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          backgroundColor: "var(--color-gray-100)",
                          borderRadius: "16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <WidgetWoucherListV4
                          isMobile={isMobile}
                          userType={userRole}
                          currentRentalPointOrders={currentCart}
                          totalOrders={totalOrders}
                          rentalPointRootData={rentalPointRootData}
                        />
                      </div>
                    </div>
                  </UserData>
                )}

                {/* Заказ отменен КЛИЕНТОМ*/}
                {findStatus("canceled")?.status && (
                  <UserData
                    type="right"
                    date={findStatus("canceled").timestamp || 0}
                    isMobile={isMobile}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-alarm)",
                          borderRadius: "0 16px 16px 16px",
                          padding: "16px",
                          boxSizing: "border-box",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            color: "var(--color-white)",
                          }}
                        >
                          Вы отменили заказ
                        </p>
                      </div>
                    </div>
                  </UserData>
                )}
              </>
            </ContentBox>
          </div>
        )}

        {/* Totals */}
        <ContentBox>
          <Table
            columns={1}
            title="Order totals"
            data={[
              {
                title: "Quantity of Bikes in Order:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {totalOrders?.quantity || 0}
                  </p>
                ),
              },
              {
                title: "Total cost additional services:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(
                      totalOrders?.totalCostAditionalServices || 0
                    )}
                  </p>
                ),
              },
              {
                title: "Total cost bike:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.totalCostBike || 0)}
                  </p>
                ),
              },
              {
                title: "Total:",
                parameter: (
                  <p style={{ fontWeight: "600" }}>
                    {formatCurrencyUSD(totalOrders?.total || 0)}
                  </p>
                ),
              },
            ]}
          />
        </ContentBox>
      </div>

      {/* Панель кнопок для RENTALPOINT */}
      {userId !== idUserCurrentOrder &&
        currentOrderState !== "confirm" &&
        currentOrderState !== "canceled" && (
          <ButtonsBar>
            {/* in pregress */}
            {!replacementBox && currentOrderState !== "replacement" && (
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <Button
                  type="small"
                  label="Replacement"
                  active={true}
                  onClick={openReplacementBox}
                  color="--color-alarm"
                />
                <Button
                  type="small"
                  label="Confirm"
                  active={true}
                  onClick={() => applyData({ stateOrder: "confirm" })}
                />
              </div>
            )}

            {/* replacement */}
            {(replacementBox || currentOrderState === "replacement") && (
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <Button
                  type="small"
                  label="Back"
                  active={true}
                  onClick={closeReplacementBox}
                  color="--color-black"
                />
                <Button
                  type="small"
                  label="Send replacements"
                  active={true}
                  onClick={() => openWaitUserBox({ stateOrder: "replacement" })}
                />
              </div>
            )}
          </ButtonsBar>
        )}

      {/* Панель кнопок для CLIENT */}
      {userId === idUserCurrentOrder &&
        currentOrderState !== "confirm" &&
        currentOrderState !== "canceled" && (
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <Button
                type="small"
                label="Cancel order"
                active={true}
                onClick={() => applyData({ stateOrder: "canceled" })}
                color="--color-alarm"
                allert={"Точкно?"}
              />
              <Button
                type="small"
                label="Confirm"
                active={true}
                onClick={() => applyData({ stateOrder: "confirm" })}
                allert={"Завершаем?"}
              />
            </div>
          </ButtonsBar>
        )}
    </>
  );
});

export default WindowNewOrderDetailV5;

// const BikesReplacmentList = React.memo(
//   ({
//     onClick,
//     onClickDelete,
//     item,
//     indexBikeCurrentCart,
//     order_selectedCompletedRootOrder,
//     isMobile,
//     currentStateOrder,
//   }) => {
//     const [photoRootBike, setPhotoRootBike] = useState(null);
//     const [titleRootbike, setTitleRootbike] = useState(null);
//     const [replacementBikes, setReplacementBikes] = useState(null);

//     useEffect(() => {
//       if (item && item.bikeData) {
//         setPhotoRootBike(item?.bikeData?.files?.photoFiles || null);
//         setTitleRootbike(
//           item?.bikeData.basicInformation?.title?.titleOriginal || null
//         );
//         setReplacementBikes(item?.replacementBikes || null);
//       }
//     }, [item]);

//     return (
//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: "2px",
//           boxSizing: "border-box",
//         }}
//       >
//         <div
//           style={{
//             width: "100%",
//             backgroundColor: "var(--color-gray-100)",
//             borderRadius: "16px",
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               padding: "16px 16px 0 16px",
//               boxSizing: "border-box",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "flex-start",
//               justifyContent: "space-between",
//               gap: "4px",
//             }}
//           >
//             <h5>{titleRootbike && titleRootbike}</h5>
//           </div>

//           {Object.entries(replacementBikes || {}).map(
//             ([key, bikeSlot], index) => (
//               <div
//                 key={key} // Используем ключ как уникальный идентификатор
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-100)",
//                   ...(Object.entries(replacementBikes || {}).length === 1
//                     ? { borderRadius: "16px" }
//                     : {
//                         borderRadius:
//                           index === 0
//                             ? "16px 16px 0 0"
//                             : index !==
//                               Object.entries(replacementBikes || {}).length - 1
//                             ? "0"
//                             : "0 0 16px 16px",
//                       }),
//                   boxSizing: "border-box",
//                   position: "relative",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "100%",
//                     padding: "4px 16px",
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     gap: "8px",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <p className="p-light">slot</p>
//                   <span
//                     style={{
//                       width: "100%",
//                       height: "1px",
//                       backgroundColor: "var(--color-gray-200)",
//                     }}
//                   />
//                   <p className="p-light">{index + 1}</p>
//                 </div>

//                 <div
//                   style={{
//                     display: "grid",
//                     padding: "8px",
//                     ...(isMobile
//                       ? {
//                           gridTemplateColumns: "repeat(2, 1fr)",
//                           gridTemplateRows: "repeat(2, 1fr)",
//                         }
//                       : {
//                           gridTemplateColumns: "repeat(4, 1fr)",
//                         }),
//                     gap: "8px",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   {/* Проверка, является ли value массивом */}
//                   {Array.isArray(bikeSlot) &&
//                     bikeSlot.map((replacementItem, i) => (
//                       <div key={i} style={{ position: "relative" }}>
//                         <PriorityFile
//                           style={{
//                             borderRadius: "80px",
//                             width: "100%",
//                             height: "100%",
//                             aspectRatio: "1/1",
//                             gridColumn: `${index + 2}`,
//                           }}
//                           files={replacementItem?.files?.photoFiles}
//                         />

//                         <span
//                           onClick={() =>
//                             onClickDelete({
//                               keySlotReplacementBike: key,
//                               indexBikeCurrentCart: indexBikeCurrentCart,
//                               indexDeleteSlotBike: index,
//                               indexDeletingBike: i,
//                               order_selectedCompletedRootOrder:
//                                 order_selectedCompletedRootOrder,
//                             })
//                           }
//                           style={{
//                             ...(isMobile
//                               ? { padding: "8px" }
//                               : { padding: "4px" }),
//                             position: "absolute",
//                             right: "0",
//                             top: "0",
//                             borderRadius: "50%",
//                             border: "0",
//                             backgroundColor: "var(--color-gray-100)",
//                             aspectRatio: "1/1",
//                             fontSize: "1rem",
//                             cursor: "pointer",
//                             boxSizing: "border-box",
//                           }}
//                           className="material-symbols-outlined"
//                         >
//                           close
//                         </span>
//                       </div>
//                     ))}

//                   {/* Кнопка "+" для того чтобы добавить в массив замену */}
//                   {currentStateOrder !== "replacement" &&
//                     Array.isArray(bikeSlot) &&
//                     bikeSlot.length !== 4 && (
//                       <div
//                         style={{
//                           width: "100%",
//                           height: "100%",
//                           aspectRatio: "1/1",
//                           borderRadius: "50%",
//                           backgroundColor: "var(--color-alarm)",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           boxSizing: "border-box",
//                           cursor: "pointer",
//                           color: "var(--color-white)",
//                         }}
//                         onClick={() =>
//                           onClick({
//                             mode: "replacement",
//                             keySlotReplacementBike: key,
//                             indexReplacementBike: index,
//                             indexBikeCurrentCart: indexBikeCurrentCart,
//                           })
//                         }
//                         className="material-symbols-outlined"
//                       >
//                         add
//                       </div>
//                     )}
//                   {/* Создаём массив с помощью from для создания доступных ячеек  */}
//                   {Array.from(
//                     {
//                       length:
//                         (currentStateOrder !== "replacement" ? 3 : 4) -
//                         bikeSlot.length,
//                     },
//                     (_, i) => (
//                       <div
//                         key={i}
//                         style={{
//                           width: "100%",
//                           height: "100%",
//                           aspectRatio: "1/1",
//                           borderRadius: "50%",
//                           backgroundColor: "var(--color-white)",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           boxSizing: "border-box",
//                         }}
//                       >
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "column",
//                             alignItems: "center",
//                           }}
//                         >
//                           <h5 style={{ color: "var(--color-gray-200)" }}>
//                             {i + bikeSlot.length + 2}
//                           </h5>
//                         </div>
//                       </div>
//                     )
//                   )}
//                 </div>
//               </div>
//             )
//           )}

//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               justifyContent: "space-between",
//               boxSizing: "border-box",
//               gap: "8px",
//               padding: "16px",
//             }}
//           >
//             <p
//               className="p-light"
//               style={{
//                 whiteSpace: "nowrap",
//               }}
//             >
//               Total slots:
//             </p>
//             <span
//               style={{
//                 width: "100%",
//                 height: "1px",
//                 backgroundColor: "var(--color-gray-200)",
//               }}
//             />
//             {/* <p className="p-light">{replacementBikesArray.length}</p> */}
//           </div>
//         </div>
//       </div>
//     );
//   }
// );
// const BikeItemDataV4 = React.memo(
//   ({
//     item,
//     index,
//     groupItems,
//     onUpdateQuantity,
//     onRemoveItem,
//     handleOpenWindowReplacementBikeV4,
//     isMobile,
//   }) => {
//     // const handleQuantityChange = (newQuantity) => {
//     //   if (newQuantity >= 1) {
//     //     onUpdateQuantity(item, newQuantity);
//     //   }
//     // };

//     // const handleRemoveItem = () => {
//     //   onRemoveItem(item);
//     // };

//     const singleBikeCost = item?.totalCostBike / (item?.totals?.quantity || 1);
//     const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
//     const updatedTotalCost =
//       updatedTotalCostBike + (item.totalCostAditioalServices || 0);

//     return (
//       <div
//         style={{
//           backgroundColor: "var(--color-gray-100)",
//           borderRadius:
//             groupItems.length === 1
//               ? "16px"
//               : `${
//                   index === 0
//                     ? "16px 16px 0 0"
//                     : index === groupItems.length - 1
//                     ? "0 0 16px 16px"
//                     : "0"
//                 }`,
//           padding: "16px",
//           display: "grid",
//           gridTemplateColumns: "repeat(3, 1fr)",
//           gap: "24px",
//         }}
//       >
//         <div
//           style={{
//             height: "100%",
//             gridColumn: "span 3",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "flex-start",
//             justifyContent: "center",
//             gap: "24px",
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               position: "relative",
//             }}
//           >
//             <PriorityFile
//               files={item.bikeData.files.photoFiles || []}
//               style={{
//                 width: "100%",
//                 aspectRatio: isMobile ? "1/1" : "16/9",
//               }}
//             />
//             {/* brand and category */}

//             <div
//               style={{
//                 position: "absolute",
//                 right: "8px",
//                 top: "8px",
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 gap: "4px",
//               }}
//             >
//               <p
//                 className="p-light"
//                 style={{
//                   backgroundColor: "rgba(255, 255, 255, 0.2)",
//                   padding: "2px 8px",
//                   borderRadius: "4px",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   backdropFilter: "blur(10px)",
//                   color: "var(--color-white)",
//                 }}
//               >
//                 {item?.bikeData?.directory?.brand?.title}
//               </p>

//               <p
//                 className="p-light"
//                 style={{
//                   backgroundColor: "rgba(255, 255, 255, 0.2)",
//                   padding: "2px 8px",
//                   borderRadius: "4px",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   backdropFilter: "blur(10px)",
//                   color: "var(--color-white)",
//                 }}
//               >
//                 {item?.bikeData?.directory?.category?.title}
//               </p>
//             </div>
//             {/* number order */}
//             <div
//               style={{
//                 position: "absolute",
//                 left: "8px",
//                 bottom: "8px",
//               }}
//             >
//               <p
//                 className="p-light"
//                 style={{
//                   backgroundColor: "rgba(255, 255, 255, 0.2)",
//                   padding: "2px 8px",
//                   borderRadius: "4px",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   backdropFilter: "blur(10px)",
//                   color: "var(--color-white)",
//                 }}
//               >
//                 # {index + 1}
//               </p>
//             </div>
//           </div>

//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               gap: "8px",
//             }}
//           >
//             {/* Name bike and quantity */}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 gap: "8px",
//               }}
//             >
//               <h5
//                 style={{
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 {truncateText(
//                   item?.bikeData?.basicInformation?.title?.titleOriginal,
//                   30
//                 )}
//               </h5>
//               <span
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-200)",
//                   height: "1px",
//                 }}
//               />
//               <h6
//                 style={{
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 {item?.totals?.quantity || 1} pc
//               </h6>
//             </div>

//             {/* date interval */}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 gap: "8px",
//               }}
//             >
//               <p className="p-light" style={{ whiteSpace: "nowrap" }}>
//                 {dateFormat(item?.totals?.dateInterval[0] || 0, isMobile)}
//               </p>

//               <div
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-200)",
//                   height: "1px",
//                 }}
//               />
//               <p className="p-light" style={{ whiteSpace: "nowrap" }}>
//                 {dateFormat(item?.totals?.dateInterval[1] || 0, isMobile)}
//               </p>
//             </div>

//             {/* Total days in order item */}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 gap: "8px",
//               }}
//             >
//               <p className="p-light">
//                 Day
//                 {item?.totals?.daysInInterval > 1 && "s"}
//               </p>
//               <span
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-200)",
//                   height: "1px",
//                 }}
//               />
//               <h6>{item?.totals?.daysInInterval || 0}</h6>
//             </div>

//             {/* Total cost bike item */}
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 gap: "8px",
//               }}
//             >
//               <p
//                 className="p-light"
//                 style={{
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 Total cost
//               </p>
//               <div
//                 style={{
//                   width: "100%",
//                   backgroundColor: "var(--color-gray-200)",
//                   height: "1px",
//                 }}
//               />
//               <h6>{formatCurrencyUSD(updatedTotalCostBike)}</h6>
//             </div>
//           </div>
//         </div>
//         {item?.orderedAditionalServices?.length > 0 && (
//           <div
//             style={{
//               gridColumn: "span 3",
//             }}
//           >
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "16px",
//               }}
//             >
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "8px",
//                 }}
//               >
//                 {item?.orderedAditionalServices?.map((service, idx) => (
//                   <div
//                     key={service?.id || idx}
//                     style={{
//                       width: "100%",
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                       gap: "8px",
//                     }}
//                   >
//                     <span
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "center",
//                         gap: "4px",
//                       }}
//                     >
//                       <p
//                         className="p-light"
//                         style={{
//                           whiteSpace: "nowrap",
//                         }}
//                       >
//                         {truncateText(service?.title?.titleOriginal, 55)}
//                       </p>
//                       <span
//                         style={{
//                           color: "var(--color-gray-400)",
//                         }}
//                         className="material-symbols-outlined p-light"
//                       >
//                         close_small
//                       </span>
//                       <p
//                         className="p-light"
//                         style={{
//                           color: "var(--color-gray-400)",
//                         }}
//                       >
//                         {service?.countOrderedServices}
//                       </p>
//                     </span>

//                     <div
//                       style={{
//                         width: "100%",
//                         backgroundColor: "var(--color-gray-200)",
//                         height: "1px",
//                       }}
//                     />
//                     <p
//                       className="p-light"
//                       style={{
//                         color: "var(--color-black)",
//                         fontWeight: "600",
//                       }}
//                     >
//                       {formatCurrencyUSD(
//                         service?.price * service?.countOrderedServices || 0
//                       )}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <p className="p-light">TOTAL:</p>
//                 <h6>{formatCurrencyUSD(updatedTotalCost)}</h6>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     );
//   }
// );
const UserData = React.memo(({ type, children, date, userName, isMobile }) => {
  if (type === "left") {
    return (
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            gridColumn: "span 10",
          }}
        >
          <p
            className="p-light"
            style={{
              padding: "8px 16px",
              width: "100%",
              textAlign: "left",
              boxSizing: "border-box",
              paddingRight: "16px",
            }}
          >
            {dateFormat(date)}
          </p>
          {children}
        </div>

        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              aspectRatio: "1/1",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <PriorityFile
              isLoading={true}
              style={{
                borderRadius: "80px",
                width: "100%",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
              }}
              // files={photoRootBike}
            />
          </div>
          <p className="p-light">
            {truncateText(userName || "No name", isMobile ? 5 : 10)}
          </p>
        </div>
      </div>
    );
  }

  if (type === "right") {
    return (
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: "8px",
        }}
      >
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              aspectRatio: "1/1",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <PriorityFile
              isLoading={true}
              style={{
                borderRadius: "80px",
                width: "100%",
                aspectRatio: "1/1",
                backgroundColor: "var(--color-gray-100)",
              }}
              // files={photoRootBike}
            />
          </div>

          <p className="p-light">
            {truncateText(userName || "No name", isMobile ? 5 : 7)}
          </p>
        </div>
        <div
          style={{
            gridColumn: "span 10",
          }}
        >
          <p
            className="p-light"
            style={{
              padding: "8px 16px",
              width: "100%",
              textAlign: "right",
              boxSizing: "border-box",
            }}
          >
            {dateFormat(date)}
          </p>
          {children}
        </div>
      </div>
    );
  }

  return null;
});

import React from "react";
const Divide = ({ title, number }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        // backgroundColor: "violet",
      }}
    >
      <p
        className="p-light"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </p>
      <div
        style={{
          height: "1px",
          width: "100%",
          backgroundColor: "var(--color-gray-200)",
        }}
      />
      <p className="p-light">{number}</p>
    </div>
  );
};
export default Divide;

import React, { useEffect, useState } from "react";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";

const WidgetBikesReplacmentListV4 = React.memo(
  ({
    onClick,
    onClickDelete,
    item,
    indexBikeCurrentCart,
    order_selectedCompletedRootOrder,
    isMobile,
    currentStateOrder,
  }) => {
    const [photoRootBike, setPhotoRootBike] = useState(null);
    const [titleRootbike, setTitleRootbike] = useState(null);
    const [replacementBikes, setReplacementBikes] = useState(null);

    useEffect(() => {
      if (item && item.bikeData) {
        setPhotoRootBike(item?.bikeData?.files?.photoFiles || null);
        setTitleRootbike(
          item?.bikeData.basicInformation?.title?.titleOriginal || null
        );
        setReplacementBikes(item?.replacementBikes || null);
      }
    }, [item]);

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "16px 16px 0 16px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "4px",
            }}
          >
            <h5>{titleRootbike && titleRootbike}</h5>
          </div>

          {Object.entries(replacementBikes || {}).map(
            ([key, bikeSlot], index) => (
              <div
                key={key} // Используем ключ как уникальный идентификатор
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-100)",
                  ...(Object.entries(replacementBikes || {}).length === 1
                    ? { borderRadius: "16px" }
                    : {
                        borderRadius:
                          index === 0
                            ? "16px 16px 0 0"
                            : index !==
                              Object.entries(replacementBikes || {}).length - 1
                            ? "0"
                            : "0 0 16px 16px",
                      }),
                  boxSizing: "border-box",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "4px 16px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                    boxSizing: "border-box",
                  }}
                >
                  <p className="p-light">slot</p>
                  <span
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <p className="p-light">{index + 1}</p>
                </div>

                <div
                  style={{
                    display: "grid",
                    padding: "8px",
                    ...(isMobile
                      ? {
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gridTemplateRows: "repeat(2, 1fr)",
                        }
                      : {
                          gridTemplateColumns: "repeat(4, 1fr)",
                        }),
                    gap: "8px",
                    boxSizing: "border-box",
                  }}
                >
                  {/* Проверка, является ли value массивом */}
                  {Array.isArray(bikeSlot) &&
                    bikeSlot.map((replacementItem, i) => (
                      <div key={i} style={{ position: "relative" }}>
                        <PriorityFile
                          style={{
                            borderRadius: "80px",
                            width: "100%",
                            height: "100%",
                            aspectRatio: "1/1",
                            gridColumn: `${index + 2}`,
                          }}
                          files={replacementItem?.files?.photoFiles}
                        />

                        <span
                          onClick={() =>
                            onClickDelete({
                              keySlotReplacementBike: key,
                              indexBikeCurrentCart: indexBikeCurrentCart,
                              indexDeleteSlotBike: index,
                              indexDeletingBike: i,
                              order_selectedCompletedRootOrder:
                                order_selectedCompletedRootOrder,
                            })
                          }
                          style={{
                            ...(isMobile
                              ? { padding: "8px" }
                              : { padding: "4px" }),
                            position: "absolute",
                            right: "0",
                            top: "0",
                            borderRadius: "50%",
                            border: "0",
                            backgroundColor: "var(--color-gray-100)",
                            aspectRatio: "1/1",
                            fontSize: "1rem",
                            cursor: "pointer",
                            boxSizing: "border-box",
                          }}
                          className="material-symbols-outlined"
                        >
                          close
                        </span>
                      </div>
                    ))}

                  {/* Кнопка "+" для того чтобы добавить в массив замену */}
                  {currentStateOrder !== "replacement" &&
                    Array.isArray(bikeSlot) &&
                    bikeSlot.length !== 4 && (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          aspectRatio: "1/1",
                          borderRadius: "50%",
                          backgroundColor: "var(--color-alarm)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                          cursor: "pointer",
                          color: "var(--color-white)",
                        }}
                        onClick={() =>
                          onClick({
                            mode: "replacement",
                            keySlotReplacementBike: key,
                            indexReplacementBike: index,
                            indexBikeCurrentCart: indexBikeCurrentCart,
                          })
                        }
                        className="material-symbols-outlined"
                      >
                        add
                      </div>
                    )}
                  {/* Создаём массив с помощью from для создания доступных ячеек  */}
                  {Array.from(
                    {
                      length:
                        (currentStateOrder !== "replacement" ? 3 : 4) -
                        bikeSlot.length,
                    },
                    (_, i) => (
                      <div
                        key={i}
                        style={{
                          width: "100%",
                          height: "100%",
                          aspectRatio: "1/1",
                          borderRadius: "50%",
                          backgroundColor: "var(--color-white)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h5 style={{ color: "var(--color-gray-200)" }}>
                            {i + bikeSlot.length + 2}
                          </h5>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              gap: "8px",
              padding: "16px",
            }}
          >
            <p
              className="p-light"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Total slots:
            </p>
            <span
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "var(--color-gray-200)",
              }}
            />
            {/* <p className="p-light">{replacementBikesArray.length}</p> */}
          </div>
        </div>
      </div>
    );
  }
);
export default WidgetBikesReplacmentListV4;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../services/firebase/authFirebase";
import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
import {
  setErrorState,
  setLoadingState,
  updateRootDocuments,
} from "../../../../catalogSliceV4";

// Для хранения функций отписки, связанных с конкретными документами
const unsubscribeMap = new Map();

/**
 * Подписка на изменения в документе Firestore.
 * @param {Object} params - Параметры подписки.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} params.rootCurrentDocumenState - Состояние текущего документа в Redux.
 * @param {string} params.idRootDocument - ID документа Firestore.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 */
export const subscribeRootDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribeRootDocumentsThunkV4",
  async (
    {
      collectionPath,
      rootCurrentDocumenState,
      idRootDocument,
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(
        "Инициализация подписки на документ:",
        `${collectionPath}/${idRootDocument}`
      );

      const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);
      console.log("Ссылка на документ Firestore создана:", documentRef);

      // Если существует подписка на тот же документ, отписываемся от неё
      if (unsubscribeMap.has(idRootDocument)) {
        const previousUnsubscribe = unsubscribeMap.get(idRootDocument);
        if (typeof previousUnsubscribe === "function") {
          previousUnsubscribe();
          console.log(
            `Существующая подписка на документ ${idRootDocument} отменена`
          );
        }
      }

      // Подписка на документ и получение функции отписки
      const unsubscribe = onSnapshot(
        documentRef,
        (snapshot) => {
          let documentData = {};
          if (snapshot.exists()) {
            documentData = { id: snapshot.id, ...snapshot.data() };
          } else {
            console.warn(`Документ с ID ${idRootDocument} не найден.`);
          }

          // Обновляем данные сразу
          dispatch(
            updateRootDocuments({
              documents: documentData,
              rootCurrentDocumenState,
            })
          );
          dispatch(
            setLoadingState({
              loadingStateName,
              isLoading: false,
            })
          );

          console.log(
            `Данные обновлены для документа ${idRootDocument} (сразу).`
          );
        },
        (error) => {
          const errorMessage = interpretErrorV4(error.code);
          console.error("Ошибка при подписке на Firestore:", errorMessage);
          dispatch(
            setErrorState({
              errorStateName,
              errorMessage,
            })
          );
          return rejectWithValue({ errorMessage });
        }
      );

      // Сохраняем функцию отписки в Map
      unsubscribeMap.set(idRootDocument, unsubscribe);

      console.log("Подписка успешно установлена на документ:", idRootDocument);

      // Возвращаем объект без функции отписки для сериализуемости
      return { subscriptionEstablished: true };
    } catch (error) {
      const errorMessage = interpretErrorV4(error.code);
      console.error("Ошибка при подписке на Firestore:", errorMessage);
      return rejectWithValue({ errorMessage });
    }
  }
);

/**
 * Функция для отписки от документа Firestore.
 * @param {string} idRootDocument - ID документа Firestore.
 */
export const unsubscribeRootDocument = (idRootDocument) => {
  if (unsubscribeMap.has(idRootDocument)) {
    const unsubscribe = unsubscribeMap.get(idRootDocument);
    if (typeof unsubscribe === "function") {
      unsubscribe();
      console.log(`Отписка от документа с ID ${idRootDocument} выполнена`);
    }
    unsubscribeMap.delete(idRootDocument);
  } else {
    console.log(`Нет подписки для документа с ID ${idRootDocument}`);
  }
};

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { doc, onSnapshot } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateRootDocuments,
// } from "../../../../catalogSliceV4";

// // Для хранения функций отписки, связанных с конкретными документами
// const unsubscribeMap = new Map();

// // Для хранения информации о последних обновлениях и таймерах по документам
// const updateMap = new Map();

// /**
//  * Подписка на изменения в документе Firestore с контролем частоты обновлений.
//  * @param {Object} params - Параметры подписки.
//  * @param {string} params.collectionPath - Путь к коллекции Firestore.
//  * @param {string} params.rootCurrentDocumenState - Состояние текущего документа в Redux.
//  * @param {string} params.idRootDocument - ID документа Firestore.
//  * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
//  * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
//  * @param {number} [params.delayTime=60000] - Задержка в миллисекундах между обновлениями (по умолчанию 60 секунд).
//  */
// export const subscribeRootDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribeRootDocumentsThunkV4",
//   async (
//     {
//       collectionPath,
//       rootCurrentDocumenState,
//       idRootDocument,
//       loadingStateName,
//       errorStateName,
//       delayTime = 0, // По умолчанию 60 секунд
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       console.log(
//         "Инициализация подписки на документ:",
//         `${collectionPath}/${idRootDocument}`
//       );

//       const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);
//       console.log("Ссылка на документ Firestore создана:", documentRef);

//       // Если существует подписка на тот же документ, отписываемся от неё
//       if (unsubscribeMap.has(idRootDocument)) {
//         const previousUnsubscribe = unsubscribeMap.get(idRootDocument);
//         if (typeof previousUnsubscribe === "function") {
//           previousUnsubscribe();
//           console.log(
//             `Существующая подписка на документ ${idRootDocument} отменена`
//           );
//         }
//       }

//       // Подписка на документ и получение функции отписки
//       const unsubscribe = onSnapshot(
//         documentRef,
//         (snapshot) => {
//           let documentData = {};
//           if (snapshot.exists()) {
//             documentData = { id: snapshot.id, ...snapshot.data() };
//           } else {
//             console.warn(`Документ с ID ${idRootDocument} не найден.`);
//           }

//           const currentTimestamp = Date.now();
//           const updateEntry = updateMap.get(idRootDocument) || {};

//           if (!updateEntry.lastUpdateTimestamp) {
//             // Первый раз: обновляем сразу
//             dispatch(
//               updateRootDocuments({
//                 documents: documentData,
//                 rootCurrentDocumenState,
//               })
//             );
//             dispatch(
//               setLoadingState({
//                 loadingStateName,
//                 isLoading: false,
//               })
//             );

//             // Устанавливаем последний момент обновления
//             updateEntry.lastUpdateTimestamp = currentTimestamp;
//             updateMap.set(idRootDocument, updateEntry);

//             console.log(
//               `Данные обновлены для документа ${idRootDocument} (сразу).`
//             );
//           } else {
//             const timeSinceLastUpdate =
//               currentTimestamp - updateEntry.lastUpdateTimestamp;

//             if (timeSinceLastUpdate >= delayTime) {
//               // Достаточно времени прошло: обновляем
//               dispatch(
//                 updateRootDocuments({
//                   documents: documentData,
//                   rootCurrentDocumenState,
//                 })
//               );
//               dispatch(
//                 setLoadingState({
//                   loadingStateName,
//                   isLoading: false,
//                 })
//               );

//               // Обновляем последний момент обновления
//               updateEntry.lastUpdateTimestamp = currentTimestamp;
//               updateMap.set(idRootDocument, updateEntry);

//               console.log(
//                 `Данные обновлены для документа ${idRootDocument} (по таймауту).`
//               );
//             } else {
//               // Время не прошло: сохраняем последние данные и устанавливаем таймер, если его нет
//               updateEntry.latestData = documentData;

//               if (!updateEntry.timerId) {
//                 const remainingTime = delayTime - timeSinceLastUpdate;

//                 updateEntry.timerId = setTimeout(() => {
//                   // Данные для обновления
//                   const dataToUpdate = updateEntry.latestData;

//                   // Обновляем данные
//                   dispatch(
//                     updateRootDocuments({
//                       documents: dataToUpdate,
//                       rootCurrentDocumenState,
//                     })
//                   );
//                   dispatch(
//                     setLoadingState({
//                       loadingStateName,
//                       isLoading: false,
//                     })
//                   );

//                   // Обновляем последний момент обновления
//                   updateEntry.lastUpdateTimestamp = Date.now();
//                   updateEntry.timerId = null;
//                   updateEntry.latestData = null;

//                   console.log(
//                     `Данные обновлены для документа ${idRootDocument} (через задержку).`
//                   );

//                   // Обновляем запись в updateMap
//                   updateMap.set(idRootDocument, updateEntry);
//                 }, remainingTime);

//                 console.log(
//                   `Таймер установлен для документа ${idRootDocument}, обновление через ${remainingTime} мс.`
//                 );
//               }

//               // Сохраняем обновленный updateEntry
//               updateMap.set(idRootDocument, updateEntry);
//             }
//           }
//         },
//         (error) => {
//           const errorMessage = interpretErrorV4(error.code);
//           console.error("Ошибка при подписке на Firestore:", errorMessage);
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );
//           return rejectWithValue({ errorMessage });
//         }
//       );

//       // Сохраняем функцию отписки в Map
//       unsubscribeMap.set(idRootDocument, unsubscribe);

//       console.log("Подписка успешно установлена на документ:", idRootDocument);

//       // Возвращаем объект без функции отписки для сериализуемости
//       return { subscriptionEstablished: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error("Ошибка при подписке на Firestore:", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// /**
//  * Функция для отписки от документа Firestore.
//  * @param {string} idRootDocument - ID документа Firestore.
//  */
// export const unsubscribeRootDocument = (idRootDocument) => {
//   if (unsubscribeMap.has(idRootDocument)) {
//     const unsubscribe = unsubscribeMap.get(idRootDocument);
//     if (typeof unsubscribe === "function") {
//       unsubscribe();
//       console.log(`Отписка от документа с ID ${idRootDocument} выполнена`);
//     }
//     unsubscribeMap.delete(idRootDocument);
//   } else {
//     console.log(`Нет подписки для документа с ID ${idRootDocument}`);
//   }

//   // Также нужно очистить таймер, если он установлен
//   if (updateMap.has(idRootDocument)) {
//     const currentUpdate = updateMap.get(idRootDocument);
//     if (currentUpdate.timerId) {
//       clearTimeout(currentUpdate.timerId);
//       console.log(`Таймер для документа ${idRootDocument} очищен`);
//     }
//     updateMap.delete(idRootDocument);
//   }
// };

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { doc, onSnapshot } from "firebase/firestore";
// import { db } from "../../../../../services/firebase/authFirebase";
// import interpretErrorV4 from "../../../../../functions/interpretErrorV4";
// import {
//   setErrorState,
//   setLoadingState,
//   updateRootDocuments,
// } from "../../../../catalogSliceV4";

// // Для хранения функций отписки, связанных с конкретными документами
// const unsubscribeMap = new Map();

// // Подписка на изменения в документе Firestore
// export const subscribeRootDocumentsThunkV4 = createAsyncThunk(
//   "catalog/subscribeRootDocumentsThunkV4",
//   async (
//     {
//       collectionPath,
//       rootCurrentDocumenState,
//       idRootDocument,
//       loadingStateName,
//       errorStateName,
//       updateTime,
//     },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       console.log(
//         "Инициализация подписки на документ:",
//         `${collectionPath}/${idRootDocument}`
//       );

//       const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);
//       console.log("Ссылка на документ Firestore создана:", documentRef);

//       // Если существует подписка на тот же документ, отписываемся от неё
//       if (unsubscribeMap.has(idRootDocument)) {
//         const previousUnsubscribe = unsubscribeMap.get(idRootDocument);
//         if (typeof previousUnsubscribe === "function") {
//           previousUnsubscribe();
//           console.log(
//             `Существующая подписка на документ ${idRootDocument} отменена`
//           );
//         }
//       }

//       // Подписка на документ и получение функции отписки
//       const unsubscribe = onSnapshot(
//         documentRef,
//         (snapshot) => {
//           let documentData = {};
//           if (snapshot.exists()) {
//             documentData = { id: snapshot.id, ...snapshot.data() };
//           } else {
//             console.warn(`Документ с ID ${idRootDocument} не найден.`);
//           }

//           dispatch(
//             updateRootDocuments({
//               documents: documentData,
//               rootCurrentDocumenState,
//             })
//           );

//           dispatch(
//             setLoadingState({
//               loadingStateName,
//               isLoading: false,
//             })
//           );
//         },
//         (error) => {
//           const errorMessage = interpretErrorV4(error.code);
//           console.error("Ошибка при подписке на Firestore:", errorMessage);
//           dispatch(
//             setErrorState({
//               errorStateName,
//               errorMessage,
//             })
//           );
//           return rejectWithValue({ errorMessage });
//         }
//       );

//       // Сохраняем функцию отписки в Map
//       unsubscribeMap.set(idRootDocument, unsubscribe);

//       console.log("Подписка успешно установлена на документ:", idRootDocument);

//       // Возвращаем объект без функции отписки для сериализуемости
//       return { subscriptionEstablished: true };
//     } catch (error) {
//       const errorMessage = interpretErrorV4(error.code);
//       console.error("Ошибка при подписке на Firestore:", errorMessage);
//       return rejectWithValue({ errorMessage });
//     }
//   }
// );

// // Функция для отписки от документа
// export const unsubscribeRootDocument = (idRootDocument) => {
//   if (unsubscribeMap.has(idRootDocument)) {
//     const unsubscribe = unsubscribeMap.get(idRootDocument);
//     if (typeof unsubscribe === "function") {
//       unsubscribe();
//       console.log(`Отписка от документа с ID ${idRootDocument} выполнена`);
//     }
//     unsubscribeMap.delete(idRootDocument);
//   } else {
//     console.log(`Нет подписки для документа с ID ${idRootDocument}`);
//   }
// };

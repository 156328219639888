import React, { useEffect, useRef, useState } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { v4 as uuidv4 } from "uuid";
import { truncateText } from "../../../../../functions/truncateText";
import { QRCodeCanvas } from "qrcode.react";
import Barcode from "../WidgetsProfile/Barcode/Barcode";
import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
import Button from "../WidgetsProfile/Buttons/Button";
import DateFormat from "../../../../Public/UniversalWidgets/supportFunctions/functionDateFormat";
import { dateFormat } from "../../../../../functions/convertDate";
import Divide from "../WidgetsProfile/Divide/Divide";
// const formatDate = (date) => {
//   const validDate = typeof date === "number" ? new Date(date) : date;
//   return validDate instanceof Date && !isNaN(validDate)
//     ? `${validDate.getDate()} ${validDate.toLocaleString("en-US", {
//         month: "long",
//       })} ${validDate.getFullYear()}`
//     : "Date not selected";
// };
const truncateToThreeChars = (value) => {
  // Проверяем, является ли value строкой
  if (typeof value === "string") {
    return value.slice(0, 3); // Обрезаем строку до 3 символов
  }
  return value; // Возвращаем значение без изменений, если это не строка
};
const WidgetWoucherListV4 = ({
  currentRentalPointOrders,
  userType,
  isMobile,
  totalOrders,
  rentalPointRootData,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCostAditionalServices, setTotalCostAditionalServices] =
    useState(0);
  const [totalCostBike, setTotalCostBike] = useState(0);

  useEffect(() => {
    setQuantity(totalOrders?.quantity || 0);
    setTotal(totalOrders?.total || 0);
    setTotalCostAditionalServices(totalOrders?.totalCostAditionalServices || 0);
    setTotalCostBike(totalOrders?.totalCostBike || 0);
  }, [totalOrders]);

  if (!currentRentalPointOrders || currentRentalPointOrders.length === 0) {
    return <div>No orders available</div>;
  }

  const orderNumber = currentRentalPointOrders
    .map((order) => order.id) // Получаем массив всех orderId
    .join(", "); // Объединяем их в строку, разделяя запятой

  console.log(
    "currentRentalPointOrders 100",
    currentRentalPointOrders,
    orderNumber
  );

  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      {userType === "admin" || userType === "rental" || userType === "user"}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            aspectRatio: "9/16",
            backgroundColor: "var(--color-black)",
            // background:
            //   "linear-gradient(#ff8983 8%, #7f0e7f 50%, #007a65 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            padding: "16px",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "16px",
              right: "16px",
              top: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <h5
              style={{
                color: "var(--color-white)",
              }}
            >
              {rentalPointRootData?.basicInformation?.title?.titleOriginal}
            </h5>

            <PriorityFile
              style={{
                borderRadius: "80px",
                height: "56px",
                width: "56px",
              }}
              files={rentalPointRootData?.files?.logoFiles}
            />
          </div>

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <QRCodeCanvas
              value={orderNumber}
              size={200}
              fgColor="#ffffff"
              bgColor="transparent"
              level="H"
            />

            <p
              className="p-light"
              style={{
                textAlign: "center",
                color: "var(--color-gray-500)",
              }}
            >
              {`Для подтверждения брони предоставьте этот QR код в точке
                прокатта 
                ${rentalPointRootData?.basicInformation?.title?.titleOriginal}`}
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              right: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: "var(--color-white)" }}
            >
              location_on
            </span>
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
              }}
            >
              {rentalPointRootData?.location?.pointAddress}
            </p>
          </div>
        </div>

        <p className="p-light">
          {`You have ordered ${quantity} bikes for a total of 
    ${formatCurrencyUSD(total)}.`}
          {totalCostAditionalServices > 0
            ? `You have also selected additional services for a total of 
      ${formatCurrencyUSD(totalCostAditionalServices)}.`
            : `No additional services were ordered.`}
          {`The total cost of your order is 
    ${formatCurrencyUSD(totalCostBike)}.`}
        </p>

        {currentRentalPointOrders?.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {currentRentalPointOrders?.map((item, index) => {
              let globalIndex = 0; // Счетчик для сквозной нумерации

              return (
                <div
                  key={uuidv4()}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <h5>
                    {item?.bikeData?.basicInformation?.title?.titleOriginal ||
                      "No name bike"}
                  </h5>
                  {item.replacementBikes && (
                    <>
                      {Object.values(item.replacementBikes).map(
                        (bikesList, listIndex) =>
                          bikesList.length === 0 && (
                            <>
                              <Divide
                                title="Option (Original choice)"
                                number={globalIndex + 1}
                              />
                              <BikesInOrder
                                listIndex={listIndex}
                                item={item}
                                isMobile={isMobile}
                              />
                              {(() => {
                                globalIndex++;
                                return null;
                              })()}
                            </>
                          )
                      )}

                      {Object.values(item.replacementBikes).map(
                        (bikesList, listIndex) =>
                          bikesList.length > 0 &&
                          bikesList.map(
                            (bikeItem, bikeIndex) =>
                              bikeItem.userChoice && (
                                <>
                                  <Divide
                                    title="Option (Replacement)"
                                    number={globalIndex + 1}
                                  />
                                  <BikesInOrder
                                    listIndex={listIndex}
                                    item={item}
                                    bikeItem={bikeItem}
                                    isMobile={isMobile}
                                  />
                                  {(() => {
                                    globalIndex++;
                                    return null;
                                  })()}
                                </>
                              )
                          )
                      )}
                    </>
                  )}
                </div>
              );
            })}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <p className="p-light">
                Your order will be ready for you at{""}
                {rentalPointRootData.basicInformation.title.titleOriginal}. You
                can pick it up at {rentalPointRootData.location.pointAddress}{" "}
                during the following hours:
              </p>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Timetable rentalPointRootData={rentalPointRootData} />
              </div>

              <p className="p-light">
                If you have any questions, feel free to contact{" "}
                {rentalPointRootData.basicInformation.title.titleOriginal}. You
                can reach them by{" "}
                {rentalPointRootData.contacts.phoneNumbers.length > 1
                  ? "phones: "
                  : "phone: "}
                {rentalPointRootData.contacts.phoneNumbers.map(
                  (phoneItem, index) => (
                    <span key={phoneItem.key}>{phoneItem.phone}, </span>
                  )
                )}
                or by{" "}
                {rentalPointRootData.contacts.emailAddresses.length > 1
                  ? "emails: "
                  : "email: "}
                {rentalPointRootData.contacts.emailAddresses.map(
                  (emailItem, index) => (
                    <span key={emailItem.key}>{emailItem.email}, </span>
                  )
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetWoucherListV4;

const Timetable = ({ rentalPointRootData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      {rentalPointRootData?.timetable?.map((timetableItem, index) =>
        !timetableItem.isHoliday ? (
          <div
            key={uuidv4()}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {truncateToThreeChars(timetableItem.weekday.weekdayOriginal)}
            </p>

            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "var(--color-gray-200)",
              }}
            />

            <div
              className="p-light"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                backgroundColor: "var(--color-success)",
                padding: "2px 8px",
                borderRadius: "4px",
                color: "var(--color-white)",
                boxSizing: "border-box",
              }}
            >
              <p>
                {timetableItem?.openHours}:{timetableItem?.openMinutes}
              </p>
              —
              <p>
                {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
              </p>
            </div>
          </div>
        ) : (
          <div
            key={uuidv4()}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {truncateToThreeChars(timetableItem?.weekday?.weekdayOriginal)}
            </p>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "var(--color-gray-200)",
              }}
            />
            <p
              className="p-light"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                backgroundColor: "var(--color-black)",
                padding: "2px 8px",
                borderRadius: "4px",
                color: "var(--color-white)",
                boxSizing: "border-box",
              }}
            >
              holiday
            </p>
          </div>
        )
      )}
    </div>
  );
};

const TotalItemsGroupedBikes = ({ groupItems, styleBox, styleText }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        ...styleBox,
      }}
    >
      <p className="p-light" style={{ ...styleText }}>
        TOTAL:
      </p>
      <p className="p-light" style={{ ...styleText }}>
        {formatCurrencyUSD(
          groupItems?.reduce(
            (acc, item) =>
              acc +
              (item.totalCostBike + (item.totalCostAditioalServices || 0)),
            0
          )
        )}
      </p>
    </div>
  );
};

const RentalPointData = ({ item }) => {
  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <PriorityFile
        files={item?.rentalPointRootData?.files?.logoFiles}
        style={{
          borderRadius: "80px",
        }}
      />
      <div
        style={{
          gridColumn: "span 5",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <span>
          <h5>
            {item?.rentalPointRootData?.basicInformation?.title?.titleOriginal}
          </h5>
          <p className="p-light">
            {item?.rentalPointRootData?.location?.country?.title}
          </p>
          <p className="p-light">
            {item?.rentalPointRootData?.location?.city?.title}
          </p>
        </span>
      </div>
    </div>
  );
};

const BikeItemData = ({
  item,
  index,
  groupItems,
  barcode,
  isMobile,
  // onUpdateQuantity,
  // onRemoveItem,
}) => {
  const singleBikeCost = item?.totalCostBike / (item?.totals?.quantity || 1);
  const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
  const updatedTotalCost =
    updatedTotalCostBike + (item.totalCostAditioalServices || 0);

  console.log("groupItems.length", groupItems);
  return (
    <div
      style={{
        backgroundColor: "var(--color-gray-100)",
        borderRadius:
          groupItems.length === 1
            ? "16px"
            : `${index === 0 ? "16px 16px 0 0" : ""} ${
                index === groupItems.length - 1 ? "0 0 16px 16px" : ""
              }`,
        // borderRadius:
        //   groupItems.length === 1
        //     ? "16px"
        //     : `${index === 0 ? "16px 16px 0 0" : ""} ${
        //         index === groupItems.length - 1 ? "0 0 16px 16px" : ""
        //       }`,
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "24px",
      }}
    >
      <div
        style={{
          height: "100%",
          gridColumn: "span 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "4px",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-gray-400)",
              }}
            >
              # {index + 1}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.brand?.title}
              </p>

              <p
                className="p-light"
                style={{
                  backgroundColor: "var(--color-white)",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.bikeData?.directory?.category?.title}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* Название байка и его колличество */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <h5
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {truncateText(
                  item?.bikeData?.basicInformation?.title?.titleOriginal,
                  30
                )}
              </h5>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />

              <h6
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {item?.totals?.quantity || 1} pc
              </h6>
            </div>
            {/* Диапазон дат */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
                whiteSpace: "nowrap",
              }}
            >
              <p
                className="p-light"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {dateFormat(item?.totals?.dateInterval[0] || 0, isMobile)}
              </p>

              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <p
                className="p-light"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {dateFormat(item?.totals?.dateInterval[1] || 0, isMobile)}
              </p>
            </div>

            {/* Колличество дней в заказе*/}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
                whiteSpace: "nowrap",
              }}
            >
              <p className="p-light">
                day
                {item?.totals?.daysInInterval > 1 && "s"}
              </p>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <p
                className="p-light"
                style={{
                  color: "var(--color-black)",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }}
              >
                {item?.totals?.daysInInterval || 0}
              </p>
            </div>
          </div>

          {barcode}
        </div>
      </div>

      {item?.orderedAditionalServices?.length > 0 && (
        <div
          style={{
            gridColumn: "span 3",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {item?.orderedAditionalServices?.map((service, idx) => (
                <div
                  key={service?.id || idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateText(service?.title?.titleOriginal, 55)}
                    </p>
                    {/* <span
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                        className="material-symbols-outlined p-light"
                      >
                        close_small
                      </span>
                      <p
                        className="p-light"
                        style={{
                          color: "var(--color-gray-400)",
                        }}
                      >
                        {service?.countOrderedServices}
                      </p> */}
                  </span>

                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--color-gray-200)",
                      height: "1px",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      color: "var(--color-black)",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {service?.countOrderedServices} pc
                  </p>
                </div>
              ))}
            </div>
            {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="p-light">TOTAL:</p>
                <h6>{formatCurrencyUSD(updatedTotalCost)}</h6>
              </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

const BikesInOrder = ({ listIndex, item, bikeItem, isMobile }) => {
  const divRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, [bikeItem, item]);

  return (
    <div
      style={{
        width: "100%",
        height: `calc(${size.height}px + 8px)`,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "32px",
      }}
      key={`${listIndex}`}
    >
      {bikeItem ? (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
            ref={divRef}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <PriorityFile
                style={{
                  width: "100%",
                  ...(isMobile
                    ? { aspectRatio: "16/9" }
                    : { aspectRatio: "16/9" }),
                  opacity: "0.5",
                }}
                files={item?.bikeData?.files?.photoFiles}
              />
            </div>

            <div
              style={{
                width: "100%",
                position: "absolute",
                left: "8px",
                top: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  position: "relative",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "24px",
                    aspectRatio: "1/1",
                    borderRadius: "50%",
                    backgroundColor: "var(--color-success)",
                    color: "var(--color-white)",
                    right: "8px",
                    top: "8px",
                  }}
                  title="Selected bike"
                >
                  check_small
                </span>

                <PriorityFile
                  style={{
                    width: "100%",
                    ...(isMobile
                      ? { aspectRatio: "16/9" }
                      : { aspectRatio: "16/9" }),
                  }}
                  files={bikeItem?.files?.photoFiles}
                />
              </div>
            </div>
          </div>

          <p className="p-light">
            {bikeItem.basicInformation.title.titleOriginal}
          </p>
        </>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              position: "relative",
            }}
            ref={divRef}
          >
            <span
              className="material-symbols-outlined"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                width: "24px",
                aspectRatio: "1/1",
                borderRadius: "50%",
                backgroundColor: "var(--color-success)",
                color: "var(--color-white)",
                right: "8px",
                top: "8px",
              }}
              title="Selected bike"
            >
              check_small
            </span>

            <PriorityFile
              style={{
                width: "100%",
                ...(isMobile
                  ? { aspectRatio: "16/9" }
                  : { aspectRatio: "16/9" }),
              }}
              files={item?.bikeData?.files?.photoFiles}
            />
          </div>

          <p className="p-light">
            {item?.bikeData?.basicInformation?.title?.titleOriginal}
          </p>
        </>
      )}
    </div>
  );
};

const BikeCard = ({ files, title, isMobile }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "8px",
      }}
    >
      {/* Изображение */}
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        {/* Иконка */}
        <span
          className="material-symbols-outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            width: "24px",
            aspectRatio: "1/1",
            borderRadius: "50%",
            backgroundColor: "var(--color-success)",
            color: "var(--color-white)",
            right: "8px",
            top: "8px",
          }}
          title="Selected bike"
        >
          check_small
        </span>

        <PriorityFile
          style={{
            width: "100%",
            ...(isMobile ? { aspectRatio: "1/1" } : { aspectRatio: "16/9" }),
          }}
          files={files}
        />
      </div>

      {/* Название */}
      <p className="p-light">{title}</p>
    </div>
  );
};
// const BikesInOrder = ({ listIndex, item, bikeItem, isMobile }) => {
//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//         }}
//         key={`${listIndex}`}
//       >
//         {bikeItem ? (
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               gap: "8px",
//               position: "relative",
//             }}
//           >
//             <span
//               className="material-symbols-outlined"
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "absolute",
//                 width: "24px",
//                 aspectRatio: "1/1",
//                 borderRadius: "50%",
//                 backgroundColor: "var(--color-success)",
//                 color: "var(--color-white)",
//                 right: "8px",
//                 top: "8px",
//               }}
//               title="Selected bike"
//             >
//               check_small
//             </span>

//             <div
//               style={{
//                 width: "100%",
//               }}
//             >
//               <PriorityFile
//                 style={{
//                   width: "100%",
//                   ...(isMobile
//                     ? { aspectRatio: "1/1" }
//                     : { aspectRatio: "16/9" }),
//                 }}
//                 files={bikeItem?.files?.photoFiles}
//               />
//             </div>

//             <p className="p-light">
//               {bikeItem.basicInformation.title.titleOriginal}
//             </p>
//           </div>
//         ) : (
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               gap: "8px",
//               position: "relative",
//             }}
//           >
//             <span
//               className="material-symbols-outlined"
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "absolute",
//                 width: "24px",
//                 aspectRatio: "1/1",
//                 borderRadius: "50%",
//                 backgroundColor: "var(--color-success)",
//                 color: "var(--color-white)",
//                 right: "8px",
//                 top: "8px",
//               }}
//               title="Selected bike"
//             >
//               check_small
//             </span>

//             <div
//               style={{
//                 width: "100%",
//                 backgroundColor: "yellow",
//               }}
//             >
//               <PriorityFile
//                 style={{
//                   width: "100%",
//                   ...(isMobile
//                     ? { aspectRatio: "1/1" }
//                     : { aspectRatio: "16/9" }),
//                 }}
//                 files={item?.bikeData?.files?.photoFiles}
//               />
//             </div>

//             <p className="p-light">
//               {item?.bikeData?.basicInformation?.title?.titleOriginal || "item"}
//             </p>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };
